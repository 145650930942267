import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ScreensHeader = () => {
    const { t } = useTranslation();
    return (
        <div className="relative border-b-2 border-gray-200 py-2">
            <span className="font-extralight">
                {t("sections.screens.mainTitle")}
            </span>
            <div className="absolute right-0 top-0 mt-1 mr-3">
                <Link to="/wizard">
                    <button className="group buttonNew">
                        <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="text-white"
                        />
                        <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light">
                            {t("sections.screens.menu.screenNew")}
                        </span>
                    </button>
                </Link>
            </div>
        </div>
    );
};
export { ScreensHeader };
