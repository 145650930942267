import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DecisionModal extends Component {
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };

    callFunctionAndClose = () => {
        let param = this.props.idParameter;
        if (param) this.props.callbackFunction(param);
        else this.props.callbackFunction();
        this.closeModal();
    };
    callbackOption1 = () => {
        this.props.callbackOption1(1);
        this.closeModal();
    };
    callbackOption2 = () => {
        this.props.callbackOption2(2);
        this.closeModal();
    };

    render() {
        const { modalID, text, title } = this.props;

        return (
            <div
                id={modalID}
                className="modal fixed z-50 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent modal-sm bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div
                        className="button cursor-pointer ml-auto relative right-1"
                        onClick={this.closeModal}
                    >
                        <FontAwesomeIcon icon={["fal", "times"]} />
                    </div>
                    <div className="modal-header relative font-extralight text-lg flex flex-col justify-between px-3 mt-1">
                        {title ? (
                            <p className="text-xl font-semibold title">
                                {title}:
                            </p>
                        ) : (
                            ""
                        )}

                        <div className="title text-de-600 text-xl">{text}</div>
                    </div>

                    <div className="modal-body flex  justify-end px-3 mt-2">
                        <p className="mr-3">{}</p>
                        {this.props.option1_text && this.props.option2_text ? (
                            <>
                                <button
                                    onClick={this.callbackOption1}
                                    className="buttonSecondary"
                                >
                                    {this.props.option1_text}
                                </button>
                                <button
                                    onClick={this.callbackOption2}
                                    className="buttonPrimary"
                                >
                                    {this.props.option2_text}
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={this.closeModal}
                                    className="buttonSecondary"
                                >
                                    {this.props.t("common.buttons.cancel")}
                                </button>
                                <button
                                    onClick={this.callFunctionAndClose}
                                    className="buttonPrimary"
                                >
                                    {this.props.t("common.buttons.accept")}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DecisionModal);
