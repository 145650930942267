import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/modals.css";
class EditCustomerModal extends React.Component {
    constructor() {
        super();
        this.state = {};
    }
    returnValue = (e) => {
        this.props.callbackFunction(this.state);
        this.closeModal();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.values !== this.props.values) {
            Object.keys(this.props.values).forEach((key) => {
                this.setState({ [key]: this.props.values[key] }, () => {});
            });
        }
    };
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };
    changeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent modal-lg bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-2xl">
                            <span className="font-normal">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.editTitle",
                                )}
                                :
                            </span>{" "}
                            {this.state.contact_name}
                        </div>
                        <div
                            className="button cursor-pointer"
                            onClick={this.closeModal}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <div className="modal-content h-auto py-4 px-2 rounded text-gray-500">
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.customerTable.contact",
                                )}
                                : {this.state.contact_name}
                            </label>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.customerTable.company",
                                )}
                            </label>
                            <input
                                name="company"
                                onChange={this.changeInput}
                                className="inputCustomer w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                value={this.state.company}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.customerTable.mail",
                                )}
                            </label>
                            <input
                                name="email"
                                onChange={this.changeInput}
                                className="inputCustomer w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                value={this.state.email}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.customerTable.phone",
                                )}
                            </label>
                            <input
                                name="phone"
                                onChange={this.changeInput}
                                className="inputCustomer w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                value={this.state.phone}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">
                                {this.props.t(
                                    "sections.management.section.commercial.customers.customerTable.discount",
                                )}
                            </label>
                            <input
                                name="discount"
                                onChange={this.changeInput}
                                type="number"
                                className="inputCustomer w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                value={this.state.discount}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="buttonPrimary"
                            onClick={this.returnValue}
                        >
                            {this.props.t("common.buttons.submit")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EditCustomerModal);
