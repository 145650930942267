import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash.isequal";
import { components } from "react-select";
import GroupedSelect from "./GroupedSelect/GroupedSelect";
import CustomContentOption from "./GroupedSelect/CustomComponents/CustomContentOption";
import {
    editCard,
    getCard,
    deleteOOH_Status,
} from "../content/boardComponent/BoardFunctions";
import ExtraButtonsMenu from "../utils/ExtraButtonsMenu";
import DecisionModal from "../utils/DecisionModal";
import ReactTooltip from "react-tooltip";

class BoardCardModalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            card: {},
            cardFields: ["title", "description"],
            cardBeingEdited: {},
            selectedContent: [{}],
            hasChange: false,
        };
    }

    componentDidUpdate = () => {
        if (!isEqual(this.props.cardBeingEdited, this.state.cardBeingEdited)) {
            if (this.props.cardBeingEdited !== undefined) {
                let id = this.props.cardBeingEdited.contentId;
                let content = this.props.contents.find(
                    (content) => content._id === id,
                );
                let selectedContent = [
                    {
                        label: content.name,
                        value: content._id,
                    },
                ];
                this.setState({
                    cardBeingEdited: this.props.cardBeingEdited,
                    selectedContent: selectedContent,
                    content: content,
                });
            }
        }
    };

    /**
     * close modal and set the state card in {}
     */
    closeModal = () => {
        document.getElementById("BoardCardModalEdit").classList.add("hidden");
        this.setState({ card: {}, hasChange: false });
    };

    /**
     * change the value of the imput selected
     * @param {*} e the new value of the input
     */
    changeInput = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let { cardBeingEdited } = this.state;
        cardBeingEdited[field] = value;
        this.setState({ cardBeingEdited, hasChange: true });
    };
    //Deprecated
    handleSaveChanges = () => {
        if (this.isAnObjectValid()) {
            this.props.saveChanges(this.state.cardBeingEdited);
            this.closeModal();
        } else {
            this.props.showNotification({
                type: "warning",
                text: "llena todos los campos",
            });
        }
    };
    /**
     * return that boolean that say if the object cardBeingEdited has atributes empty
     * @returns boolean
     */
    isAnObjectValid = () => {
        let objectAtributes = Object.values(this.state.cardBeingEdited).filter(
            (values) => values === "",
        );
        if (objectAtributes.length === 0) {
            return true;
        }
        return false;
    };

    /**
     * Return values ​​from select in the correct format
     * @returns array of object for the select
     */
    selectContentOptions = () =>
        this.props.contents?.map((c) => {
            return {
                label: c.name,
                value: c._id,
            };
        });
    /**
     * Handles the changes of selected content and saves it in state
     * @param {Array} selected
     */
    handleSelectContent = (selected) => {
        let content;
        if (selected === null) {
            selected = [];
        } else {
            content = this.props.contents.find(
                (content) => content._id === selected.value,
            );
        }
        this.setState({
            selectedContent: selected,
            content: content,
            hasChange: true,
        });
    };

    /**
     * verify that the card has a correct format and update it
     */
    editCard = async () => {
        let { cardBeingEdited } = this.state;
        let response = Object.values(cardBeingEdited).filter((e) => e === "");
        if (response.length === 0 && this.state.content) {
            this.uploadCard(cardBeingEdited);
            this.closeModal();
        } else {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.completeFields"),
            });
        }
    };

    /**
     * Upload the card to DB
     * @param {*} card with the new atributes.
     */
    uploadCard = async (card) => {
        card.contentId = this.state.content._id;
        try {
            let responseAdd = await editCard(card);
            if (responseAdd.status === 200) {
                let responseGetCard = await getCard(responseAdd.data.id);
                responseAdd = responseGetCard.data;

                this.props.replaceCard(responseAdd);
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "warning",
                text: this.props.t(
                    "common.notification.status.errorToEditCard",
                ),
            });
        }
    };

    /**
     * @returns Array of buttons
     */
    getButtonList = () => {
        let buttonList = [
            {
                title: this.props.t(
                    "sections.calendar.wizard.extraButtons.delete",
                ),
                view: true,
                function: () => {
                    this.props.openModal("deleteCard");
                },
                icon: (
                    <FontAwesomeIcon icon={["fal", "trash"]} className="mr-3" />
                ),
                class: "text-red-500",
            },
        ];
        return buttonList;
    };

    /**
     * Delete a card to the Db and the
     */
    handleDelete = async () => {
        try {
            let response = await deleteOOH_Status(
                this.state.cardBeingEdited._id,
            );
            if (response.status === 200) {
                this.props.deleteCard(this.state.cardBeingEdited);
                this.closeModal();
                this.props.showNotification({
                    type: "success",
                    text: this.props.t(
                        "common.notification.status.successToDeleteCard",
                    ),
                });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.status.errorToDeleteCard",
                ),
            });
        }
    };

    /**
     * open a modal of confirmation
     */
    handleEditCard = () => {
        this.props.openModal("comfirmationSaveChangesCard");
    };

    render() {
        const CustomOption = (props) => {
            let filteredOption = this.props.contents.filter(
                (c) => c._id === props.value,
            );
            return (
                <components.Option {...props}>
                    <CustomContentOption
                        content={filteredOption}
                        props={props}
                    />
                </components.Option>
            );
        };

        return (
            <div
                id={"BoardCardModalEdit"}
                className="modal z-10 fixed w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div
                    className={`modalContent   ${!this.state.step === 1 ? "sm:w-auto" : ""} w-auto md:w-4/6 lg:w-6/12 xl:w-5/12 min-w-96 bg-white rounded-md p-5 flex flex-col `}
                >
                    <div className="modal-header relative font-extralight text-lg flex justify-end px-3">
                        <div
                            className="button cursor-pointer absolute"
                            onClick={() => this.closeModal()}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <ExtraButtonsMenu
                        id={"modalID"}
                        buttons={this.getButtonList()}
                    ></ExtraButtonsMenu>
                    <div>
                        <div className="flex flex-col">
                            <p className="  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                                {this.props.t("sections.status.title")}:
                            </p>
                            <input
                                id="inputTitleCard"
                                type="text"
                                onChange={this.changeInput}
                                name="title"
                                value={this.state.cardBeingEdited?.title}
                                className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-2  leading-tight focus:outline-none focus:bg-white"
                            ></input>
                        </div>
                        <div className="flex flex-col">
                            <p className="uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                                {this.props.t(
                                    "sections.wizard.stepThree.description",
                                )}
                                :
                            </p>
                            <input
                                type="text"
                                onChange={this.changeInput}
                                name="description"
                                value={this.state.cardBeingEdited?.description}
                                className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-2  leading-tight focus:outline-none focus:bg-white"
                            ></input>
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <label className="  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                                    {this.props.t(
                                        "sections.calendar.wizard.step2.content",
                                    )}
                                    :
                                </label>
                            </div>
                            <div className="flex justify-between">
                                <div className="w-full ">
                                    <GroupedSelect
                                        getGroupedOptions={
                                            this.selectContentOptions
                                        }
                                        selected={this.state.selectedContent}
                                        customComponents={{
                                            Option: CustomOption,
                                        }}
                                        changeCallback={
                                            this.handleSelectContent
                                        }
                                        extraProps={{
                                            closeMenuOnSelect: "false",
                                            maxMenuHeight: 200,
                                        }}
                                    ></GroupedSelect>
                                </div>
                                <ReactTooltip
                                    effect="solid"
                                    place="top"
                                    className="tooltipIndex"
                                    id={"editContentTooltip"}
                                >
                                    <p className="text-center   ">
                                        {this.props.t(
                                            "sections.status.editContent",
                                        )}
                                    </p>
                                </ReactTooltip>

                                <div
                                    className="w-5 ml-2 flex justify-end "
                                    data-tip
                                    data-for={"editContentTooltip"}
                                >
                                    <FontAwesomeIcon
                                        onClick={() => {
                                            this.props.editContent(
                                                "contentList",
                                                this.state?.content,
                                            );
                                        }}
                                        className="mt-2 cursor-pointer"
                                        icon={["fal", "sliders-h"]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div
                                className="buttonSecondary cursor-pointer"
                                onClick={() => this.closeModal()}
                            >
                                {this.props.t("common.buttons.cancel")}
                            </div>
                            {this.state.hasChange ? (
                                <div
                                    className="buttonPrimary cursor-pointer"
                                    onClick={() => this.handleEditCard()}
                                >
                                    {this.props.t("sections.status.editCard")}
                                </div>
                            ) : (
                                <div className="bg-gray-200 cursor-pointer hover:bg-gray-100 text-black border border-gray-200  transition-colors duration-300 ease-in-out font-light text-sm my-2 mr-2 p-1 py-2 px-2 lg:px-4 focus:outline-none">
                                    {this.props.t("sections.status.editCard")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <DecisionModal
                    modalID="deleteCard"
                    text={this.props.t("sections.modal.deleteModal.warning")}
                    callbackFunction={this.handleDelete}
                ></DecisionModal>
                <DecisionModal
                    modalID="comfirmationSaveChangesCard"
                    text={this.props.t("sections.status.wishSaveChanges")}
                    callbackFunction={this.editCard}
                ></DecisionModal>
            </div>
        );
    }
}
export default withTranslation()(BoardCardModalEdit);
