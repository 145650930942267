import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select, { components } from "react-select";
import Title from "../common/Title";

class MailingLayout extends Component {
    render() {
        const { isActiveMailingMenu, defaultOptionSelectPeriodicity } =
            this.props;
        const customPeriodicityOption = (props) => {
            return (
                <components.Option {...props}>
                    {props.children}
                    <span className="ml-2">
                        {this.props.t(
                            "sections.management.section.mailing.contactMail.selectOptionDecorator",
                        )}
                    </span>
                </components.Option>
            );
        };
        const singleValue = ({ children, ...props }) => (
            <components.SingleValue {...props}>
                {children}{" "}
                {this.props.t(
                    "sections.management.section.mailing.contactMail.selectOptionDecorator",
                )}
            </components.SingleValue>
        );
        return (
            <div className="flex divide-x divide-dotted">
                <div className="w-20 md:w-44">
                    <nav className="flex flex-col">
                        {/* for future   onClick={() => this.props.handleMailingMenu("contactMail")}  */}
                        <button
                            id="commercialSectionPricing"
                            className={`${isActiveMailingMenu === "contactMail" ? "tabActiveV font-semibold" : ""} tabGeneral uppercase text-xs text-right`}
                        >
                            <span className="hidden md:inline-block">
                                {this.props.t(
                                    "sections.management.section.mailing.contactMail.tab",
                                )}
                            </span>{" "}
                            <FontAwesomeIcon
                                icon={["fa", "at"]}
                                className="text-blue-500"
                                fixedWidth
                            />
                        </button>
                    </nav>
                </div>
                <div className="px-4 w-full md:w-10/12 pb-10">
                    <div
                        className={`${isActiveMailingMenu === "contactMail" ? "block" : "hidden"} w-full`}
                    >
                        <Title>
                            {this.props.t(
                                "sections.management.section.mailing.title",
                            )}
                            <p className="font-light">
                                {this.props.t(
                                    "sections.management.section.mailing.contactMail.description",
                                )}
                            </p>
                        </Title>
                    </div>
                    <div className="flex flex-col w-1/3">
                        <div className="w-full my-2">
                            <input
                                type="text"
                                id="contactMailInput"
                                value={this.props.parseEmailsAdresses()}
                                onChange={(e) =>
                                    this.props.onChangeEmailAdresses(e)
                                }
                                tabIndex="1"
                                className="shadow appearance-none border rounded w-full my-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                placeholder={this.props.t(
                                    "sections.management.section.mailing.contactMail.placeholder",
                                )}
                            />
                        </div>
                        <div className="w-full my-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                <p className="font-light">
                                    {this.props.t(
                                        "sections.management.section.mailing.contactMail.periodicity",
                                    )}
                                </p>
                            </label>
                            <Select
                                className="w-full"
                                onChange={(e) =>
                                    this.props.handlePeriodicityOnMailContact(e)
                                }
                                value={defaultOptionSelectPeriodicity}
                                options={
                                    this.props.getPeriodicityOptions() || {}
                                }
                                components={{
                                    Option: customPeriodicityOption,
                                    SingleValue: singleValue,
                                }}
                            />
                        </div>
                        <button
                            className="buttonPrimary ml-10"
                            onClick={(e) => this.props.setPeriodicityMail()}
                        >
                            {this.props.t(
                                "sections.management.section.mailing.contactMail.button",
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(MailingLayout);
