import React, { Component } from "react";
import {
    getCameraByElectronIDAndOrder,
    getScreenNameOfCamera,
} from "../management/Management.Functions";
import CameraDisplay from "../utils/Cameras/CameraDisplay";
import ErrorMessage from "../utils/common/ErrorMessage";
import { withTranslation } from "react-i18next";

class SharedCameraView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraElectronId: "",
            cameraOder: "",
            screenName: "",
            error: null,
        };
    }

    componentDidMount = async () => {
        let location = window.location;
        let cameraElectronId =
                location.pathname.split("/")[
                    location.pathname.split("/").length - 2
                ],
            cameraOder =
                location.pathname.split("/")[
                    location.pathname.split("/").length - 1
                ];
        try {
            let camera = await getCameraByElectronIDAndOrder(
                cameraElectronId,
                cameraOder,
            );
            let screenName = await getScreenNameOfCamera(camera.data._id);
            if (camera.data && screenName.data) {
                this.setState(
                    {
                        camera: camera.data,
                        screenName: screenName.data,
                    },
                    () => {
                        this.props.setIsShared();
                    },
                );
            } else {
                this.setState({
                    error: {
                        message: this.props.t(
                            "sections.management.section.cameras.view.noStream",
                        ),
                    },
                });
            }
        } catch (error) {
            this.setState({
                error: {
                    message: this.props.t(
                        "sections.management.section.cameras.view.noStream",
                    ),
                },
            });
        }
    };

    render() {
        const { screenName, error } = this.state;
        return (
            <div
                className={`w-screen h-screen flex justify-center items-center bg-cover bg-center bg-gradient-to-r ${process.env.REACT_APP_WL_NAME.toString() === "taggify" ? "from-green-400 to-blue-500 w-4/6" : "from-purple-400 to-pink-800 w-full"}`}
            >
                <div
                    className={`absolute hidden md:block h-full text-white p-4 left-0`}
                >
                    <div className="logo overflow-hidden">
                        <img
                            className="h-10 object-left-top"
                            src={require(
                                `../../images/${process.env.REACT_APP_WL_NAME}/logo-white.svg`,
                            )}
                            alt="Taggify Simple Signage"
                        />
                    </div>
                    <div className="logo-product"></div>
                    <div className="absolute z-10 bottom-5 font-light flex flex-col">
                        <p>
                            Shared{" "}
                            <span className="text-lg font-bold subpixel-antialiased">
                                Camera
                            </span>
                        </p>
                    </div>
                </div>
                <div className="margin-y-auto rounded p-3 w-4/6 h-4/6 bg-gray-200 flex flex-col items-center justify-center">
                    <p className="w-5/6 text-xl">{screenName}</p>
                    {error ? (
                        <ErrorMessage message={error.message} />
                    ) : (
                        this.state.camera && (
                            <CameraDisplay
                                camera={this.state.camera}
                                needLink={false}
                                needScreenshot={false}
                            />
                        )
                    )}
                </div>
            </div>
        );
    }
}
export default withTranslation()(SharedCameraView);
