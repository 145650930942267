import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
export const getScreens = async () => {
    try {
        const screens = await instance_axios.get("/generic/screen", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return screens;
    } catch (err) {
        return err;
    }
};
export const getPlayerVersionLatest = async (os) => {
    try {
        const user = await instance_axios.get("/player/version/" + os, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return user;
    } catch (err) {
        return err;
    }
};
export const getUser = async () => {
    try {
        const user = await instance_axios.get("/user", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return user;
    } catch (err) {
        return err;
    }
};
export const addNewUser = async (data) => {
    try {
        const r = await instance_axios.post("/user/addnewuser", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.response;
    }
};
export const getCustomers = async () => {
    try {
        const user = await instance_axios.get("/generic/customer", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return user;
    } catch (err) {
        return err;
    }
};
export const addCustomer = async (data) => {
    try {
        const r = await instance_axios.post("/generic", [data], {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err;
    }
};
export const getBrands = async () => {
    try {
        const user = await instance_axios.get("/generic/brand", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return user;
    } catch (err) {
        return err;
    }
};
export const addBrand = async (data) => {
    try {
        const r = await instance_axios.post("/generic", [data], {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err;
    }
};
export const modifyBrand = async (brand) => {
    try {
        const r = await instance_axios.put("/generic", brand, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const deleteBrand = (brandID) => {
    return instance_axios
        .delete("/generic" + brandID, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err.request);
};
export const setCurrencyOnUser = async (currency) => {
    try {
        const r = await instance_axios.put(
            "/user/setcurrency",
            { currency },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const getUserData = async () => {
    try {
        const r = await instance_axios.get("/user/data", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const editTableManagement = async (data) => {
    try {
        const r = await instance_axios.put("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const removeItem = async (id) => {
    try {
        const r = await instance_axios.delete("/generic/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const modifyCustomers = async (customer) => {
    try {
        const r = await instance_axios.post("/generic", customer, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const deleteUserWithRole = async (id) => {
    try {
        const r = await instance_axios.delete("/user/delete/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const verifyMail = async (email) => {
    try {
        const r = await instance_axios.get("/user/verifyMail" + email, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err;
    }
};
export const setProgrammatic = async (data) => {
    try {
        const r = await instance_axios.put("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const setPublisherID = async (publisher_id) => {
    try {
        const r = await instance_axios.put(
            "/user/setPublisherID",
            { publisher_id },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const getAllSSPID = async () => {
    try {
        const r = await instance_axios.get("/screen/getallsspids", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const setMailing = async (data) => {
    try {
        const r = await instance_axios.post("/user/setmailing", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const putObject = async (data) => {
    try {
        const r = await instance_axios.put("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const changeCommercializeString = async (
    commercialize_all_by,
    screens_ids,
) => {
    try {
        const r = await instance_axios.put(
            "/user/setcommercialize",
            { commercialize_all_by, screens_ids },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};

export const addCameraConfig = async (data) => {
    try {
        const r = await instance_axios.post("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const editCameraConfig = async (data) => {
    try {
        const r = await instance_axios.put("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const deleteCameraConfig = async (id) => {
    try {
        const r = await instance_axios.delete("/generic/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const getAllCameras = async () => {
    try {
        const r = await instance_axios.get("/generic/camera", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const getCameraByID = async (id) => {
    try {
        const r = await instance_axios.get("/generic/camera/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const getCameraByElectronIDAndOrder = async (id, order) => {
    try {
        const r = await instance_axios.get("/camera/" + id + "/" + order, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const getScreenNameOfCamera = (id) => {
    return instance_axios
        .get("/camera/screen/name" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};

export const getStreamingOfCamera = (
    id,
    electron_id,
    order,
    needScreenshot,
) => {
    return instance_axios
        .post(
            `/camera/stream/${id}/${order}`,
            { needScreenshot, electron_id },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        )
        .then((r) => r)
        .catch((err) => err.request);
};

export const triggerCameraChanges = (screen_id) => {
    return instance_axios
        .get(`/camera/triggercamerachanges/${screen_id}`, {
            withCredentials: true,
            headers: { common: headers },
        })
        .then((r) => r)
        .catch((err) => err);
};
