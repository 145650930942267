import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";

class ScreenPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [10, 20, 30, 40, 50],
        };
    }
    onChangeSelect = (event) => {
        this.props.handleOnChange(event);
    };

    render() {
        return (
            <div className="p-2 flex justify-end mr-0.5">
                {this.props.itemsLength > 10 && (
                    <>
                        <div className="min-w-xs mr-5">
                            <span className="text-gray-500 mr-2">
                                {this.props.t(
                                    "sections.screens.screenPagination.rowPerPage",
                                )}{" "}
                            </span>
                            <select
                                onChange={this.onChangeSelect}
                                className="text-xs w-12 h-1/12 py-0 px-0.5 mr-2"
                            >
                                {this.state.options.map((item) => {
                                    return (
                                        <option
                                            selected={
                                                this.props.limit.toString() ===
                                                item.toString()
                                            }
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                            <span className="text-gray-500">
                                {(this.props.page - 1) * this.props.limit + 1}-
                                {Math.ceil(
                                    this.props.itemsLength / this.props.limit,
                                ) !== this.props.page
                                    ? this.props.page * this.props.limit
                                    : this.props.itemsLength}{" "}
                            </span>
                        </div>
                        <div>
                            <ReactPaginate
                                onPageChange={
                                    this.props.handlePaginationOnChange
                                }
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName="border border-gray-400 divide-x divide-solid divide-gray-400 rounded flex list-style-none text-xs font-semibold"
                                pageClassName="cursor-pointer relative p-1 m-0 block bg-transparent outline-none transition-all duration-300 flex items-center hover:bg-gray-100 focus:shadow-none"
                                pageLinkClassName="page-link px-1 "
                                previousClassName="cursor-pointer relative block py-1.5 px-3 bg-transparent outline-none transition-all duration-300 focus:shadow-none"
                                previousLinkClassName="page-link p-1"
                                nextClassName="cursor-pointer relative block py-1.5 px-3 bg-transparent outline-none transition-all duration-300 focus:shadow-none"
                                nextLinkClassName="page-link p-1"
                                breakClassName="cursor-pointer relative block py-1.5 px-1 bg-transparent outline-none transition-all duration-300 focus:shadow-none"
                                breakLinkClassName="page-link p-1"
                                activeClassName="bg-blue-500 text-gray-50 hover:bg-blue-500 "
                                activeLinkClassName="m-0 "
                                pageCount={Math.ceil(
                                    this.props.itemsLength / this.props.limit,
                                )}
                                breakLabel="..."
                                previousLabel={
                                    <FontAwesomeIcon
                                        className=" ml-0.5"
                                        icon={["fal", "chevron-left"]}
                                    />
                                }
                                nextLabel={
                                    <FontAwesomeIcon
                                        className="ml-0.5"
                                        icon={["fal", "chevron-right"]}
                                    />
                                }
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
}
export default withTranslation()(ScreenPagination);
