import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
export const register = (newUser) => {
    return instance_axios
        .post("/auth/register", newUser)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const login = async (user) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    return instance_axios
        .post(
            "/auth/login",
            {
                email: user.email,
                password: user.password,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
                withCredentials: true,
            },
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const forgot_password = (email) => {
    return instance_axios
        .put("/auth/forgotpassword", {
            email: email,
        })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const reset_password = (newData) => {
    return instance_axios
        .put("/auth/resetpassword", {
            resetLink: newData.resetLink,
            newPass: newData.newPass,
        })
        .then((res) => res)
        .catch((err) => err);
};
export const isLogin = () => {
    if (localStorage.getItem("user") !== null) {
        return true;
    }
    return document.cookie.includes("jwt=");
};
/**
 * Checks if URL has "shared" param
 * @returns {Boolean}
 */
export const isPublic = () => {
    return document.URL.includes("shared");
};
export const remainingSecondsToExpireSession = () => {
    return instance_axios
        .get("/auth/check-login", { withCredentials: true })
        .then((res) => {
            if (res.status === 200)
                return (
                    res.data.expirationAt -
                    Number(Date.now().toString().slice(0, 10)) -
                    60
                );
            else return 0;
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    // Session expired
                    return 0;
                }
            } else {
                console.log(err);
                return 0;
            }
        });
};
export const logOut = () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    return instance_axios
        .get("/auth/logOut", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};
export const confirmAccount = (code) => {
    return instance_axios
        .post(`/auth/confirm_account/${code}`)
        .then((r) => r)
        .catch((err) => err.response);
};
