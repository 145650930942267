export const orderByProp = (items = [], prop, screen_id) => {
    let sorted = items.sort((a, b) => {
        let indexA;
        if (a[prop]) {
            a[prop].forEach((ord, index) => {
                if (ord.screen_id === screen_id) {
                    indexA = index;
                }
            });
        }
        let indexB;
        if (b[prop]) {
            b[prop].forEach((ord, index) => {
                if (ord.screen_id === screen_id) {
                    indexB = index;
                }
            });
        }
        if (indexA !== undefined && indexB !== undefined) {
            return a[prop][indexA].index - b[prop][indexB].index;
        } else if (indexA === undefined && indexB !== undefined) {
            return 1;
        } else if (indexA !== undefined && indexB === undefined) {
            return -1;
        } else {
            return 0;
        }
    });
    return sorted;
};

/**
 * Return the word passed per parameter whit the first letter in uppercase.
 * @param {string} word a string of characters that represents a word that in turn represents a meaning an idea according to its language culture region and time, which is transmitted from generation to generation through speech, writing and technology
 * @returns {string} word capitalized.
 */
export const capitalize = (word) => {
    return word[0].toUpperCase() + word.slice(1).toString();
};
