import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

export const sendForm = (dataArray) => {
    return instance_axios
        .post("/user/support", dataArray, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};

export const getLoggedUser = () => {
    return instance_axios
        .get("/user/getLoggedUser", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};
