import React, { Component } from "react";
import { reset_password } from "./UserFunctions";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { ReactComponent as LogoWhite } from '../../images/logo-white.svg';
// import { ReactComponent as LogoColor } from '../../images/logo-color.svg';
class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            newPass: "",
            message: "",
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    onSubmit(e) {
        e.preventDefault();
        const url = this.props.location.pathname;
        const resetLink = url.substring(url.lastIndexOf("/") + 1);
        const newData = {
            resetLink: resetLink,
            newPass: this.state.newPass,
        };
        reset_password(newData).then((res) => {
            if (!res.error) {
                this.setState({ message: res.message });
                document.getElementById("p_error").classList.remove("hidden");
                setTimeout(() => {
                    this.props.history.push("/login");
                }, 3000);
            } else {
                console.log(res.error);
            }
        });
    }

    render() {
        return (
            <div className="w-full h-screen flex justify-between">
                <div className="relative hidden md:block w-4/6 h-full text-white p-4 bg-cover bg-center bg-gradient-to-r from-green-400 to-blue-500">
                    <div className="logo overflow-hidden">
                        {/* <LogoWhite className="h-10 object-left-top" /> */}
                        <img
                            className="h-10 object-left-top"
                            src={require(
                                `../../images/${process.env.REACT_APP_WL_NAME}/logo-white.svg`,
                            )}
                            alt="Taggify Simple Signage"
                        />
                    </div>
                    <div className="logo-product"></div>
                    <div className="absolute z-10 bottom-0 right-0 text-right p-4 font-light">
                        Simple
                        <span className="text-lg font-bold subpixel-antialiased">
                            Signage
                        </span>
                        <br />
                        {this.props.t("sections.login.slogan")}
                    </div>
                </div>
                <div className="relative w-full md:w-2/6 h-full text-black p-4 flex flex-col">
                    <div className="logo block md:hidden">
                        {/* <LogoColor /> */}
                        <img
                            src={require(
                                `../../images/${process.env.REACT_APP_WL_NAME}/logo-color.svg`,
                            )}
                            alt="Taggify Simple Signage"
                        />
                    </div>
                    <div className="flex justify-end md:hidden content-center mt-2">
                        <div className="inline font-bold mt-3 ml-2 text-gray-700">
                            <span className="font-extralight">Simple</span>
                            Signage
                        </div>
                        <div className="align-bottom font-extralight text-gray-400 text-xs mt-4 ml-4">
                            by Taggify
                        </div>
                    </div>
                    <div className="mt-5 p-0 md:p-8 h-full font-light">
                        <form
                            noValidate
                            onSubmit={this.onSubmit}
                            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                        >
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="password"
                                >
                                    {this.props.t(
                                        "sections.resetPassword.newPassword",
                                    )}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                    id="newPass"
                                    type="password"
                                    placeholder={this.props.t(
                                        "sections.resetPassword.newPasswordPlaceHolder",
                                    )}
                                    value={this.state.newPass}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:focus:ring"
                                >
                                    {this.props.t(
                                        "sections.resetPassword.changePassword",
                                    )}
                                </button>
                            </div>
                            <p id="p_error" className="hidden">
                                {this.state.message}
                            </p>
                        </form>
                    </div>
                    <div className="fixed bg-white p-1 rounded-sm ring-1 ring-black ring-opacity-5 bottom-0 right-0 text-center text-xs mr-3 text-gray-600">
                        {this.props.t("sections.register.alreadyHaveAccount")}{" "}
                        <Link
                            className="font-medium text-blue-600 hover:text-indigo-500"
                            to="/login"
                        >
                            {" "}
                            {this.props.t("common.buttons.signin")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ResetPassword);
