import cloneDeep from "lodash.clonedeep";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ButtonSwitcher from "../../buttons/ButtonSwitcher";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserData } from "../../management/Management.Functions";
import isEqual from "lodash.isequal";

class Step3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {},
        };
    }
    componentDidMount() {
        this.getUserCurrency();
        let event = cloneDeep(this.props.event);
        if (event) {
            if (event.programmaticExclusivity) {
                event.isExclusive = event.programmaticExclusivity;
            }
            this.setState({
                event: event,
                hasOwnDuration: event.duration ? true : false,
                eventHasValue: event.value !== undefined,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.event, this.props.event)) {
            let event = this.props.event;
            if (event.programmaticExclusivity) {
                event.isExclusive = event.programmaticExclusivity;
            }
            this.setState({
                event: event,
                hasOwnDuration:
                    event.duration !== undefined || prevState.hasOwnDuration,
                eventHasValue:
                    event.value !== undefined || prevState.eventHasValue,
            });
        }
    }
    /**
     * Function that gets the currency of logged user and sets it in state
     */
    getUserCurrency = () => {
        getUserData()
            .then((res) => {
                if (res) {
                    this.setState({ userCurrency: res.data.currency || "$" });
                }
            })
            .catch((err) => console.log(err));
    };
    /**
     * Function that trigger a callback function
     */
    addEvent = () => {
        let { event } = this.state;
        if (
            event.programmaticExclusivity &&
            !event.hasOwnProperty("impression")
        ) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t(
                    "common.notification.calendar.programmaticExclusiveLimit",
                ),
            });
            return;
        }
        this.props.callFunctionAndClose(this.state.event);
    };
    /**
     * Function that swaps the hasOwnDuration property of state and in case of false, delete duration property from event
     */
    handleDuration = () => {
        let event = cloneDeep(this.state.event);
        this.setState(
            {
                hasOwnDuration: !this.state.hasOwnDuration,
            },
            () => {
                if (!this.state.hasOwnDuration) {
                    delete event.duration;
                }
                this.setState({
                    event: event,
                });
            },
        );
    };
    /**
     * !Not used today
     * Function that swaps isLoop property from event and sets it in state
     */
    handleEventLoop = () => {
        let event = cloneDeep(this.state.event);
        event.isLoop = !event.isLoop;
        this.setState({
            event: event,
        });
    };
    /**
     * Function that trigger the handleExclusive callback function
     */
    handleExclusive = () => {
        let event = cloneDeep(this.state.event);
        if (!event.programmaticExclusivity) {
            this.props.handleExclusive(event);
        }
    };
    /**
     * Function thar set the confirmed event property in true
     */
    functionToConfirm = () => {
        let event = cloneDeep(this.state.event);
        event.confirmed = true;
        this.setState(
            {
                event: event,
            },
            () => {
                this.props.callFunctionAndClose(event);
            },
        );
    };
    /**
     * Function that handles the eventHasValue property from state
     */
    handleValueVisibility = () => {
        this.setState({
            eventHasValue: !this.state.eventHasValue,
        });
    };
    /**
     * !Not used today
     * Function that creates a field in event and sets its value
     * @param {*} event
     */
    handlePMPchange = (event) => {
        let ids = event.target.id.split("-");
        let field = ids[ids.length - 1].toLowerCase();
        let editedEvent = cloneDeep(this.state.event);
        editedEvent[field] = event.target.value;
        this.setState({
            event: editedEvent,
        });
    };
    render() {
        const { event } = this.state;
        return (
            <div className="textStepFor w-full ">
                <div className="flex w-full align-center py-1">
                    <ReactTooltip
                        effect="solid"
                        className="p-2 tex-center"
                        id={"thirdStep-Value"}
                        aria-haspopup="true"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step3.valueInfo",
                        )}
                    </ReactTooltip>
                    <div className="w-1/2 flex flex-col">
                        <label className="flex justify-start text-gray-600">
                            <span className="uppercase text-xs font-bold">
                                {this.props.t(
                                    "sections.calendar.wizard.step3.hasValue",
                                )}
                                ?
                            </span>
                            <FontAwesomeIcon
                                icon={["fas", "question-circle"]}
                                className="my-auto pl-1 text-gray-400"
                                data-tip
                                data-for="thirdStep-Value"
                            />
                        </label>
                        {this.state.eventHasValue !== undefined && (
                            <div className="w-min">
                                <ButtonSwitcher
                                    className="my-auto"
                                    handleVisibility={() =>
                                        this.handleValueVisibility()
                                    }
                                    status={this.state.eventHasValue}
                                    labelOn={this.props.t("common.buttons.yes")}
                                    labelOff={this.props.t("common.buttons.no")}
                                    revertLabel={true}
                                />
                            </div>
                        )}
                    </div>
                    {
                        this.state.eventHasValue && (
                            <div class="w-full flex rounded-md shadow-sm">
                                <span class="inline-flex items-center px-3 rounded-l border border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                    {" "}
                                    {this.state.userCurrency}{" "}
                                </span>
                                <input
                                    type="number"
                                    onChange={(e) =>
                                        this.props.handleInputChange(
                                            "value",
                                            e.target.value,
                                            true,
                                        )
                                    }
                                    id="new-value"
                                    class="w-full appearance-none block uppercase text-gray-600 border border-gray-200 border-l-0 rounded-r px-4 leading-tight focus:outline-none focus:bg-white"
                                    value={event.value}
                                />
                            </div>
                        )
                        // <input type="number" className="ml-1 my-2 w-full appearance-none block uppercase text-gray-600 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white" id="new-value" onChange={this.handleInputChange} defaultValue={event.value}/>
                    }
                </div>
                <label htmlFor="text" className="flex w-auto justify-center">
                    <div
                        htmlFor="exclusive"
                        className="flex flex-col flex-1 pr-2 tracking-wide text-gray-600 mb-2 mt-3"
                    >
                        <ReactTooltip
                            effect="solid"
                            className="p-2 tex-center"
                            id={"thirdStep-Exclusive"}
                            aria-haspopup="true"
                        >
                            {this.props.t(
                                "sections.calendar.wizard.step3.exclusiveInfo",
                            )}
                        </ReactTooltip>
                        <label className="flex ">
                            <span className="uppercase text-xs font-bold">
                                {this.props.t(
                                    "sections.calendar.wizard.step3.isExclusive",
                                )}
                                ?
                            </span>
                            <FontAwesomeIcon
                                icon={["fas", "question-circle"]}
                                className="my-auto pl-1 text-gray-400"
                                data-tip
                                data-for="thirdStep-Exclusive"
                            />
                        </label>
                        <div className="flex justify-start">
                            <ButtonSwitcher
                                className="my-2 "
                                handleVisibility={
                                    event.programmaticExclusivity
                                        ? null
                                        : this.handleExclusive
                                }
                                status={event.isExclusive}
                                labelOn={this.props.t("common.buttons.yes")}
                                labelOff={this.props.t("common.buttons.no")}
                                revertLabel={true}
                            ></ButtonSwitcher>
                        </div>
                    </div>
                    <div
                        htmlFor="loop"
                        className="flex flex-col flex-1 pr-2 text-center uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-5 hidden"
                    >
                        {this.props.t("sections.calendar.wizard.step3.isLoop")}?
                        <ButtonSwitcher
                            className="my-2"
                            handleVisibility={() => this.handleEventLoop()}
                            status={event.isLoop}
                            labelOn={this.props.t("common.buttons.yes")}
                            labelOff={this.props.t("common.buttons.no")}
                            revertLabel={true}
                        ></ButtonSwitcher>
                    </div>
                </label>
                <div
                    className={`flex flex-col w-auto align-center ${this.state.event.programmaticExclusivity ? "hidden" : ""}`}
                >
                    <ReactTooltip
                        effect="solid"
                        className="p-2 tex-center max-w-screen-sm"
                        id={"thirdStep-Duration"}
                        aria-haspopup="true"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step3.durationInfo",
                        )}
                    </ReactTooltip>
                    <div className="flex">
                        <label className="flex justify-start text-gray-600 mr-3">
                            <span className="uppercase text-xs font-bold my-auto">
                                {this.props.t(
                                    "sections.calendar.wizard.step3.duration",
                                )}
                                :
                            </span>
                            <FontAwesomeIcon
                                icon={["fas", "question-circle"]}
                                className="my-auto pl-1 text-gray-400"
                                data-tip
                                data-for="thirdStep-Duration"
                            />
                        </label>
                        {this.state.hasOwnDuration !== undefined && (
                            <ButtonSwitcher
                                className="my-1"
                                handleVisibility={() => this.handleDuration()}
                                status={this.state.hasOwnDuration}
                                labelOn={this.props.t(
                                    "sections.calendar.wizard.step3.custom",
                                )}
                                labelOff={this.props.t(
                                    "sections.calendar.wizard.step3.default",
                                )}
                                revertLabel={true}
                            ></ButtonSwitcher>
                        )}
                    </div>
                    <input
                        type="number"
                        className={
                            "ml-1 my-2 w-full appearance-none block bg-slate-50 border-gray-200 uppercase text-gray-600 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white " +
                            (this.state.hasOwnDuration
                                ? "visible"
                                : "invisible")
                        }
                        id="duration"
                        onChange={(e) =>
                            this.props.handleInputChange(
                                "duration",
                                e.target.value,
                                true,
                            )
                        }
                        defaultValue={event.duration ? event.duration : ""}
                    />
                </div>

                <div className={"flex flex-col w-auto align-center"}>
                    <ReactTooltip
                        effect="solid"
                        className="p-2 tex-center max-w-screen-sm"
                        id={"thirdStep-Impression"}
                        aria-haspopup="true"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step3.impressionInfo",
                        )}
                    </ReactTooltip>
                    <label className="flex justify-start text-gray-600">
                        <span className="uppercase text-xs font-bold">
                            {this.props.t(
                                "sections.calendar.wizard.step3.impression",
                            )}
                            :
                        </span>
                        <FontAwesomeIcon
                            icon={["fas", "question-circle"]}
                            className="my-auto pl-1 text-gray-400"
                            data-tip
                            data-for="thirdStep-Impression"
                        />
                    </label>
                    <input
                        type="number"
                        className={
                            "ml-1 my-2 w-full appearance-none block bg-slate-50 border-gray-200 uppercase text-gray-600 border rounded px-4 leading-tight focus:outline-none focus:bg-white"
                        }
                        id="impression"
                        onChange={(e) =>
                            this.props.handleInputChange(
                                "impression",
                                e.target.value,
                                true,
                            )
                        }
                        defaultValue={event.impression ? event.impression : ""}
                    />
                </div>
                <label
                    htmlFor="pmp-ID"
                    className="flex flex-col uppercase tracking-wide text-gray-600 text-xs font-bold mb-2  mt-5 hidden"
                >
                    PMP-Deal-ID:
                    <input
                        type="text"
                        className="appearance-none block uppercase  text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="new-PMPdealID"
                        onChange={this.handlePMPchange}
                        defaultValue={event.pmpdealid}
                    />
                </label>
                <div className="flex justify-between mt-3">
                    <button
                        onClick={this.props.prevStep}
                        className="buttonSecondary"
                    >
                        {this.props.t("common.buttons.back")}
                    </button>
                    <button onClick={this.addEvent} className="buttonPrimary">
                        {this.props.t("common.buttons.complete")}
                    </button>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Step3);
