import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class GridItemDetailsCarrusel extends Component {
    render() {
        const item = this.props.item;
        return (
            <>
                <div>
                    <FontAwesomeIcon
                        icon={["fad", "clipboard-list"]}
                        className="text-blue-400 ml-1.5 mr-1.5 cursor-pointer"
                    />
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">
                        {this.props.t("sections.content.carousel")}
                    </span>
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={["fal", "calendar-alt"]}
                        className="text-blue-400 ml-1.5 mr-1.5"
                    />
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">
                        {item.schedule.startDate}
                    </span>
                    {item.schedule.endDate !== "" && (
                        <span className="playlist-item-size font-extralight pr-1 text-gray-600">
                            {item.schedule.endDate
                                ? "~ " + item.schedule.endDate
                                : ""}
                        </span>
                    )}
                </div>
            </>
        );
    }
}
export default withTranslation()(GridItemDetailsCarrusel);
