import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const instance_axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

export const getDirectImpressions = (filters) => {
    return instance_axios
        .post("/screen/impressions", filters, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};

export const getUserData = () => {
    return instance_axios
        .get("/user/data", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};

export const getCustomers = () => {
    return instance_axios
        .get("/generic/customer", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};

/**
 * This function sends a GET request to retrieve programmatic impressions data
 * Enpoint /programmatic/impressions
 * @param {Number} from The start date for the impressions request. formatted date: YYmmddhh
 * @param {Number} till The end date for the impressions data to be retrieved. formatted date: YYmmddhh
 * @param {String} groups  'day,month,year','tag','brand'
 * @param {Number} publisherID SSP ID of Publisher
 * @param {String} currency - If this parameter is not provided, the default currency of the user's account will
 * @returns The Promise that resolves a object if the request is successful
 */
export const getProgrammaticImpressionsRequests = (
    from,
    till,
    groups,
    publisherID,
    currency,
) => {
    return instance_axios
        .get("/programmatic/impressions", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
            params: {
                from: from,
                till: till,
                groups: groups,
                publisherID: publisherID,
                currency: currency,
            },
        })
        .then((r) => r)
        .catch();
};

/**
 * This function sends a GET request to retrieve programmatic screens data with a specific publisherID.
 * Endpoint /programmatic/screen
 * @param {number} publisherID SSP ID of Publisher
 * @returns The Promise that resolves a object if the request is successful
 * */
export const getProgrammaticScreensRequest = (publisherID) => {
    return instance_axios
        .get("/programmatic/screens", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
            params: {
                publisherID: publisherID,
            },
        })
        .then((r) => r)
        .catch();
};
