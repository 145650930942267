import React, { Component } from "react";
import "./carousel.css";
import ImageGallery from "react-image-gallery";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as dotenv from "dotenv";
dotenv.config();

class CarouselModal extends Component {
    constructor() {
        super();
        this.gallery = React.createRef();
        this.state = {
            images: [],
        };
        this.inputFile = React.createRef();
    }

    componentDidMount() {
        this.updateImages();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.carouselImages.length !== this.props.carouselImages.length
        )
            this.updateImages();
    }

    currentIndex = () => {
        return this.gallery.current.state.currentIndex;
    };

    updateImages = async () => {
        const { carouselImages } = this.props;
        const convertedImages = await this.convertImages(carouselImages);
        this.setState({ images: convertedImages });
    };

    convertImages = (images) => {
        return new Promise((resolve) => {
            const convertedImages = [];
            let allImagesAreStrings = true;

            if (images.length === 0) resolve(convertedImages);

            for (let i = 0; i < images.length; i++) {
                if (
                    typeof images[i] === "string" ||
                    images[i] instanceof String
                )
                    convertedImages.push({
                        original:
                            process.env.REACT_APP_FILESERVER_URL +
                            "/" +
                            images[i],
                        index: i,
                    });
                else {
                    this.convertImageFileToString(images[i], i).then((data) => {
                        convertedImages.push({
                            original: data.imageString,
                            index: data.index,
                        });

                        if (this.isLastImageToConvert(images, data.index))
                            resolve(this.sortImagesByIndex(convertedImages));
                    });

                    allImagesAreStrings = false;
                }
            }

            if (allImagesAreStrings)
                resolve(
                    convertedImages.map((image) => {
                        delete image.index;
                        return image;
                    }),
                );
        });
    };

    sortImagesByIndex = (images) =>
        images.sort((img1, img2) => img1.index - img2.index);

    convertImageFileToString = (imageFile, index) => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve({ imageString: reader.result, index });
            };

            reader.readAsDataURL(imageFile);
        });
    };

    isLastImageToConvert = (images, index) => {
        let lastImageToConvertIndex;

        images.forEach((image, i) => {
            if (typeof image !== "string" && !(image instanceof String))
                lastImageToConvertIndex = i;
        });

        return lastImageToConvertIndex === index;
    };

    handleDelete = () => {
        const index = this.currentIndex();
        this.props.deleteCurrentImage(index);
    };

    openInputFile = () => {
        this.inputFile.current.value = "";
        this.inputFile.current.click();
    };

    render() {
        const toggleCarouselModal = this.props.toggleCarouselModal;
        const handleImages = this.props.handleImages;

        return (
            <div>
                {/* <!--Modal--> */}
                <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modal-overlay backdrop absolute w-full h-full bg-gray-900 opacity-50"></div>

                    <div className="bg-white max-w-screen-md w-100 max-h-full mx-auto my-10 rounded shadow-lg z-50 overflow-y-auto">
                        <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                            <div className="title text-gray-600 text-2xl">
                                {this.props.t(
                                    "sections.modal.carouselModal.title",
                                )}
                            </div>
                            <div
                                className="button cursor-pointer"
                                onClick={toggleCarouselModal}
                            >
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </div>
                        </div>
                        <div className="modal-content py-4 text-left px-6 max-h-full relative">
                            {/* <!--Body--> */}
                            {this.state.images.length > 0 && (
                                <ImageGallery
                                    items={this.state.images}
                                    showPlayButton={false}
                                    showThumbnails={false}
                                    ref={this.gallery}
                                />
                            )}

                            {/* <!--Footer--> */}
                            <div className="clear-left flex justify-end pt-2">
                                <button
                                    className="px-4 bg-transparent p-3 rounded-lg text-blue-500 hover:bg-gray-100 hover:text-blue-400 mr-2"
                                    onClick={this.openInputFile}
                                >
                                    {this.props.t(
                                        "sections.modal.carouselModal.addPhoto",
                                    )}
                                </button>
                                <input
                                    className="hidden"
                                    name="files_input_nm"
                                    ref={this.inputFile}
                                    type="file"
                                    multiple={true}
                                    accept=".jpeg, .jpg, .png"
                                    placeholder={this.props.t(
                                        "sections.modal.carouselModal.addFile",
                                    )}
                                    onChange={handleImages}
                                />
                                {this.state.images.length > 0 && (
                                    <button
                                        className="px-4 bg-transparent p-3 rounded-lg text-blue-500 hover:bg-gray-100 hover:text-blue-400 mr-2"
                                        onClick={this.handleDelete}
                                    >
                                        {this.props.t("common.buttons.delete")}
                                    </button>
                                )}
                                <button
                                    onClick={toggleCarouselModal}
                                    className="px-4 bg-transparent p-3 rounded-lg text-blue-500 hover:bg-gray-100 hover:text-blue-400 mr-2"
                                >
                                    {this.props.t("common.buttons.close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CarouselModal);
