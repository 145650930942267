import { withTranslation } from "react-i18next";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { GridItem } from "./GridItem";
import EventForPlaylistGrid from "../utils/EventForPlaylistGrid";
import { getPropFromIDs } from "./Screen.functions";

class Sortable extends React.Component {
    constructor() {
        super();
        this.state = {
            pathsForEvents: [],
        };
    }
    componentDidMount = async () => {
        let events = this.props.screenEvents.map((e) => e.content[0]);
        if (events.length > 0) {
            let paths = await getPropFromIDs("path", events);
            this.setState({ pathsForEvents: paths.data });
        }
    };
    /**
     *When you change a place card, rearrange the order of the items
     * @param {*} param0
     */
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.props.handleOnSortEndGridView(
            arrayMoveImmutable(this.props.items, oldIndex, newIndex),
        );
    };

    /**
     * Return the contents and events in an array
     * @param {*} items
     * @param {*} events
     * @returns  items + events
     */
    getItems = (items, events) => {
        let eventos = [];
        events.forEach((event) => {
            if (!this.props.isEventInThePast(event)) {
                eventos.push(event);
            }
        });
        return items.concat(eventos);
    };
    render() {
        const SortableItem = SortableElement(({ item }) => (
            <div className="item">
                <div className="inner-item cursor-pointer pt-2 rounded w-52 border shadow-xl h-72 bg-gray-100 hover:bg-white">
                    {item.type === "content" ? (
                        <GridItem
                            contentMenuDetailThumbnail={
                                this.props.contentMenuDetailThumbnail
                            }
                            getIndexOfplaylist={this.props.getIndexOfplaylist}
                            countClonedContentWithID={
                                this.props.countClonedContentWithID
                            }
                            hasContentCloned={this.props.hasContentCloned}
                            contentHasBeenRepeated={
                                this.props.contentHasBeenRepeated
                            }
                            handleContentCloned={this.props.handleContentCloned}
                            contentMenuIsVisible={
                                this.props.contentMenuIsVisible
                            }
                            openOptionsOfDetailContent={
                                this.props.openOptionsOfDetailContent
                            }
                            formatNumber={this.formatNumber}
                            item={item}
                            screen={this.props.screen}
                            handleModalOfContent={
                                this.props.handleModalOfContent
                            }
                            t={this.props.t}
                            renderPlaylistContentSwitch={(index, content) =>
                                this.props.renderPlaylistContentSwitch(
                                    index,
                                    content,
                                )
                            }
                            openModal={this.props.openModal}
                            selectedContentId={this.props.selectedContentId}
                        ></GridItem>
                    ) : (
                        <EventForPlaylistGrid
                            path={this.state.pathsForEvents.find(
                                (e) => e.id === item.content[0],
                            )}
                            event={item}
                            updateOrder={this.updateOrder}
                            isReOrdening={this.state.isReOrdening}
                            screen={this.props.screen}
                            openModal={this.props.openModal}
                            contents={this.props.contents}
                            getEventContents={this.props.getEventContents}
                        />
                    )}
                </div>
            </div>
        ));
        const SortableList = SortableContainer(({ items }) => (
            <div className={`container flex gap-4 flex-wrap `}>
                {items.map((item, index) => (
                    <SortableItem
                        key={`${item._id}`}
                        index={index}
                        item={item}
                        disabled={item.type === "calendarEvent"}
                    />
                ))}
            </div>
        ));
        return (
            <>
                <SortableList
                    items={this.getItems(
                        this.props.items,
                        this.props.screenEvents,
                    )}
                    onSortEnd={this.onSortEnd}
                    axis="xy"
                    distance={1}
                    disableAutoscroll={true}
                />
            </>
        );
    }
}
export default withTranslation()(Sortable);
