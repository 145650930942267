import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
export const checkAdmin = () => {
    return instance_axios
        .get("/generic/checkAdmin", {
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};
export const validateMail = (mail) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
};
