import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

class ExtraButtonsMenu extends Component {
    constructor() {
        super();
        this.state = {
            isMenuVisible: false,
        };
        document.addEventListener("click", this.checkClickOutside);
    }
    toggleMenu() {
        this.setState({ isMenuVisible: !this.state.isMenuVisible });
    }
    /**
     * If the target of the click is not the menu, then hide the menu.
     * @param e - the event object
     */
    checkClickOutside = (e) => {
        let type;
        let targetID = "";
        if (
            !e.target ||
            e.target?.toString().includes("HTMLDocument") ||
            e.target.getAttribute("data-target") === null
        ) {
            if (
                e.target.parentNode?.toString().includes("HTMLDocument") ||
                e.target.parentNode?.getAttribute("data-target") === null
            ) {
                type = null;
            } else {
                type = e.target.parentNode?.getAttribute("data-type");
                targetID = e.target.parentNode?.getAttribute("data-target");
            }
        } else {
            type = e.target.getAttribute("data-type");
            targetID = e.target.getAttribute("data-target");
        }
        if (type === "extra-menu")
            this.setState({
                isMenuVisible:
                    this.state.isMenuVisible !== this.props?.id
                        ? targetID
                        : false,
            });
        else this.setState({ isMenuVisible: false });
    };
    render() {
        return (
            <div>
                <div className="absolute z-auto">
                    <div
                        data-toggle={this.props.id}
                        className={`absolute flex flex-col z-10 w-48 right-0 mt-6 p-2 px-6 bg-white rounded-md shadow-md ${this.state.isMenuVisible === this.props.id ? "" : "hidden"}`}
                    >
                        {this.props.buttons.map((button, index) => {
                            return (
                                <div
                                    key={index.toString()}
                                    className={`w-auto font-semibold text-sm content-center cursor-pointer p-1 rounded hover:bg-gray-100 ${button.view ? "" : "hidden"} ${button.class}`}
                                    onClick={() => button.function()}
                                >
                                    {button.icon}
                                    {button.title}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <FontAwesomeIcon
                    data-type="extra-menu"
                    data-toggle="toggleMenuIcon"
                    data-target={this.props.id}
                    icon={["fal", "cog"]}
                    size="1x"
                    className={` ${this.props.classIcon ? this.props.classIcon + " mr-2 cursor-pointer hover:animate-spin" : "text-blue-600 mr-2 cursor-pointer hover:animate-spin"}`}
                />
            </div>
        );
    }
}
export default ExtraButtonsMenu;
