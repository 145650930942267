import React, { Component } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class SystemNotifications extends Component {
    setDuration = () => {
        const notification = this.props.notification;

        if (notification.autoClose !== false) {
            let duration = 4000;

            if (notification.duration) duration = notification.duration;
            const timeOutId = setTimeout(() => {
                this.props.closeNotification();
                clearInterval(timeOutId);
            }, duration);
        }
    };

    render() {
        const notification = this.props.notification;

        if (notification.open) this.setDuration();

        const notificationType = notification.type;
        const title = notification.title;
        const text = notification.text;
        const text2 = notification.text2;
        const arrOfText = notification.arrOfText;
        const link = notification.link;
        const notificationVariants = {
            open: { opacity: 1, x: 0 },
            closed: { opacity: 0, x: "200%" },
        };

        let borderLeft = "";
        let icon = "";
        let titleColor = "";

        switch (notificationType) {
            case "success":
                borderLeft = "border-green-300";
                icon = "badge-check";
                titleColor = "text-green-500";
                break;
            case "warning":
                borderLeft = "border-yellow-300";
                icon = "exclamation-triangle";
                titleColor = "text-yellow-800";
                break;
            case "error":
                borderLeft = "border-red-300";
                icon = "exclamation-triangle";
                titleColor = "text-red-500";
                break;
            default:
                borderLeft = "border-gray-300 bg-gray-200";
                icon = "bells";
                titleColor = "text-gray-700";
                break;
        }

        return (
            <>
                <motion.div
                    className={`absolute z-50 w-11/12 md:max-w-md md:min-w-max ${borderLeft} border-l-4 bg-white backdrop shadow-lg rounded-lg overflow-hidden top-0 right-0 mr-2 mt-2 px-4`}
                    animate={notification.open ? "open" : "closed"}
                    variants={notificationVariants}
                >
                    <div
                        className="absolute top-0 right-0 mr-2"
                        onClick={() => this.props.closeNotification()}
                    >
                        <FontAwesomeIcon
                            icon={["fal", `times`]}
                            className="text-gray-400"
                        />
                    </div>
                    <div className="flex items-center px-1 py-3">
                        <FontAwesomeIcon
                            icon={["fad", `${icon}`]}
                            className={`${titleColor} opacity-50 text-2xl`}
                        />
                        <div className="mx-3">
                            <h2 className={`font-semibold ${titleColor}`}>
                                {title}
                            </h2>
                            <p className="text-xs text-gray-600">{text}</p>
                            {text2 && (
                                <p className="text-xs text-gray-600">{text2}</p>
                            )}
                            {link && (
                                <Link
                                    to={{
                                        pathname: "/management",
                                        state: {
                                            data: {
                                                isActiveSection: "programmatic",
                                            },
                                        },
                                    }}
                                >
                                    <p className="text-blue-600 underline">
                                        Link
                                    </p>
                                </Link>
                            )}
                            {arrOfText && (
                                <>
                                    {arrOfText.map((txt, i) => {
                                        return (
                                            <p
                                                key={i}
                                                className="text-xs text-gray-600"
                                            >
                                                {txt}
                                            </p>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </motion.div>
            </>
        );
    }
}

export default SystemNotifications;
