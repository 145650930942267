import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import Searchbar from "../utils/Searchbar";
class CalendarHeader extends Component {
    confirmDelete = () => {
        this.props.deleteContent();
        this.props.switchCheckedCheckbox();
        this.props.clearDeleteCheckboxes();
    };

    cancelDelete = () => {
        this.props.switchCheckedCheckbox();
        this.props.clearDeleteCheckboxes();
    };

    render() {
        return (
            <>
                <div
                    id="calendarHeader"
                    className="calendarHeader  overflow-hidden relative border-b-2 border-gray-200 pt-3  flex flex-row justify-between align-center"
                >
                    <div>
                        <span className=" planContentEvent text-center font-bolt align-middle">
                            {this.props.t("sections.calendar.header.title")}
                        </span>
                    </div>
                    <div className="d-flex flex-row justify-center  ">
                        <Searchbar
                            classNames="searchBar shadow appearance-none w-auto border rounded mb-4 py-2 mx-2 px-3 ml-0 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                            placeholder={this.props.t(
                                "sections.calendar.header.searchbar",
                            )}
                            disabled={false}
                            callFunction={this.props.callFunction}
                        />
                    </div>
                    <button
                        onClick={this.props.showScreenOptions}
                        className="btnOptionsScreen hidden"
                    >
                        <FontAwesomeIcon
                            icon={["fas", "cog"]}
                            fixedWidth
                            size="1x"
                            className="btnOptionsScreen hidden text-gray-400 hover:text-red-800"
                        />
                    </button>
                </div>
            </>
        );
    }
}

export default withTranslation()(CalendarHeader);
