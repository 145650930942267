import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { addColumns, getColumn } from "./BoardFunctions";
import { isEqual } from "lodash";
import { editColumn } from "./BoardFunctions";

class modalAddColumn extends Component {
    constructor() {
        super();
        this.state = {
            color: "#000000",
            text: "",
            columnToEdit: null,
        };
        this.timer = null;
    }

    componentDidUpdate = () => {
        if (!isEqual(this.state.columnToEdit, this.props.columnToEdit)) {
            if (this.props.columnToEdit !== null) {
                this.setState({
                    columnToEdit: this.props.columnToEdit,
                    text: this.props.columnToEdit.title,
                    color: this.props.columnToEdit.titleStyle.border.color,
                });
            } else {
                this.setState({ columnToEdit: null });
            }
        }
    };
    /**
     * Close the modal whit the id pass per parameter
     */
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };
    /**
     * execute the handleSubmit function and close the modal
     */
    callFunctionAndClose = () => {
        this.handleSubmit();
        this.closeModal();
    };
    /**
     * Close the modal and reset the atributes of a column
     */
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
        this.setState({ text: "", color: "#000000" });
        this.props.resetEditColumn();
    };
    /**
     * When change color of the imput in the modal set the new color in the state
     * @param {*} e the param that contain the color
     */
    onChangeInputColor = (e) => {
        let color = e.target.value;
        this.setState({ color });
    };

    /**
     * When change the name in the imput set the value in the state
     * @param {*} e he param that contain the text
     */
    changeInput = (e) => {
        let value = e.target.value;
        this.setState({ text: value });
    };
    /**
     * Create an object of type column and return it
     * @returns object type column
     */
    createObjColumn = () => {
        return {
            title: this.state.text,
            order: this.props.lanesLength + 1,
            currentPage: 1,
            type: "column",
            titleStyle: {
                border: {
                    color: this.state.color,
                },
            },
            style: {
                backgroundColor: "rgb(243 244 246)",
                boxShadow: "3px 3px 4px 0px rgba(0,0,0,0.25)",
            },
            cards: [],
            priority: false,
        };
    };
    /**
     * create a object type colummn and upload to DB, if status is equal to 200 then add to the board
     */
    handleSubmit = async () => {
        if (this.columnsHasTitle()) {
            let column = this.createObjColumn();
            try {
                let response = await addColumns(column);
                if (response.status === 201) {
                    let responseNewColumn = await getColumn(response.data.id);
                    if (responseNewColumn.status === 200) {
                        responseNewColumn = responseNewColumn.data.docs[0];
                        responseNewColumn.id = responseNewColumn._id;
                        responseNewColumn.rev = responseNewColumn._rev;
                        // this.props.onAdd(responseNewColumn);
                        this.props.newLaneCreated(this.props.lanesLength + 1);
                        this.props.addColumnToBoard(responseNewColumn);
                    }
                }
            } catch (error) {
                console.log(error);
                this.props.showNotification({
                    type: "warning",
                    text: this.props.t(
                        "common.notification.status.errorToCreateColumn",
                    ),
                });
            }
        } else {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.completeFields"),
            });
        }
    };
    /**
     * return a boolean that say if the title of the imput modal is empty
     * @returns a boolean
     */
    columnsHasTitle = () => {
        let value = this.state.text;
        if (value !== "") {
            return true;
        }
        return false;
    };
    /**
     *Create a new object type column and replace for an existing column
     */
    editColumn = async () => {
        if (this.columnsHasTitle()) {
            let column = this.createObjColumn();
            column.order = this.state.columnToEdit.order;
            column._id = this.props.columnToEdit._id;
            column.id = this.props.columnToEdit._id;
            column._rev = this.props.columnToEdit._rev;
            try {
                let response = await editColumn(column);
                if (response.status === 200) {
                    column._rev = response.data.rev;
                    this.props.replaceColumnAtributes(column);
                    this.closeModal();
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.completeFields"),
            });
        }
    };

    render() {
        const { modalID } = this.props;
        return (
            <div
                id={modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent modal-sm bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className="flex">
                        <div className="uppercase tracking-wide text-gray-600 font-bold  text-sm mt-2 ml-2  ">
                            {this.props.t("sections.status.column")}:
                        </div>
                    </div>

                    <div className="modal-header relative font-extralight text-lg flex  justify-between mt-1">
                        <input
                            type="text"
                            name="title"
                            value={this.state.text}
                            onChange={this.changeInput}
                            className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-4 ml-2 leading-tight focus:outline-none focus:bg-white"
                        ></input>
                        <input
                            type="color"
                            onChange={this.onChangeInputColor}
                            value={this.state.color}
                            className="h-10 ml-2 mr-2 rounded"
                        ></input>
                    </div>

                    <div className=" flex  justify-between  mt-2">
                        <button
                            onClick={this.closeModal}
                            className="buttonSecondary ml-2"
                        >
                            {this.props.t("common.buttons.cancel")}
                        </button>
                        {this.state.columnToEdit !== null ? (
                            <button
                                className="buttonPrimary"
                                onClick={() => {
                                    this.editColumn();
                                }}
                            >
                                {this.props.t("sections.status.editColumn")}
                            </button>
                        ) : (
                            <button
                                onClick={this.callFunctionAndClose}
                                className="buttonPrimary"
                            >
                                {this.props.t("common.buttons.accept")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(modalAddColumn);
