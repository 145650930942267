import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const instance_axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

export const getBrands = () => {
    return instance_axios
        .get("/generic/brand", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err);
};

export const getCustomers = () => {
    return instance_axios
        .get("/generic/customer", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err);
};

export const getScreenGroups = () => {
    return instance_axios
        .get("/generic/screenGroup", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((screenGroups) => screenGroups)
        .catch((err) => err);
};

export const getUser = () => {
    return instance_axios
        .get("/generic/user", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((user) => user)
        .catch((err) => err);
};
