import React, { Component } from "react";
import isEqual from "lodash.isequal";
import ModalLayoutWOButtons from "../common/ModalLayoutWOButtons";
import CameraDisplay from "./CameraDisplay";
import { isTunnelUp } from "../../screens/Screen.functions";

export default class CameraViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cameras: [],
            isTunnelUp: false,
        };
        this.intervalTunnelUp = 0;
    }

    componentDidMount = async () => {
        try {
            if (this.props.cameras?.length > 0) {
                this.openTunnelInterval();
            }
        } catch (error) {
            console.log("RESPONSE ERROR", error);
        }
    };

    openTunnelInterval = () => {
        clearInterval(this.intervalTunnelUp);
        this.intervalTunnelUp = setInterval(async () => {
            try {
                console.log("openTunnelInterval");
                if (this.props.cameras.length > 0) {
                    let responseTunnel = await isTunnelUp(
                        `${this.props.cameras[0]?.currentURL}/screen/info`,
                    );
                    if (responseTunnel.data.status === 200) {
                        this.setState({ isTunnelUp: true }, () => {
                            clearInterval(this.intervalTunnelUp);
                        });
                    }
                }
            } catch (error) {
                console.log("error: ", error);
            }
        }, 1000);
    };

    componentDidUpdate = async (prevProps) => {
        if (
            this.props.cameras &&
            !isEqual(prevProps.cameras, this.props.cameras)
        ) {
            this.setState(
                {
                    cameras: this.props.cameras,
                },
                () => {
                    this.openTunnelInterval();
                },
            );
        }
    };

    closeCameraModal = () => {
        clearInterval(this.intervalTunnelUp);
        this.props.onCloseViewModal();
    };

    render() {
        const { modalID, screenName } = this.props;
        const { cameras } = this.state;
        return (
            <ModalLayoutWOButtons
                title={screenName}
                modalID={modalID}
                onclose={this.closeCameraModal}
            >
                <div className="flex flex-col ml-3 h-full overflow-y-scroll snap-container">
                    {this.state.isTunnelUp ? (
                        <>
                            {cameras.length > 0 &&
                                cameras.map((camera) => (
                                    <CameraDisplay
                                        key={`cameraDisplay-${camera._id}`}
                                        camera={camera}
                                        needLink={true}
                                        needScreenshot={true}
                                    />
                                ))}
                        </>
                    ) : (
                        <>
                            {cameras.length > 0 && (
                                <CameraDisplay
                                    key={`cameraDisplay-${cameras[0]._id}`}
                                    camera={cameras[0]}
                                    needLink={true}
                                    needScreenshot={true}
                                />
                            )}
                        </>
                    )}
                </div>
            </ModalLayoutWOButtons>
        );
    }
}
