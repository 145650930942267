import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import Title from "../common/Title";
import "../../assets/styles.css";
import ExpandedCertificationRow from "./utils/ExpandedCertificationRow";
class CertificationsLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
        };
    }
    /**
     * Array of columns to show in table
     */
    screenList = [
        {
            name: this.props.t(
                "sections.management.section.cameras.table.name",
            ),
            selector: (row) => row.name,
            sortable: true,
        },
    ];
    /**
     * Inserts the cameras in the corresponding screen
     * @param {Array} screens
     * @param {Array} cameras
     * @param {Array} screens
     * @param {Array} cameras
     * @returns {Array} screens
     */
    getParsedScreensAndCamera = (screens, cameras) => {
        screens.forEach((screen) => {
            screen.cameras = [];
            screen.cameras = cameras.filter((camera) =>
                camera.screen?.includes(screen._id),
            );
        });
        return screens;
    };

    render() {
        const { screens, cameras } = this.props;
        const cameraModels = [
            { value: 1, label: "Hikvision" },
            { value: 2, label: "Gadnic" },
            { value: 3, label: "Other" },
        ];
        const expandedRowComponent = ({ data }) => {
            return data.cameras.length > 0 ? (
                <ExpandedCertificationRow
                    data={data}
                    cameraModels={cameraModels}
                    handleEditCameraConfig={this.props.handleEditCameraConfig}
                    openLive={(camera, screenID) =>
                        this.props.openLive(camera, screenID)
                    }
                />
            ) : (
                <p className="w-full border border-gray-300 rounded-b my-auto p-2 text-center">
                    {this.props.t(
                        "sections.management.section.cameras.noCamera",
                    )}
                </p>
            );
        };
        return (
            <div className="max-h-5/6">
                <div className="flex content-center my-4">
                    <Title>
                        <FontAwesomeIcon
                            icon={["fal", "cctv"]}
                            fixedWidth
                            className="text-blue-500"
                        />{" "}
                        {this.props.t(
                            "sections.management.section.cameras.title",
                        )}
                        <p className="font-dark">
                            {this.props.t(
                                "sections.management.section.cameras.beta",
                            )}
                        </p>
                        <p className="font-light">
                            {this.props.t(
                                "sections.management.section.cameras.camerasTxt",
                            )}
                        </p>
                    </Title>
                </div>
                <div className="divide-y-2 divide-solid flex flex-col justify-between">
                    <div
                        id="managementCertificationTable"
                        className="w-full d-flex"
                    >
                        <button
                            className=" group absolute right-10 mt-2 buttonNew ml-auto"
                            onClick={(e) =>
                                this.props.handleEditCameraConfig("create")
                            }
                        >
                            <FontAwesomeIcon
                                icon={["fas", "plus"]}
                                className="text-white"
                            />
                            <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light">
                                {this.props.t("common.buttons.add")}{" "}
                                {this.props.t(
                                    "sections.management.section.cameras.tab",
                                )}
                            </span>
                        </button>
                        <DataTableExtensions
                            data={this.getParsedScreensAndCamera(
                                screens,
                                cameras,
                            )}
                            columns={this.screenList}
                            filterPlaceholder={this.props.t(
                                "sections.content.contentList.search",
                            )}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                className="w-full"
                                responsive={true}
                                dense={true}
                                keyField="_id"
                                expandableRows
                                expandableRowsComponent={expandedRowComponent}
                                noDataComponent={false}
                                noHeader={true}
                                pagination
                                noDataComponent={this.props.t(
                                    "table.no-records",
                                )}
                            />
                        </DataTableExtensions>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(CertificationsLayout);
