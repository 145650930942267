import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBrands, getCustomers } from "../utils/CommonRequests";
import { Link } from "react-router-dom";
class DetailContentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            brands: [],
            customers: [],
        };
    }
    componentDidMount = async () => {
        this.getBrands();
        this.getCustomers();
    };
    getBrands = async () => {
        try {
            const response = await getBrands();
            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
                return;
            }
            this.setState({ brands: response.data });
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };
    getCustomers = async () => {
        try {
            const response = await getCustomers();
            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
                return;
            }
            this.setState({ customers: response.data });
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };
    getCustomersOptions = (customers) =>
        customers.map((customer) => {
            return {
                label: customer.contact_name + "-" + customer.company,
                value: customer._id,
            };
        });
    /**
     * hide the modal with the id that the props have, if the params contents exist also clean the checkboxes
     * @param {*} content
     */
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };
    getBrandsOptions = (brands) =>
        brands.map((brand) => {
            return { label: brand.name, value: brand._id };
        });
    /**
     *  Create an array of objects with the @name and @_id from the screen and return it to the select
     * @param {*} screens [array]
     * @returns options[ {label: screen01, value: 111111}]
     */
    getOptionScreens = (screens = []) => {
        let options = [];
        screens.forEach((e) => {
            let obj = {
                label: e.name,
                value: e._id,
            };
            options.push(obj);
        });
        return options;
    };

    render() {
        const imageExtensions = ["png", "jpg", "jpeg", "gif"];
        const videoExtensions = ["mp4", "ogg", "webm"];
        const htmlExtensions = ["zip", "html"];
        return (
            <>
                {this.props.content && (
                    <div
                        id={this.props.modalID}
                        className="modal fixed z-40 w-screen h-screen  overflow-y-auto top-0 left-0 flex  hidden"
                    >
                        <div
                            className={`w-auto m-auto pb-2 h-6/7 bg-white rounded-md max-w-md `}
                        >
                            <div className="flex justify-between w-full button ">
                                <p className=" ml-6 mt-4 text-center text-gray-700 font-bold truncate">
                                    {this.props.content.name}
                                </p>
                                <span
                                    className="right-0 mr-3 mt-3 cursor-pointer"
                                    onClick={() => this.closeModal(false)}
                                >
                                    <FontAwesomeIcon icon={["fal", "times"]} />
                                </span>
                            </div>

                            <div className="flex justify-center ">
                                <div className="w-full mx-6">
                                    <div className="w-96 h-auto  justify-center">
                                        <div className="rounded">
                                            <div className="bg-white  ">
                                                <div className=" flex justify-center">
                                                    {imageExtensions.includes(
                                                        this.props.content.ext,
                                                    ) && (
                                                        <>
                                                            {" "}
                                                            <img
                                                                data-tip
                                                                data-for={
                                                                    "tooltip" +
                                                                    this.props
                                                                        .content
                                                                        ._id
                                                                }
                                                                className="object-fill max-h-72"
                                                                src={`${process.env.REACT_APP_FILESERVER_URL}/${this.props.content.path}`}
                                                                alt={
                                                                    this.props
                                                                        .content
                                                                        .name
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    {videoExtensions.includes(
                                                        this.props.content.ext,
                                                    ) && (
                                                        <>
                                                            <video
                                                                controls
                                                                muted
                                                                data-tip
                                                                data-for={
                                                                    "tooltip" +
                                                                    this.props
                                                                        .content
                                                                        ._id
                                                                }
                                                                className="object-fill max-h-72"
                                                                src={`${process.env.REACT_APP_FILESERVER_URL}/${this.props.content.path}`}
                                                                alt={
                                                                    this.props
                                                                        .content
                                                                        .name
                                                                }
                                                            ></video>
                                                        </>
                                                    )}
                                                    {htmlExtensions.includes(
                                                        this.props.content.ext,
                                                    ) && (
                                                        <>
                                                            <img
                                                                data-tip
                                                                data-for={
                                                                    "tooltip" +
                                                                    this.props
                                                                        .content
                                                                        ._id
                                                                }
                                                                src={require("../../images/common/html.png")}
                                                                alt={
                                                                    this.props
                                                                        .content
                                                                        .name
                                                                }
                                                            ></img>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <span className="text-gray-700 font-bold mb-2">
                                                    {this.props.t(
                                                        "sections.content.associatedScreens",
                                                    )}
                                                    :
                                                </span>
                                                <div
                                                    className={
                                                        "max-h-16  w-full overflow-y-auto border border-gray-200 rounded mr-1 flex flex-wrap"
                                                    }
                                                >
                                                    {this.getOptionScreens(
                                                        this.props.screens.filter(
                                                            (screen) =>
                                                                this.props.content?.screen_id.includes(
                                                                    screen._id,
                                                                ),
                                                        ),
                                                    ).map((s) => {
                                                        return (
                                                            <div className="px-1 py-0.5 m-0.5 bg-gray-200 rounded">
                                                                {s.label}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-gray-700 font-bold mb-2">
                                                    {this.props.t(
                                                        "sections.content.brand",
                                                    )}
                                                    :
                                                </span>
                                                <div
                                                    className={
                                                        "max-h-16 custom-min-h-xs  w-full border border-gray-200 rounded mr-1 flex flex-wrap"
                                                    }
                                                >
                                                    {this.getBrandsOptions(
                                                        this.state.brands.filter(
                                                            (brand) =>
                                                                brand._id ===
                                                                this.props
                                                                    .content
                                                                    ?.brand,
                                                        ),
                                                    ).map((b) => {
                                                        return (
                                                            <div className="px-1 py-0.5 m-0.5 bg-gray-200 rounded">
                                                                {b.label}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-gray-700 font-bold mb-2">
                                                    {this.props.t(
                                                        "sections.content.customer",
                                                    )}
                                                    :
                                                </span>
                                                <div
                                                    className={
                                                        "max-h-16 custom-min-h-xs  w-full border border-gray-200 rounded mr-1 flex flex-wrap"
                                                    }
                                                >
                                                    {this.getCustomersOptions(
                                                        this.state.customers.filter(
                                                            (customer) =>
                                                                customer._id ===
                                                                this.props
                                                                    .content
                                                                    ?.customer,
                                                        ),
                                                    ).map((c) => {
                                                        return (
                                                            <div className="px-1 py-0.5 m-0.5 bg-gray-200 rounded">
                                                                {c.label}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-between w-full">
                                                    <div>
                                                        <p className="text-gray-700 font-bold ">
                                                            {this.props.t(
                                                                "sections.content.calendarFrom",
                                                            )}
                                                        </p>
                                                        <input
                                                            id="startDateContentDetail"
                                                            type="date"
                                                            disabled="true"
                                                            value={
                                                                this.props
                                                                    .content
                                                                    .schedule
                                                                    .startDate
                                                            }
                                                            className="  block shadow appearance-none border border-gray-300  rounded py-2 mx-auto  text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                        ></input>
                                                    </div>
                                                    {this.props.content.schedule
                                                        .endDate !== "" && (
                                                        <div>
                                                            <p className=" text-gray-700 font-bold ">
                                                                {this.props.t(
                                                                    "sections.content.calendarUntil",
                                                                )}
                                                            </p>
                                                            <input
                                                                id="endDateContentDetail"
                                                                type="date"
                                                                disabled="true"
                                                                value={
                                                                    this.props
                                                                        .content
                                                                        .schedule
                                                                        .endDate
                                                                }
                                                                className="w-26  block shadow appearance-none border border-gray-300  rounded    text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                            ></input>
                                                        </div>
                                                    )}
                                                </div>
                                                {this.props.content
                                                    .daily_limit && (
                                                    <div className="block">
                                                        <span className="text-gray-700 font-bold ">
                                                            {this.props.t(
                                                                "sections.content.Dailyimpressionlimit",
                                                            )}
                                                            :
                                                        </span>
                                                        <input
                                                            disabled="true"
                                                            type="number"
                                                            value={
                                                                this.props
                                                                    .content
                                                                    .daily_limit
                                                            }
                                                            className=" w-24 block shadow appearance-none border border-gray-300 rounded py-2  text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                        ></input>
                                                    </div>
                                                )}
                                                <div className="">
                                                    <div className="block ">
                                                        <span className="text-gray-700 font-bold mb-2">
                                                            {this.props.t(
                                                                "sections.content.contentStartingHour",
                                                            )}
                                                            :
                                                        </span>
                                                        <input
                                                            id="timeContentDetail"
                                                            disabled="true"
                                                            type="time"
                                                            value={
                                                                this.props
                                                                    .content
                                                                    .schedule
                                                                    .time
                                                            }
                                                            className="w-auto block shadow appearance-none border border-gray-300 rounded  text-gray-700 leading-tight focus:outline-none focus:focus:ring "
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-5.5/6 mx-5 flex justify-end">
                                <Link
                                    to={{
                                        pathname: "/content",
                                        state: {
                                            data: {
                                                contentBeingEdited:
                                                    this.props.content._id,
                                            },
                                        },
                                    }}
                                >
                                    <button className="buttonPrimary ">
                                        {this.props.t("common.buttons.edit")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withTranslation()(DetailContentModal);
