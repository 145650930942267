import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const instance_axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
export const getScreen = async (screen_id) => {
    try {
        const r = await instance_axios.get(`/generic/screen/${screen_id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const syncScreen = async (code, screen_id) => {
    const data = { code, screen_id };

    try {
        const r = await instance_axios.post("/screen/sync", data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const updateContentOrder = async (updatedContent) => {
    try {
        const r = await instance_axios.post("/content/order", updatedContent, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const repeatContent = async (repeatedContent, screen_id) => {
    try {
        const r = await instance_axios.post(
            "/content/repeated",
            { repeatedContent, screen_id },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
                withCredentials: true,
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};

export const unpairScreen = async (id) => {
    try {
        const r = await instance_axios.get("/screen/unpair/" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const getContents = async (screen_id) => {
    try {
        const res = await instance_axios.get(`/screen/${screen_id}/content`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return res;
    } catch (err) {
        return err;
    }
};
export const updateContent = async (newDoc) => {
    try {
        const res = await instance_axios.put("/generic/", newDoc, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return res;
    } catch (err) {
        return err;
    }
};
export const removeScreenIDFromAllContent = async (contentIDs, screenID) => {
    try {
        const res = await instance_axios.post(
            "/screen/playlist/removeallitems",
            {
                contentIDs,
                screenID,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
                withCredentials: true,
            },
        );
        return res;
    } catch (err) {
        return err;
    }
};
export const removeScreen = async (screen_id) => {
    try {
        const res = await instance_axios.post(`/screen/${screen_id}/remove`);
        return res;
    } catch (err) {
        return err;
    }
};
export const removeScreenImages = async (images) => {
    try {
        const res = await instance_axios.delete(`/storage`, { keys: images });
        return res;
    } catch (err) {
        console.log("removeScreenImages error", err);
        return err;
    }
};

export const deleteRepeatedContent = async (ids, screen_id) => {
    if (!Array.isArray(ids)) ids = [ids];

    const data = { content_ids: ids, screen_id };
    try {
        const res = await instance_axios.post(`/content/deleterepeated`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
            withCredentials: true,
        });
        return res;
    } catch (err) {
        console.log("removeScreenImages error", err);
        return err;
    }
};

export const recoverDeleted = async () => {
    try {
        const r = await instance_axios.get("/screen/getdeleted");
        return r;
    } catch (err) {
        return err;
    }
};
export const setSSPID = async (screen_id, SSPID) => {
    try {
        const r = await instance_axios.put(
            `/screen/${screen_id}/sspid`,
            { sspid: SSPID },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        console.log(err.request);
        return err.request;
    }
}; /* A string literal. */

export const disableContent = async (id, disableContent) => {
    try {
        const r = await instance_axios.post(
            "/content/disablecontent/" + id,
            { disableContent },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};
export const updateManyContent = async (docs) => {
    try {
        const r = await instance_axios.put(
            "/content/updatemany",
            { docs },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};
export const updateOrder = async (docs) => {
    try {
        const r = await instance_axios.put(
            "/content/updatemany",
            { docs, order: true },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const deLinkContent = async (data) => {
    try {
        const r = await instance_axios.post(
            "/content/removefromplaylist",
            data,
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.response;
    }
};
export const checkLimitExceeded = async (screens_id) => {
    try {
        const r = await instance_axios.post("/content/checklimit", {
            screens_id,
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const getEvents = async () => {
    try {
        const r = await instance_axios.get("/generic/calendarEvent", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const unLinkAllExceptThis = async (data) => {
    try {
        const r = await instance_axios.post("/content/unlinkexceptone", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const enableAllContent = async (data) => {
    try {
        const r = await instance_axios.post("/content/enableall", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};
export const toggleContentCloned = async (data) => {
    try {
        const r = await instance_axios.post(
            "/content/toggleContentCloned",
            data,
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const getNotAssignedContent = async (screen_id) => {
    try {
        const r = await instance_axios.get(
            "/content/getNotAssignedContent/" + screen_id,
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const getScreensByPage = async (page = 1, limit = 10, name = "") => {
    try {
        const r = await instance_axios.post(
            `/screen/bypage`,
            {
                page,
                limit,
                name,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
                withCredentials: true,
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};
export const deleteScreensById = async (screens_ids) => {
    try {
        const r = await instance_axios.post(
            "/screen/removeallbyid",
            screens_ids,
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
export const getContentFromID = async (id) => {
    try {
        const r = await instance_axios.get("/content/getbyid/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (error) {
        return error;
    }
};
export const getCamerasByScreen = async (id) => {
    try {
        const r = await instance_axios.get("/camera/screen/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (error) {
        return error;
    }
};

export const getNameOfScreen = async (id) => {
    try {
        const r = await instance_axios.get("/screen/name/" + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const getPropFromIDs = async (prop, ids) => {
    try {
        const r = await instance_axios.post(
            "/generic/getpropfromids/" + prop,
            { ids },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};

export const isTunnelUp = async (tunnelURL) => {
    try {
        const r = await axios.get(tunnelURL);
        return {
            data: {
                status: r.status,
            },
        };
    } catch (err) {
        if (err.request.status === 0) {
            try {
                let response = await instance_axios.post(
                    "/camera/checktunnel",
                    {
                        tunnelURL,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                        },
                    },
                );
                return response;
            } catch (error) {
                return {
                    data: {
                        status: err.request.status,
                    },
                };
            }
        } else {
            return {
                data: {
                    status: err.request.status,
                },
            };
        }
    }
};
export const suscribeToChangesInCameras = () => {
    try {
        let responseCameraSSE = new EventSource(
            `${process.env.REACT_APP_BACKEND_URL}/camera/listenchanges`,
        );
        return responseCameraSSE;
    } catch (error) {
        console.log(error);
        return error;
    }
};
