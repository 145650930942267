import React from "react";
import PropTypes from "prop-types";
import InlineInput from "react-trello/dist/widgets/InlineInput";
import { Title, LaneHeader, RightContent } from "react-trello/dist/styles/Base";
import LaneMenu from "./LaneMenu";

const LaneHeaderComponent = (props) => {
    let {
        updateTitle,
        canAddLanes,
        onDelete,
        editLaneTitle,
        label,
        title,
        titleStyle,
        labelStyle,
        t,
        laneDraggable,
        openModal,
        onDoubleClick,
        _id,
    } = props;
    return (
        <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
            <div
                className="w-full absolute left-0 top-0"
                style={{
                    borderBottom: "2px solid " + titleStyle.border.color,
                    padding: "0",
                }}
            ></div>
            <Title
                className="font-bold w-full flex h-10 overflow-hidden"
                draggable={laneDraggable}
            >
                {editLaneTitle ? (
                    <InlineInput
                        value={title}
                        border
                        placeholder={t("placeholder.title")}
                        resize="vertical"
                        onSave={updateTitle}
                    />
                ) : (
                    title
                )}
            </Title>
            {label && (
                <RightContent>
                    <span style={labelStyle}>{label}</span>
                </RightContent>
            )}

            {canAddLanes && (
                <LaneMenu
                    t={t}
                    onDelete={onDelete}
                    traslate={props.traslate}
                    setLaneId={props.setLaneId}
                    laneId={_id}
                    onLaneClick={props.onLaneClick}
                    openModal={openModal}
                    setEditColumn={props.setEditColumn}
                ></LaneMenu>
            )}
        </LaneHeader>
    );
};

LaneHeaderComponent.propTypes = {
    updateTitle: PropTypes.func,
    editLaneTitle: PropTypes.bool,
    canAddLanes: PropTypes.bool,
    laneDraggable: PropTypes.bool,
    label: PropTypes.string,
    title: PropTypes.string,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    t: PropTypes.func.isRequired,
};

LaneHeaderComponent.defaultProps = {
    updateTitle: () => {},
    editLaneTitle: false,
    canAddLanes: false,
};

export default LaneHeaderComponent;
