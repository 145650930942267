import React, { Component } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";

//Components
import UserConsole from "./components/userconsole/UserConsole";
import { ContainerMenu } from "./components/menu/ContainerMenu";
import FileDropper from "./components/dropper/FileDropper";
import Wizard from "./components/wizard/Wizard";
import SystemNotification from "./components/notifications/SystemNotifications";
import { withTranslation } from "react-i18next";

// Sections
import ScreensSection from "./components/screens/Screens";
import Content from "./components/content/Content";
import Reports from "./components/reports/Reports";
import Management from "./components/management/Management";
import Calendar from "./components/calendar/Calendar";
import ResetPassword from "./components/Log_Reg/ResetPassword";
import Login from "./components/Log_Reg/Login";
import Register from "./components/Log_Reg/Register";
import ForgotPassword from "./components/Log_Reg/ForgotPassword";
import { PrivateRoute } from "./components/privateroute/PrivateRoute";
import {
    logOut,
    isLogin,
    remainingSecondsToExpireSession,
    isPublic,
} from "./components/Log_Reg/UserFunctions";
import { FileDrop } from "react-file-drop";
import { PublicRoute } from "./components/privateroute/PublicRoute";
import { UnloggedRoute } from "./components/privateroute/UnloggedRoute";
import Loading from "./components/loading/Loading";
import ConfirmAccount from "./components/Log_Reg/ConfirmAccount";
import BulkImportSection from "./components/wizard/bulk_import/BulkImportSection";
import SharedCameraView from "./components/public/SharedCameraView";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//  import AdminSection from './components/adminSection/AdminSection';
// Cargo Files en memoria para importar
class App extends Component {
    constructor() {
        super();
        this.state = {
            isLoggedIn: false,
            isShared: false,
            isDropping: false,
            notification: {
                open: false,
            },
            isLoading: false,
            limitSize: 50,
            adBlockActive: false,
            isHelpOpen: false,
            isProfileOpen: false,
        };
        this.cookieExpiresTimeOut = 0;
    }
    /**Maintains only one modal open in section menu */
    handleModalsOpen = (nameModal, callback) => {
        let modals = [
            { name: "profile", state: this.state.isProfileOpen },
            { name: "help", state: this.state.isHelpOpen },
        ];
        let nameAux = nameModal;
        if (nameAux !== null) {
            let newModalSwicht = modals.map((modal) => {
                if (modal.name === nameAux) {
                    modal.state = true;
                } else {
                    modal.state = false;
                }
                return modal;
            });
            modals = newModalSwicht;
            this.setState(
                { isProfileOpen: modals[0].state, isHelpOpen: modals[1].state },
                callback,
            );
        }
    };

    componentDidMount() {
        this.setState({
            isLoggedIn: isLogin(),
            isPublic: isPublic(),
        });
        this.checkSessionExpires();
        this.isAdBlockActive()
            .then((active) => this.setState({ adBlockActive: active }))
            .catch((err) => console.log(err));
    }

    componentDidUpdate = () => {
        if (
            this.state.adBlockActive &&
            this.state.isLoggedIn &&
            !localStorage.getItem("adBlockNotificationShowed")
        ) {
            this.handleNotification({
                open: true,
                text: this.props.t("common.notification.disableAdblock"),
                type: "warning",
                duration: 10000,
            });
            localStorage.setItem("adBlockNotificationShowed", true);
        }
    };

    /**  A function that toggles the menu of help. */
    toggleHelp = () => {
        this.setState({ isHelpOpen: !this.state.isHelpOpen });
    };
    checkSessionExpires = async () => {
        if (isLogin() && this.cookieExpiresTimeOut === 0) {
            const timeToExpire = await remainingSecondsToExpireSession();
            if (timeToExpire > 0)
                this.cookieExpiresTimeOut = setTimeout(
                    this.logOut,
                    timeToExpire * 1000,
                );
            else if (timeToExpire === false) {
                this.logOut();
                useHistory.push("/login");
            }
        }
    };

    setLogin = () => {
        if (localStorage.getItem("user") === null) {
            this.checkSessionExpires();
        }
        this.setState({ isLoggedIn: true });
    };

    /**
     * Change the value of isPublic variable in state
     */
    setIsShared = () => {
        this.setState({
            isPublic: isPublic(),
        });
    };
    logOut = () => {
        logOut()
            .then((r) => {
                if (r.status === 200) {
                    if (r.data?.message === "QA") {
                        localStorage.removeItem("user");
                        console.log("user Cleared");
                    } else {
                        clearTimeout(this.cookieExpiresTimeOut);
                        this.cookieExpiresTimeOut = 0;
                    }
                    this.setState({
                        isLoggedIn: false,
                        isHelpOpen: false,
                        isProfileOpen: false,
                    });
                } else {
                    this.handleNotification({
                        type: "error",
                        text: this.props.t("common.notification.serverError"),
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.handleNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
            });
    };

    isAdBlockActive = () => {
        return fetch(
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
            {
                method: "HEAD",
                mode: "no-cors",
            },
        )
            .then(() => false)
            .catch(() => true);
    };

    dragOver = (e) => {
        if (e.dataTransfer.items[0].kind === "file") {
            let body = document.querySelector("#bodyContainer");
            body.classList.add("border-2", "border-black", "opacity-80");
            document.querySelector("#dragAdviser").classList.remove("hidden");
        }
    };
    dragLeave = () => {
        let body = document.querySelector("#bodyContainer");
        body.classList.remove("border-4", "border-black", "opacity-80");
        document.querySelector("#dragAdviser").classList.add("hidden");
    };
    handleDropFiles = async (files) => {
        if (this.state.isLoggedIn) {
            const arrayFiles = [];
            Object.entries(files).forEach((file) => {
                if (file[1].size / 1024 / 1024 < this.state.limitSize) {
                    arrayFiles.push(file[1]);
                } else {
                    this.handleNotification({
                        type: "warning",
                        text:
                            file[1].name +
                            this.props.t(
                                "common.notification.fileDropper.limitSize",
                            ) +
                            this.state.limitSize +
                            this.props.t(
                                "common.notification.fileDropper.consideration",
                            ),
                    });
                }
            });
            let body = document.querySelector("#bodyContainer");
            body.classList.remove("border-4", "border-black", "opacity-80");
            document.querySelector("#dragAdviser").classList.add("hidden");
            this.setState({ filesDropped: arrayFiles });
            this.setState({ isDropping: true });
        }
    };

    handleNotification = (obj) => {
        obj.open = "true";
        this.setState({ notification: obj });
    };

    handleLoading = (boolean) => {
        this.setState({ isLoading: boolean });
    };

    closeNotification = () => {
        this.setState({ notification: { open: false } });
    };

    openModal = (id) => {
        document.getElementById(id).classList.remove("hidden");
        document.getElementById(id).classList.add("modalOpen");
    };

    setFiles = (files) => {
        this.setState({ filesDropped: files });
    };

    setFiles = (files) => {
        this.setState({ filesDropped: files });
    }

    render() {
        return (
            <BrowserRouter basename="./">
                {this.state.isDropping && this.state.isLoggedIn && (
                    <FileDropper
                        setLoading={this.handleLoading}
                        showNotification={this.handleNotification}
                        files={this.state.filesDropped}
                        closeModal={() => this.setState({ isDropping: false })}
                        setFiles={this.setFiles}
                    />
                )}
                {this.state.isLoading && !this.state.isShared && <Loading />}
                <FileDrop
                    className="absolute overflow-x-hidden top-0 left-0 h-full w-full"
                    onDrop={this.handleDropFiles}
                >
                    <SystemNotification
                        closeNotification={this.closeNotification}
                        handleNotification={this.handleNotification}
                        notification={this.state.notification}
                    />
                    <div className="fixed w-full h-screen flex bg-gray-100">
                        {/* Container Menu */}
                        {this.state.isLoggedIn && !this.state.isPublic && (
                            <ContainerMenu
                                isProfileOpen={this.state.isProfileOpen}
                                handleModalsOpen={this.handleModalsOpen}
                                toggleHelp={this.toggleHelp}
                                logOut={this.logOut}
                            />
                        )}
                        {this.state.isLoggedIn && !this.state.isPublic && (
                            <UserConsole
                                isProfileOpen={this.state.isProfileOpen}
                                handleModalsOpen={this.handleModalsOpen}
                                toggleHelp={this.toggleHelp}
                                isHelpOpen={this.state.isHelpOpen}
                                openModal={this.openModal}
                                showNotification={this.handleNotification}
                            />
                        )}
                        {/* <div className="bg-red-700 z-100 absolute inset-0"></div> */}

                        <div
                            className={`fixed overflow-y-auto overflow-x-hidden w-full h-screen ${this.state.isLoggedIn && !this.state.isPublic ? "pl-20" : "pl-0"}`}
                            id="bodyContainer"
                            onDragOver={this.dragOver}
                            onDragLeave={this.dragLeave}
                        >
                            {/* User Console */}

                            {/* Section */}
                            <Switch>
                                {/* <Route exact path = "/" >
                                <DashboardSection/>
                                </Route> */}
                                {/* <PrivateRoute component={DashboardSection} path='/' exact /> */}
                                {/* <PrivateRoute component={ScreensSection} showNotification={this.handleNotification} path='/screens' exact /> */}
                                <PrivateRoute
                                    component={ScreensSection}
                                    openModal={this.openModal}
                                    showNotification={this.handleNotification}
                                    path="/"
                                    exact
                                />
                                {/* <Route path = "/screens"
                                component = { ScreensSection }/>  */}
                                {/* <Route path = "/drop"
                                component = { FileDropper }/>  */}
                                <PrivateRoute
                                    component={Wizard}
                                    setLoading={this.handleLoading}
                                    showNotification={this.handleNotification}
                                    path="/wizard"
                                    exact
                                />
                                <PrivateRoute
                                    component={BulkImportSection}
                                    setLoading={this.handleLoading}
                                    showNotification={this.handleNotification}
                                    openModal={this.openModal}
                                    path="/import"
                                    exact
                                />
                                {/* <Route path = "/wizard"
                                component = { Wizard }/> */}
                                <PrivateRoute
                                    component={Content}
                                    openModal={this.openModal}
                                    showNotification={this.handleNotification}
                                    path="/content"
                                    openDropper={this.handleDropFiles}
                                    exact
                                />
                                <PrivateRoute
                                    component={Reports}
                                    setLoading={this.handleLoading}
                                    showNotification={this.handleNotification}
                                    openModal={this.openModal}
                                    path="/reports"
                                    exact
                                />
                                <PrivateRoute
                                    component={Management}
                                    closeNotification={this.closeNotification}
                                    openModal={this.openModal}
                                    showNotification={this.handleNotification}
                                    path="/management"
                                    exact
                                />

                                {/* <PrivateRoute  openModal={this.openModal} showNotification={this.handleNotification} component={AdminSection} path='/admin' exact /> */}
                                {/* Calendario */}
                                <PrivateRoute
                                    component={Calendar}
                                    openModal={this.openModal}
                                    showNotification={this.handleNotification}
                                    path="/scheduler"
                                    exact
                                />
                                {/* <Route path = "/content"
                                component = { ContentSection }/> */}
                                <UnloggedRoute
                                    component={Login}
                                    showNotification={this.handleNotification}
                                    setLogin={this.setLogin}
                                    path="/login"
                                    exact
                                />
                                {/* <Route path = "/login"
                                component = { Login }/>  */}
                                <UnloggedRoute
                                    component={Register}
                                    showNotification={this.handleNotification}
                                    path="/register"
                                    exact
                                />
                                {/* <Route path = "/register"
                                component = { Register }/>  */}
                                <UnloggedRoute
                                    component={ForgotPassword}
                                    showNotification={this.handleNotification}
                                    path="/forgotpassword"
                                    exact
                                />
                                {/* <Route path = "/forgotpassword"
                                component = { ForgotPassword }/> */}
                                <UnloggedRoute
                                    component={ResetPassword}
                                    path="/resetpassword/:id"
                                    exact
                                />
                                <UnloggedRoute
                                    component={ConfirmAccount}
                                    path="/confirm_account/:code"
                                    exact
                                />
                                {/* Public Routes */}
                                <PublicRoute
                                    component={SharedCameraView}
                                    showNotification={this.handleNotification}
                                    setIsShared={this.setIsShared}
                                    path="/shared/:id/:order"
                                    exact
                                />
                                {/* <Route path = "/resetpassword"
                                component = { ResetPassword }/> */}
                                <Route path="*">
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                        </div>

                        {this.state.isLoggedIn && (
                            <div id="dragAdviser" className="m-auto hidden">
                                <p>
                                    {this.props.t(
                                        "common.modals.fileDropper.dropArea",
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                </FileDrop>
            </BrowserRouter>
        );
    }
}

export default withTranslation()(App);
