import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });

export const uploadFiles = async (files) => {
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
        data.append("files", files[i], files[i].name);
    }
    return instance_axios
        .post(`${baseURL}/storage`, data, {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};
