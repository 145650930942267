import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class StepThree extends Component {
    back = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep } = this.props;
        setCurrentStep(currentStep - 1);
    };

    continue = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep } = this.props;
        setCurrentStep(currentStep + 1);
    };

    render() {
        const handleChange = this.props.handleChange;
        const handleNumberWithDecimals = this.props.handleNumberWithDecimals;
        const handleSimpleSelect = this.props.handleSimpleSelect;
        const {
            brand,
            quality,
            size_width_meters,
            size_height_meters,
            floor_distance,
            orientation,
        } = this.props.values;
        const orientations = [
            {
                name: this.props.t("sections.wizard.stepThree.north"),
                value: "north",
            },
            {
                name: this.props.t("sections.wizard.stepThree.east"),
                value: "east",
            },
            {
                name: this.props.t("sections.wizard.stepThree.south"),
                value: "south",
            },
            {
                name: this.props.t("sections.wizard.stepThree.west"),
                value: "west",
            },
            {
                name: this.props.t("sections.wizard.stepThree.northEast"),
                value: "northEast",
            },
            {
                name: this.props.t("sections.wizard.stepThree.southEast"),
                value: "southEast",
            },
            {
                name: this.props.t("sections.wizard.stepThree.southWest"),
                value: "southWest",
            },
            {
                name: this.props.t("sections.wizard.stepThree.northWest"),
                value: "northWest",
            },
        ];
        const qualities = [
            { name: "Pitch 1", value: "pitch_1" },
            { name: "Pitch 2", value: "pitch_2" },
            { name: "Pitch 3", value: "pitch_3" },
            { name: "Pitch 4", value: "pitch_4" },
            { name: "Pitch 5", value: "pitch_5" },
            { name: "Pitch 6", value: "pitch_6" },
            { name: "Pitch 7", value: "pitch_7" },
            { name: "Pitch 8", value: "pitch_8" },
            { name: "Pitch 9", value: "pitch_9" },
            { name: "Pitch 10", value: "pitch_10" },
            { name: "Pitch 11", value: "pitch_11" },
            { name: "Pitch 12", value: "pitch_12" },
            { name: "Pitch 13", value: "pitch_13" },
            { name: "Pitch 14", value: "pitch_14" },
            { name: "Pitch 15", value: "pitch_15" },
            { name: "Pitch 16", value: "pitch_16" },
            { name: "Full HD", value: "FHD" },
            { name: "4K", value: "4k" },
            { name: "8K", value: "8k" },
        ];

        return (
            <div className="w-full p-5">
                <h1 className="text-gray-800 text-xl font-bold">
                    {this.props.t("sections.wizard.stepThree.stepTitle")}{" "}
                    <span className="font-light text-sm text-gray-700">
                        {" "}
                        -{" "}
                        {this.props.t(
                            "sections.wizard.stepThree.stepTitleExtra",
                        )}
                    </span>
                </h1>
                <hr />

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t("sections.wizard.stepThree.widthInMeter")}
                    </label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={handleNumberWithDecimals("size_width_meters")}
                        value={size_width_meters}
                        placeholder={this.props.t(
                            "sections.wizard.stepThree.widthInMeter",
                        )}
                    />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t(
                            "sections.wizard.stepThree.heightInMeter",
                        )}
                    </label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={handleNumberWithDecimals(
                            "size_height_meters",
                        )}
                        value={size_height_meters}
                        placeholder={this.props.t(
                            "sections.wizard.stepThree.heightInMeter",
                        )}
                    />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t("sections.wizard.stepThree.elevation")}
                    </label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={(e) => handleChange("floor_distance", e)}
                        value={floor_distance}
                        placeholder={this.props.t(
                            "sections.wizard.stepThree.elevation",
                        )}
                    />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t("sections.wizard.stepThree.orientation")}
                    </label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select
                                onChange={handleSimpleSelect("orientation")}
                                defaultValue="-1"
                                value={orientation}
                                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring"
                            >
                                <option value="-1">
                                    {this.props.t(
                                        "sections.wizard.stepThree.unspecified",
                                    )}
                                </option>
                                {orientations.map((orientation, index) => (
                                    <option
                                        key={index}
                                        value={orientation.value}
                                    >
                                        {orientation.name}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t("sections.wizard.stepFour.trademark")}
                    </label>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={(e) => handleChange("brand", e)}
                        value={brand}
                        placeholder={this.props.t(
                            "sections.wizard.stepFour.trademark",
                        )}
                    />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {this.props.t("sections.wizard.stepFive.quality")}
                    </label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select
                                onChange={handleSimpleSelect("quality")}
                                defaultValue="-1"
                                value={quality}
                                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring"
                            >
                                <option value="-1">
                                    {this.props.t(
                                        "sections.wizard.stepFive.unspecified",
                                    )}
                                </option>
                                {qualities.map((quality, index) => (
                                    <option key={index} value={quality.value}>
                                        {quality.name}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <button className="buttonSecondary" onClick={this.back}>
                        {this.props.t("common.buttons.back")}
                    </button>
                    <button className="buttonPrimary" onClick={this.continue}>
                        {this.props.t("common.buttons.next")}
                    </button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(StepThree);
