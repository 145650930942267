import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../utils/modals.css";
import Select, { createFilter } from "react-select";
import ModalLayout from "./common/ModalLayout";
import isEqual from "lodash.isequal";
class EditBrandModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: {},
        };
    }

    componentDidMount = () => {
        this.setState({
            brand: this.props.values,
        });
    };

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.values, this.props.values)) {
            this.setState({
                brand: this.props.values,
            });
        }
    };

    /**
     * Handles the changes in the brand object
     * @param {Array} e - Array of selected options
     * @param {String} field - Object property to modify
     */
    handleChange = (e, field) => {
        let { brand } = this.state;
        switch (field) {
            case "customer":
                brand[field] = e.value;
                break;
            case "categories":
                brand[field] = !e ? [] : e;
                break;
            default:
                break;
        }
        this.setState({ brand });
    };

    returnValue = () => {
        let { brand } = this.state;
        this.props.callbackFunction(brand);
        this.closeModal();
    };

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };

    render() {
        const { customers, categories } = this.props;
        let { brand } = this.state;
        const customerOptions = customers.map((cust) => {
            return { label: cust.contact_name, value: cust._id };
        });
        const customFilter = createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: "any",
        });
        return (
            <ModalLayout
                title={`${this.props.t("common.buttons.edit")}: ${brand.name}`}
                modalID={this.props.modalID}
                returnValue={this.returnValue}
            >
                <div className="mt-1 ml-2">
                    <p className="text-gray-400">
                        {this.props.t(
                            "sections.management.section.commercial.brands.brandCustomerPlaceholder",
                        )}
                    </p>
                    <Select
                        filterOptions={customFilter}
                        className="w-11/12"
                        onChange={(e) => this.handleChange(e, "customer")}
                        options={customerOptions}
                        value={[
                            customerOptions.find(
                                (customer) => customer.value === brand.customer,
                            ),
                        ]}
                    />
                </div>
                <div className="mt-1 ml-2">
                    <p className="text-gray-400">
                        {this.props.t(
                            "sections.management.section.commercial.brands.brandCategoryPlaceholder",
                        )}
                    </p>
                    <Select
                        filterOptions={customFilter}
                        className="w-11/12"
                        isMulti
                        onChange={(e) => this.handleChange(e, "categories")}
                        options={categories}
                        value={brand.categories}
                    />
                </div>
            </ModalLayout>
        );
    }
}
export default withTranslation()(EditBrandModal);
