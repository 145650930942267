import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import "./style.css";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { getScreens, getScreenWithCoords } from "./MapFunctions";
import { withTranslation } from "react-i18next";
import * as dotenv from "dotenv";

dotenv.config();
Geocode.setApiKey(process.env.REACT_APP_API_KEY_MAPS);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
class Mapv2 extends Component {
    constructor() {
        super();

        this.state = {
            marker_location: [],
            selectedMarker: {},
            searchBox: {},
            center: {
                lat: 0,
                lng: 0,
            },
            zoom: 6.3,
            infoWindow: {
                position: {
                    lat: "",
                    lng: "",
                },
            },
            mapsReference: null,
            mapInstance: null,
            markers: [],
            dragged: false,
        };
    }
    onMapLoaded = (map, maps) => {
        this.setState({ mapInstance: map });
        this.setState({ mapsReference: maps });

        if (this.props.info === "Screens") {
            let allScreens = this.state.marker_location;
            getScreens()
                .then((screens) => {
                    screens.data.forEach((screen) => {
                        let screens_marker = {
                            coordinates: screen.location.coordinates,
                            address: screen.location.address,
                        };
                        allScreens.push(screens_marker);
                    });
                    this.setState(
                        { marker_location: allScreens },
                        this.drawMarkers,
                    );
                })
                .catch((err) => console.log("getScreens Map error:", err));
        }
        this.checkMarkers();
        if (this.props.info !== "Screens") this.createSearchBox();
    };
    createSearchBox = () => {
        let input = document.getElementById("pac-input");
        let searchBox = new this.state.mapsReference.places.SearchBox(input);
        this.setState({ searchBox });
        this.state.mapInstance.controls[
            this.state.mapsReference.ControlPosition.TOP_CENTER
        ].push(input);
        this.state.mapInstance.addListener("bounds_changed", () => {
            searchBox.setBounds(this.state.mapInstance.getBounds());
        });
        searchBox.addListener("places_changed", this.handlePlacesChanged);
    };
    handlePlacesChanged = () => {
        let searchBox = this.state.searchBox;
        let lat = searchBox.getPlaces()[0].geometry.location.lat();
        let lng = searchBox.getPlaces()[0].geometry.location.lng();
        let address = searchBox.getPlaces()[0].formatted_address;
        let new_center = {
            lat,
            lng,
        };
        let marker = {
            coordinates: new_center,
            address,
        };
        this.setState({ center: new_center });
        this.setState({ zoom: 24 });
        let arr_markers = [];
        arr_markers.push(marker);
        setTimeout(() => {
            this.setState({ marker_location: arr_markers });
            this.drawMarkers();
        }, 500);
        if (!localStorage.getItem("screen")) {
            localStorage.setItem("screen", JSON.stringify(marker));
        } else {
            localStorage.removeItem("screen");
            localStorage.setItem("screen", JSON.stringify(marker));
        }
    };
    onCloseInfoWindow(self) {
        self.setState({ selectedMarker: null });
        self.props.selected(1);
    }
    checkMarkers = () => {
        const location = this.props.location;
        if (location) {
            if (location.length > 0) {
                let markers = this.state.marker_location;
                markers = markers.concat(location);
                this.setState({ marker_location: markers }, this.drawMarkers);
            }
        }
    };
    drawMarkers = () => {
        let arrayMarkers = [];
        let first_marker = this.state.marker_location[0];
        if (first_marker) {
            this.state.mapInstance.panTo(first_marker.coordinates);
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                let new_center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.state.mapInstance.panTo(new_center);
            });
        }
        this.state.marker_location.forEach((location) => {
            let svgMarker = {
                path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
                fillColor: "blue",
                fillOpacity: 0.8,
                anchor: new this.state.mapsReference.Point(0, 500),
                scale: 0.04,
            };
            let marker = new this.state.mapsReference.Marker({
                icon: svgMarker,
                position: location.coordinates,
                draggable: this.props.info !== "Screens",
                map: this.state.mapInstance,
            });
            this.state.mapInstance.addListener();
            marker.addListener("click", () => {
                let newCenter = {
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng(),
                };
                this.smoothZoom(
                    this.state.mapInstance,
                    12,
                    this.state.mapInstance.getZoom(),
                );
                this.state.mapInstance.panTo(newCenter);
                if (this.props.info === "Screens") {
                    this.showInfoMarker(marker);
                }
            });
            if (this.props.info !== "Screens") {
                marker.addListener("dragend", (e) => {
                    let lat = e.latLng.lat();
                    let lng = e.latLng.lng();
                    Geocode.fromLatLng(lat, lng)
                        .then((r) => {
                            marker.setPosition({ lat, lng });
                            let markerScreen = {
                                coordinates: {
                                    lat,
                                    lng,
                                },
                                address: r.results[0].formatted_address,
                            };
                            if (!localStorage.getItem("screen")) {
                                localStorage.setItem(
                                    "screen",
                                    JSON.stringify(markerScreen),
                                );
                            } else {
                                localStorage.removeItem("screen");
                                localStorage.setItem(
                                    "screen",
                                    JSON.stringify(markerScreen),
                                );
                            }
                            let infoWindowContent = `
                <div>
                  <p>${markerScreen.address}</p>
                </div>`;
                            let newInfoWindow =
                                new this.state.mapsReference.InfoWindow({
                                    content: infoWindowContent,
                                });
                            newInfoWindow.open(this.state.mapInstance, marker);
                        })
                        .catch((err) => console.log(err));
                });
            }
            arrayMarkers.push(marker);
        });
        this.setState({ markers: arrayMarkers }, () => {
            new MarkerClusterer(this.state.mapInstance, this.state.markers, {
                imagePath:
                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            });
        });
    };
    getAdressByCoords = (lat, lng) => {
        let location = {
            lat,
            lng,
        };

        let allMarkers = [...this.state.marker_location];
        let marker = allMarkers.find((marker) => {
            return (
                marker.coordinates.lat === location.lat &&
                marker.coordinates.lng === location.lng
            );
        });
        return marker.address;
    };
    showInfoMarker = (marker) => {
        let location = {
            type: "Point",
            coordinates: {
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng(),
            },
            address: this.getAdressByCoords(
                marker.getPosition().lat(),
                marker.getPosition().lng(),
            ),
        };
        getScreenWithCoords(location)
            .then((screen) => {
                this.setState({ selectedMarker: screen.data[0] }, () =>
                    this.showInfoWindow(marker),
                );
                if (this.props.selected) {
                    this.props.selected(this.state.selectedMarker._id);
                }
            })
            .catch((err) => console.log(err));
    };

    showInfoWindow = (marker) => {
        let infoWindowContent = `
        <div>
          <p>${this.state.selectedMarker.name}</p>
          <p>${this.state.selectedMarker.pixel_size_width}x${this.state.selectedMarker.pixel_size_height}</p>
        </div>`;
        let maps = { ...this.state.mapsReference };
        let infoWindow = new maps.InfoWindow({
            content: infoWindowContent,
        });
        infoWindow.addListener("closeclick", (e) => {
            this.onCloseInfoWindow(this);
        });
        setTimeout(() => {
            infoWindow.open(this.state.mapInstance, marker);
        }, 1000);
    };
    smoothZoom = (map, max, cnt) => {
        if (cnt >= max) {
            return;
        } else {
            let self = this;
            let z = this.state.mapsReference.event.addListener(
                map,
                "zoom_changed",
                function (event) {
                    self.state.mapsReference.event.removeListener(z);
                    self.smoothZoom(map, max, cnt + 1);
                },
            );
            setTimeout(function () {
                map.setZoom(cnt);
            }, 80);
        }
    };
    render() {
        return (
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_API_KEY_MAPS,
                    libraries: [
                        "places",
                        "geometry",
                        "drawing",
                        "visualization",
                    ],
                }}
                center={this.state.center}
                zoom={this.state.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) =>
                    this.onMapLoaded(map, maps)
                }
                options={{
                    styles: [
                        {
                            featureType: "all",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "administrative",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#444444",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.province",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.locality",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.neighborhood",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.land_parcel",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.land_parcel",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "landscape",
                            elementType: "all",
                            stylers: [
                                {
                                    color: "#f2f2f2",
                                },
                            ],
                        },
                        {
                            featureType: "landscape.man_made",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                                {
                                    color: "#cee9de",
                                },
                                {
                                    saturation: "2",
                                },
                                {
                                    weight: "0.80",
                                },
                            ],
                        },
                        {
                            featureType: "poi.attraction",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "road",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: -100,
                                },
                                {
                                    lightness: 45,
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                                {
                                    color: "#f5d6d6",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    hue: "#ff0000",
                                },
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway.controlled_access",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway.controlled_access",
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                                {
                                    hue: "#0064ff",
                                },
                                {
                                    gamma: "1.44",
                                },
                                {
                                    lightness: "-3",
                                },
                                {
                                    weight: "1.69",
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "road.local",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "road.local",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                                {
                                    weight: "0.31",
                                },
                                {
                                    gamma: "1.43",
                                },
                                {
                                    lightness: "-5",
                                },
                                {
                                    saturation: "-22",
                                },
                            ],
                        },
                        {
                            featureType: "transit",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "transit.line",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                                {
                                    hue: "#ff0000",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station.airport",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                                {
                                    hue: "#ff0045",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station.bus",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                                {
                                    hue: "#00d1ff",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station.bus",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station.rail",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                                {
                                    hue: "#00cbff",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station.rail",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "simplified",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "all",
                            stylers: [
                                {
                                    color: "#46bcec",
                                },
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    weight: "1.61",
                                },
                                {
                                    color: "#cde2e5",
                                },
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                    ],
                }}
            >
                {this.props.info !== "Screens" && (
                    <input
                        id="pac-input"
                        className="controls rounded border shadow px-2 mt-2"
                        type="text"
                        placeholder={this.props.t("map.searchBoxPlaceHolder")}
                    />
                )}
                {/* <ScreenMarker
            screenName="Marker Prueba"
            screenSize="800x600"
            screenDuration="10"
            lat="-36.2372574"
            lng="-61.1189887"
        >
        </ScreenMarker> */}
            </GoogleMapReact>
        );
    }
}
export default withTranslation()(Mapv2);
