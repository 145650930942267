import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import ListedEventModal from "./ListedEventModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./EventForPlaylist.css";
import { getContentFromID } from "../screens/Screen.functions";
const imageExtensions = ["png", "jpg", "jpeg", "gif"];
const videoExtensions = ["mp4", "ogg", "webm"];
const htmlExtensions = ["html", "zip"];

class EventForPlaylistGrid extends Component {
    constructor() {
        super();
        this.state = {
            response: {},
        };
    }

    componentDidMount = async () => {
        let response = await getContentFromID(this.props.event.content[0]);
        this.setState({ response: response });
    };

    openModal = (modalID) => {
        this.props.openModal(modalID);
    };

    /* Checking if the event is happening today. */
    isEventToday = (event) => {
        let today = new Date().getTime();
        let eventStart = new Date(event.start).setHours(
            event.hourstart.split(":")[0],
            event.hourstart.split(":")[1],
        );
        let eventEnd = new Date(event.end).setHours(
            event.hourend.split(":")[0],
            event.hourend.split(":")[1],
        );
        if (eventStart <= today && today < eventEnd) {
            let startToday = new Date(today);
            startToday.setHours(
                event.hourstart.split(":")[0],
                event.hourstart.split(":")[1],
            );
            let endToday = new Date(today);
            endToday.setHours(
                event.hourend.split(":")[0],
                event.hourend.split(":")[1],
            );
            if (startToday <= today && today < endToday) {
                if (
                    event.repetitive_day &&
                    event.repetitive_day.includes(new Date().getDay())
                ) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventPlaying",
                        ),
                        "green",
                    );
                } else if (!event.repetitive_day) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventPlaying",
                        ),
                        "green",
                    );
                }
            } else {
                eventStart = new Date(event.start).setHours("00");
                eventEnd = new Date(event.end).setHours("23", "59");
                if (eventStart <= today && today < eventEnd) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventTodayBadge",
                        ),
                        "yellow",
                    );
                } else {
                    return "";
                }
            }
        } else {
            eventStart = new Date(event.start).setHours("00");
            eventEnd = new Date(event.end).setHours("23", "59");
            if (eventStart <= today && today < eventEnd) {
                return this.renderBadge(
                    this.props.t(
                        "sections.calendar.eventForPlaylist.eventTodayBadge",
                    ),
                    "yellow",
                );
            } else {
                return "";
            }
        }
    };

    /* A function that returns a span with a variable inside. */
    renderBadge = (badge, color) => {
        return (
            <span
                className={`p-1 rounded bg-${color}-400 text-gray-800 font-semibold text-xs`}
            >
                {badge}
            </span>
        );
    };

    render() {
        const EVENT = this.props.event;
        const t = this.props.t;
        console.log(this.props.path?.prop.split(".")[1]);
        return (
            <>
                <div
                    className=" h-full"
                    onClick={() =>
                        this.openModal(
                            `eventModal${EVENT._id}${this.props.index}`,
                        )
                    }
                >
                    <div className="">
                        <div
                            className="absolute flex justify-center w-5 h-5 bg-white rounded-full ml-2 mt-7 cursor-pointer"
                            data-for="ScheduledEvent"
                            data-tip
                        >
                            <ReactTooltip
                                effect="solid"
                                id="ScheduledEvent"
                                aria-haspopup="true"
                            >
                                <p className="text-center">
                                    {" "}
                                    {t(
                                        "sections.screens.screenPlaylist.ScheduledEvent",
                                    )}
                                </p>
                            </ReactTooltip>
                            <FontAwesomeIcon
                                icon={["fal", "calendar-check"]}
                                className="relative text-xs top-1 "
                            />
                        </div>
                        {EVENT.isExclusive && (
                            <>
                                <ReactTooltip
                                    effect="solid"
                                    id="exclusiveEvent"
                                    aria-haspopup="true"
                                >
                                    <p className="text-center">
                                        {t(
                                            "sections.screens.screenPlaylist.ExclusiveEvent",
                                        )}
                                    </p>
                                </ReactTooltip>
                                <div
                                    className="absolute w-5 h-5 bg-white rounded-full ml-2 mt-16 "
                                    data-for="exclusiveEvent"
                                    data-tip
                                >
                                    <FontAwesomeIcon
                                        icon={["fas", "star"]}
                                        className="mr-3 text-sm text-yellow-300  iconCircleStar"
                                    />
                                </div>
                            </>
                        )}
                        <div className="mt-1 align-middle">
                            <span className=" ml-3 font-bold"></span>
                            {/* miniatura */}
                            {
                                <div className="h-40 mx-auto overflow-hidden rounded">
                                    {imageExtensions.includes(
                                        this.props.path?.prop.split(".")[1],
                                    ) && (
                                        <>
                                            <img
                                                data-tip
                                                data-for={
                                                    "tooltip" +
                                                    this.props.path?.id
                                                }
                                                className="object-cover "
                                                draggable="false"
                                                src={`${process.env.REACT_APP_FILESERVER_URL}/${this.props.path?.prop}`}
                                                alt={EVENT.name}
                                            />
                                        </>
                                    )}
                                    {videoExtensions.includes(
                                        this.props.path?.prop.split(".")[1],
                                    ) && (
                                        <>
                                            <video
                                                data-tip
                                                data-for={
                                                    "tooltip" +
                                                    this.props.path?.id
                                                }
                                                draggable="false"
                                                src={`${process.env.REACT_APP_FILESERVER_URL}/${this.props.path?.prop}`}
                                            ></video>
                                        </>
                                    )}
                                    {htmlExtensions.includes(
                                        this.props.path?.prop.split(".")[1],
                                    ) && (
                                        <>
                                            <img
                                                data-tip
                                                data-for={
                                                    "tooltip" +
                                                    this.props.path?.id
                                                }
                                                draggable="false"
                                                src={require("../../images/common/html.png")}
                                                alt={EVENT.name}
                                            ></img>
                                        </>
                                    )}
                                    <p>a</p>
                                </div>
                            }
                        </div>
                        <div className="flex text-xs">
                            <div className=" flex flex-col w-full screenFont">
                                <p
                                    className=" font-bold title"
                                    data-tip
                                    data-for={`eventFullName${EVENT._id}`}
                                >
                                    {" "}
                                    {EVENT.title}{" "}
                                    <span className="spanBadge">
                                        {this.isEventToday(EVENT)}
                                    </span>
                                </p>
                                <div className="mt-1 ">
                                    <p className="font-lightt text-gray-500 ml-1">
                                        {" "}
                                        <FontAwesomeIcon
                                            icon={["fal", "calendar-check"]}
                                            className="mr-3 icon"
                                        />
                                        {EVENT.start.split("T")[0]}{" "}
                                        <span className="mx-0.1">~</span>{" "}
                                        {EVENT.end.split("T")[0]}
                                    </p>
                                    <p className="font-lightt text-gray-500 ml-1">
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="mr-2 icon"
                                        />{" "}
                                        {EVENT.hourstart}hs{" "}
                                        <span className="mx-0.1">~</span>{" "}
                                        {EVENT.hourend}hs{" "}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <Link
                                    to={{
                                        pathname: "/scheduler",
                                        state: {
                                            data: {
                                                event: EVENT._id,
                                            },
                                        },
                                    }}
                                >
                                    <div className="text-left py-1 font-bold  hover:text-black">
                                        <FontAwesomeIcon
                                            icon={["fal", "sliders-h"]}
                                            className="mr-3 "
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <ListedEventModal
                    isReOrdening={this.props.isReOrdening}
                    updateOrder={this.props.updateOrder}
                    getEventContents={this.props.getEventContents}
                    screen={this.props.screen}
                    key={`${EVENT._id}+${this.props.index}`}
                    modalID={`eventModal${EVENT._id}${this.props.index}`}
                    event={EVENT}
                    contents={this.props.contents}
                />
            </>
        );
    }
}

export default withTranslation()(EventForPlaylistGrid);
