import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class DailyLimitBar extends Component {
    render() {
        const { item, width } = this.props;
        return (
            <>
                <div
                    className={` absolute z-10 ml-3.5 ${width} mt-32 bg-gray-200 rounded-full h-2 dark:bg-gray-700`}
                >
                    {(item.impressions_in_day / item.daily_limit) * 100 ===
                    100 ? (
                        <div
                            className="bg-red-600 h-2 rounded-full"
                            style={{
                                width:
                                    (item.impressions_in_day /
                                        item.daily_limit) *
                                        100 +
                                    "%",
                            }}
                        ></div>
                    ) : (
                        <div
                            className={`${(item.impressions_in_day / item.daily_limit) * 100 < 30 ? "bg-blue-300" : "bg-yellow-300"} h-3 rounded-full`}
                            style={{
                                width:
                                    (item.impressions_in_day /
                                        item.daily_limit) *
                                        100 +
                                    "%",
                            }}
                        ></div>
                    )}
                </div>
            </>
        );
    }
}
export default withTranslation()(DailyLimitBar);
