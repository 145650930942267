import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
export const addGroup = (data) => {
    return instance_axios
        .post("/generic", [data], {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((groups) => groups)
        .catch((err) => console.log(err));
};

export const addScreen = (data) => {
    return instance_axios
        .post("/generic", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((screen) => screen)
        .catch((err) => err);
};
export const editScreen = (data) => {
    return instance_axios
        .put("/screen/update", data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};

export const getGroups = () => {
    return instance_axios
        .get("/generic/screenGroup", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((groups) => groups)
        .catch((err) => console.log(err));
};

export const getRestrictions = () => {
    return instance_axios
        .get("/generic/restriction", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((restrictions) => restrictions)
        .catch((err) => console.log(err));
};
export const getScreenByID = (ID) => {
    return instance_axios
        .get("/generic/screen/" + ID, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        })
        .then((r) => r)
        .catch((err) => err);
};
export const readSheetAndReturnJsonScreen = (files) => {
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
        data.append("sheet", files[i], files[i].name);
    }
    return instance_axios
        .post(`/screen/readsheet`, data, {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
            withCredentials: true,
        })
        .then((r) => r)
        .catch((err) => err.request);
};
