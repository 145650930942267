import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ButtonSwitcher from "../../../buttons/ButtonSwitcher";
import Title from "../common/Title";

class ProgrammaticLayout extends Component {
    render() {
        const { publisher_id, programmaticScreens } = this.props;
        return (
            <div className="flex flex-col md:flex-row divide-x divide-dotted">
                <div className="md:w-6/12 p-4">
                    <Title>
                        <FontAwesomeIcon
                            icon={["fal", "fingerprint"]}
                            fixedWidth
                            className="text-blue-500"
                        />{" "}
                        {this.props.t(
                            "sections.management.section.programmatic.title",
                        )}
                        <p className="font-light">
                            {this.props.t(
                                "sections.management.section.programmatic.programmaticTxt",
                            )}
                        </p>
                    </Title>
                    <div className="my-2 md:w-4/6">
                        <div className="relative flex flex-grow">
                            <input
                                type="text"
                                id="publisherID"
                                value={publisher_id}
                                onChange={this.props.onChangePublisherId}
                                tabIndex="1"
                                className="shadow appearance-none border rounded w-full my-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                placeholder="SSP Publisher ID"
                            />
                            <button
                                className="buttonPrimary ml-10"
                                onClick={(e) => this.props.setPublisherID()}
                            >
                                {this.props.t(
                                    "sections.management.section.programmatic.setPublisherID",
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="md:w-6/12 p-4">
                    <Title>
                        <FontAwesomeIcon
                            icon={["fal", "coins"]}
                            fixedWidth
                            className="text-blue-500"
                        />{" "}
                        {this.props.t(
                            "sections.management.section.programmatic.programmaticList.title",
                        )}{" "}
                        {this.props.itemNeedTooltip(
                            <FontAwesomeIcon
                                icon={["fas", "info-circle"]}
                                className="text-black-500 text-sm"
                            />,
                            this.props.t(
                                "sections.management.section.programmatic.programmaticList.programmaticTable.noSSPIDTooltip",
                            ),
                            0,
                        )}
                        <p className="font-light">
                            {this.props.t(
                                "sections.management.section.programmatic.programmaticList.listTxt",
                            )}
                        </p>
                    </Title>
                    <div className="flex-grow overflow-auto h-40">
                        <table className="relative w-full">
                            <thead>
                                <tr>
                                    <th className="sticky z-20 text-left rounded-tl-lg bg-gray-200 top-0 px-1 md:px-3 py-1">
                                        {this.props.t(
                                            "sections.management.section.programmatic.programmaticList.programmaticTable.name",
                                        )}
                                    </th>
                                    <th className="sticky z-20 bg-gray-200 top-0 px-1 md:px-3 py-1 text-right">
                                        {this.props.t(
                                            "sections.management.section.programmatic.programmaticList.programmaticTable.cpm",
                                        )}
                                    </th>
                                    <th className="sticky z-20 text-right rounded-tr-lg bg-gray-200 top-0 px-1 md:px-3 py-1">
                                        {this.props.t(
                                            "sections.management.section.programmatic.programmaticList.programmaticTable.active",
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-dotted odd:bg-gray-100 overflow-y-auto max-h-96">
                                {programmaticScreens.map((screen) => {
                                    return (
                                        <tr className="hover:bg-gray-50 transition-colors duration-100">
                                            <td className="px-1 md:px-3 font-light text-sm py-2">
                                                {screen.name}
                                                {this.props.itemAndProgrammaticHasEqualDimension(
                                                    screen,
                                                )
                                                    ? ""
                                                    : this.props.itemNeedTooltip(
                                                          <FontAwesomeIcon
                                                              icon={[
                                                                  "fas",
                                                                  "exclamation-triangle",
                                                              ]}
                                                              className="text-red-500 text-sm"
                                                          />,
                                                          this.props.t(
                                                              "sections.management.section.programmatic.programmaticList.programmaticTable.diffSize",
                                                          ),
                                                          screen._id,
                                                      )}
                                            </td>
                                            <td className="px-1 md:px-3 font-light text-sm py-2 text-right">
                                                {screen.cpm}
                                            </td>
                                            <td className="px-1 md:px-3 font-light text-sm py-2 text-right overflow-ellipsis md:w-44">
                                                <ButtonSwitcher
                                                    handleVisibility={() =>
                                                        this.props.handleProgrammatic(
                                                            screen._id,
                                                        )
                                                    }
                                                    status={
                                                        screen.programmatic_view
                                                    }
                                                    revertLabel={true}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ProgrammaticLayout);
