import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import ListedEventModal from "./ListedEventModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./EventForPlaylist.css";
import { getContentFromID } from "../screens/Screen.functions";

const imageExtensions = ["png", "jpg", "jpeg", "gif"];
const videoExtensions = ["mp4", "ogg", "webm"];
const htmlExtensions = ["html", "zip"];

class EventForPlaylist extends Component {
    constructor() {
        super();
        this.state = {
            response: {},
        };
    }
    componentDidMount = async () => {
        let response = await getContentFromID(this.props.event.content[0]);
        this.setState({ response: response });
    };
    /* Rendering the image or video or html icon. */
    renderImgOrthumnail = () => {
        if (this.props.contents && this.props.event) {
            let content = this.state.response.data;
            if (!content) {
                return;
            }
            if (!content) {
                return;
            }
            let path =
                process.env.REACT_APP_FILESERVER_URL + "/" + content.path;
            if (imageExtensions.includes(content.ext)) {
                return (
                    <div className="contenedorEvento flex items-center  justify-center mx-1 my-1 rounded-lg bg-black   overflow-hidden">
                        <img
                            className="rounded-lg object-fill"
                            src={path}
                            alt={content.name}
                        />
                    </div>
                );
            } else if (videoExtensions.includes(content.ext)) {
                return (
                    <div className="contenedorEvento flex items-center  justify-center mx-1 my-1 rounded-lg bg-black   overflow-hidden">
                        <video
                            src={path}
                            alt={content.name}
                            className="object-fill"
                        ></video>{" "}
                    </div>
                );
            } else if (htmlExtensions.includes(content.ext)) {
                return (
                    <div className="contenedorEvento flex items-center  justify-center mx-1 my-1 rounded-lg bg-black   overflow-hidden">
                        <img
                            className="w-20"
                            src={require("../../images/common/html.png")}
                            alt={content.name}
                        ></img>
                    </div>
                );
            }
        }
    };

    openModal = (modalID) => {
        this.props.openModal(modalID);
    };

    /* Checking if the event is happening today. */
    isEventToday = (event) => {
        let today = new Date().getTime();
        let eventStart = new Date(event.start).setHours(
            event.hourstart.split(":")[0],
            event.hourstart.split(":")[1],
        );
        let eventEnd = new Date(event.end).setHours(
            event.hourend.split(":")[0],
            event.hourend.split(":")[1],
        );
        if (eventStart <= today && today < eventEnd) {
            let startToday = new Date(today);
            startToday.setHours(
                event.hourstart.split(":")[0],
                event.hourstart.split(":")[1],
            );
            let endToday = new Date(today);
            endToday.setHours(
                event.hourend.split(":")[0],
                event.hourend.split(":")[1],
            );
            if (startToday <= today && today < endToday) {
                if (
                    event.repetitive_day &&
                    event.repetitive_day.includes(new Date().getDay())
                ) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventPlaying",
                        ),
                        "green",
                    );
                } else if (!event.repetitive_day) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventPlaying",
                        ),
                        "green",
                    );
                }
            } else {
                eventStart = new Date(event.start).setHours("00");
                eventEnd = new Date(event.end).setHours("23", "59");
                if (eventStart <= today && today < eventEnd) {
                    return this.renderBadge(
                        this.props.t(
                            "sections.calendar.eventForPlaylist.eventTodayBadge",
                        ),
                        "yellow",
                    );
                } else {
                    return "";
                }
            }
        } else {
            eventStart = new Date(event.start).setHours("00");
            eventEnd = new Date(event.end).setHours("23", "59");
            if (eventStart <= today && today < eventEnd) {
                return this.renderBadge(
                    this.props.t(
                        "sections.calendar.eventForPlaylist.eventTodayBadge",
                    ),
                    "yellow",
                );
            } else {
                return "";
            }
        }
    };

    /* A function that returns a span with a variable inside. */
    renderBadge = (badge, color) => {
        return (
            <span
                className={`p-1 rounded bg-${color}-400 text-gray-800 font-semibold text-xs`}
            >
                {badge}
            </span>
        );
    };

    render() {
        const EVENT = this.props.event;
        const t = this.props.t;

        return (
            <>
                <div
                    className="flex justify-between hover:bg-blue-100 rounded"
                    onClick={() =>
                        this.openModal(
                            `eventModal${EVENT._id}${this.props.index}`,
                        )
                    }
                >
                    <div className="flex">
                        <div
                            className="absolute flex justify-center  w-5 h-5 bg-white rounded-full ml-2 mt-3 cursor-pointer"
                            data-for="ScheduledEvent"
                            data-tip
                        >
                            <ReactTooltip
                                effect="solid"
                                id="ScheduledEvent"
                                aria-haspopup="true"
                            >
                                <p className="text-center">
                                    {" "}
                                    {t(
                                        "sections.screens.screenPlaylist.ScheduledEvent",
                                    )}
                                </p>
                            </ReactTooltip>
                            <FontAwesomeIcon
                                icon={["fal", "calendar-check"]}
                                className="relative text-xs top-1 "
                            />
                        </div>
                        {EVENT.isExclusive && (
                            <>
                                <ReactTooltip
                                    effect="solid"
                                    id="exclusiveEvent"
                                    aria-haspopup="true"
                                >
                                    <p className="text-center">
                                        {t(
                                            "sections.screens.screenPlaylist.ExclusiveEvent",
                                        )}
                                    </p>
                                </ReactTooltip>

                                <div
                                    className="absolute w-5 h-5 bg-white rounded-full ml-2 mt-16 "
                                    data-for="exclusiveEvent"
                                    data-tip
                                >
                                    <FontAwesomeIcon
                                        icon={["fas", "star"]}
                                        className="mr-3 text-sm text-yellow-300  iconCircleStar"
                                    />
                                </div>
                            </>
                        )}
                        <div className=" py-1  align-middle">
                            {/* miniatura */}
                            {this.renderImgOrthumnail()}
                        </div>
                        <div className="flex flex-col w-full screenFont">
                            <p
                                className="font-bold title"
                                data-tip
                                data-for={`eventFullName${EVENT._id}`}
                            >
                                {" "}
                                {EVENT.title}{" "}
                                <span className="spanBadge">
                                    {this.isEventToday(EVENT)}
                                </span>
                            </p>
                            <p className="font-lightt text-gray-500 ml-1">
                                {" "}
                                <FontAwesomeIcon
                                    icon={["fal", "calendar-check"]}
                                    className="mr-3 icon"
                                />
                                {EVENT.start.split("T")[0]}{" "}
                                <span className="mx-0.1">~</span>{" "}
                                {EVENT.end.split("T")[0]}
                            </p>
                            <p className="font-lightt text-gray-500 ml-1">
                                <FontAwesomeIcon
                                    icon={["fal", "clock"]}
                                    className="mr-2 icon"
                                />{" "}
                                {EVENT.hourstart}hs{" "}
                                <span className="mx-0.1">~</span>{" "}
                                {EVENT.hourend}hs{" "}
                            </p>
                            {/* <p className="font-lightt text-gray-500 ml-1"> <FontAwesomeIcon icon={["fal", "check"]} className="mr-3 icon" />Otro text</p> */}
                            {/* <FontAwesomeIcon icon={this.props.content.contents.downloaded.includes(this.props.screen._id) ? ["fad", "check-double"] : ["fad", "hourglass-half"]} fixedWidth className="text-blue-300 mr-2"/>  */}
                        </div>
                    </div>
                    <Link
                        to={{
                            pathname: "/scheduler",
                            state: {
                                data: {
                                    event: EVENT._id,
                                },
                            },
                        }}
                    >
                        <div className="text-left py-2 font-bold mr-3 hover:text-black">
                            <FontAwesomeIcon
                                icon={["fal", "sliders-h"]}
                                className="mr-3 "
                            />
                            {this.props.t("common.buttons.edit")}
                        </div>
                    </Link>
                </div>
                <ListedEventModal
                    isReOrdening={this.props.isReOrdening}
                    updateOrder={this.props.updateOrder}
                    getEventContents={this.props.getEventContents}
                    screen={this.props.screen}
                    key={`${EVENT._id}+${this.props.index}`}
                    modalID={`eventModal${EVENT._id}${this.props.index}`}
                    event={EVENT}
                    contents={this.props.contents}
                />
            </>
        );
    }
}

export default withTranslation()(EventForPlaylist);
