import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScreenPlaylist from "./ScreenPlaylist";
import "./modals/modal.css";
import {
    removeScreen,
    removeScreenImages,
    getScreen,
    setSSPID,
    getCamerasByScreen,
    isTunnelUp,
    suscribeToChangesInCameras,
} from "./Screen.functions";
import DecisionModal from "../utils/DecisionModal";
import { Link } from "react-router-dom";
import OneInputModal from "../utils/OneInputModal";
import ReactTooltip from "react-tooltip";
import { Doughnut } from "react-chartjs-2";
import cloneDeep from "lodash.clonedeep";

class ScreenItem extends Component {
    constructor() {
        super();
        this.state = {
            isElementVisible: false,
            isMenuVisible: false,
            isSelected: false,
            active: false,
            changesSSP: false,
            isOutOfDate: false,
            dataPieChart: "",
            cameras: [],
        };
        this.toggleElement = this.toggleElement.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.activeTimeOutID = 0;
        this.activeIntervalID = 0;
        this.responseCameraSSE = false;
        this.screenOutOfDateIntervalID = 0;
    }

    toggleElement() {
        this.setState({ isElementVisible: !this.state.isElementVisible });
    }
    toggleMenu() {
        this.setState({ isMenuVisible: !this.state.isMenuVisible });
    }
    async componentDidMount() {
        this.setActive(this.props.screen);
        this.checkActive(this.props.screen);
        if (this.props.screen._id === this.props.selected) {
            this.setState({ isSelected: true });
        }
        this.checkScreenIsOutOfDate();
        this.getStatisticsPerScreen();
        this.listenToCameraChangesAndGetCameras();
        this.getAndSetCameras();
        const id = setInterval(this.checkScreenIsOutOfDate, 1000 * 60 * 15);
        this.screenOutOfDateIntervalID = id;
        document.addEventListener("click", this.checkClickOutside);
    }
    componentWillUnmount = () => {
        if (this.responseCameraSSE) {
            this.responseCameraSSE.close();
        }
    };
    /**
     *  Retrieves and sets the cameras for a given screen ID in the component's state.
     */
    getAndSetCameras = async () => {
        try {
            let cameras = await getCamerasByScreen(this.props.screen._id);
            this.setState({ cameras: cameras.data });
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };
    /**
     * This function asynchronously subscribes to changes in cameras and adds an event listener for
     * incoming messages.
     */
    async listenToCameraChangesAndGetCameras() {
        try {
            this.responseCameraSSE = await suscribeToChangesInCameras();
            this.responseCameraSSE.addEventListener(
                "message",
                this.ifCamerasUpdatesGetsAgain,
            );
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    }
    /**
     * This function updates the state of cameras based on a message received, if the affected user
     * matches the camera's user.
     * @param message - The parameter `message` is an object that contains data received from a message
     * event. It is likely that this function is being used as a callback function for a SSE.
     *  The `message` object is parsed using `JSON.parse()` to
     * extract the relevant data.
     */
    ifCamerasUpdatesGetsAgain = async (message) => {
        let messageParsed = JSON.parse(message.data);
        if (
            this.state.cameras.length > 0 &&
            messageParsed?.screen === this.state.cameras[0]?.screen
        ) {
            let cameras = await getCamerasByScreen(this.props.screen._id);
            this.setState({ cameras: cameras.data });
        }
    };
    /**
     * It returns an object with two keys, one is the translation of the word "free" and the other is
     * the translation of the word "occupied".
     *
     * @return An object with two keys, one with the value of the free slots and the other with the
     * value of the occupied slots.
     */
    getRawDataOfSlots = () => {
        let screen = cloneDeep(this.props.screen);
        let contents = cloneDeep(this.props.content) || [];
        let dataFree = (
            100 -
            (contents.length / screen.total_spots) * 100
        ).toFixed(2);
        let dataOcuppied = 100 - dataFree;
        const { t } = this.props;
        const free = t("sections.dashboard.free");
        const occupied = t("sections.dashboard.occupied");
        if (dataFree < 0) {
            dataFree = 0;
        }
        if (dataOcuppied > 100) {
            dataOcuppied = 100;
        }
        return {
            [free]: dataFree,
            [occupied]: dataOcuppied,
        };
    };
    /**
     * It takes the data from the getRawDataOfSlots() function and puts it into the dataPieChart state.
     */
    getStatisticsPerScreen = () => {
        let rawData = this.getRawDataOfSlots();
        let dataPie = (canvas) => {
            return {
                labels: [Object.keys(rawData)[1], Object.keys(rawData)[0]],
                datasets: [
                    {
                        data: [
                            rawData[Object.keys(rawData)[1]],
                            rawData[Object.keys(rawData)[0]],
                        ],
                        backgroundColor: [
                            "rgb(205, 180, 219)",
                            "rgb(250, 250, 250)",
                        ],
                    },
                ],
            };
        };
        this.setState({ dataPieChart: dataPie });
    };
    /**
     * This function returns a paragraph element with a class name of text-center and the text of the
     * first key in the rawData object, followed by a percent sign, followed by a space, followed by
     * the first key in the rawData object.
     *
     * @return A React component.
     */
    getTextForScreenSlotTooltip = () => {
        let rawData = this.getRawDataOfSlots();
        return (
            <p className="text-center">
                {rawData[Object.keys(rawData)[0]]}% {Object.keys(rawData)[0]}
            </p>
        );
    };

    /**
     * "If the current time is between the start and end time of the day, then the screen is out of
     * date."
     *
     */
    checkScreenIsOutOfDate = () => {
        //el objeto schedule de screen empieza por lunes
        //en cambio el objeto Date de JS los días empiezan en domingo
        //por lo tanto al comparar, hay que agarrar un día menos de schedule
        let indexOfDay = new Date().getDay() - 1;

        if (indexOfDay === -1) indexOfDay = 6;

        const dayToCompare = this.props.screen.schedule[indexOfDay];
        const startSplitted = dayToCompare.start.split(":");
        const endSplitted = dayToCompare.end.split(":");

        const date = new Date();
        date.setHours(startSplitted[0]);
        date.setMinutes(startSplitted[1]);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const startTimestamp = date.getTime();

        date.setHours(endSplitted[0]);
        date.setMinutes(endSplitted[1]);
        const endTimestamp = date.getTime();

        const actualTimestamp = Date.now();
        let isOutOfDate = "";
        if (dayToCompare.start > dayToCompare.end) {
            isOutOfDate =
                actualTimestamp >= endTimestamp &&
                actualTimestamp < startTimestamp;
        } else {
            isOutOfDate =
                actualTimestamp < startTimestamp ||
                actualTimestamp >= endTimestamp;
        }
        this.setState({ isOutOfDate });
    };

    /**
     * If the user clicks on a screen, toggle the menu. If the user clicks anywhere else, hide the
     * menu.
     *
     * @param e the event object
     */
    checkClickOutside = (e) => {
        let screenID = 0;
        let type;
        if (
            e.target?.toString().includes("HTMLDocument") ||
            e.target?.getAttribute("data-target") === null
        ) {
            if (
                !e.target.parentNode ||
                e.target.parentNode?.toString().includes("HTMLDocument") ||
                e.target.parentNode?.getAttribute("data-target") === null
            ) {
                screenID = null;
                type = null;
            } else {
                screenID = e.target.parentNode.getAttribute("data-target");
                type = e.target.parentNode.getAttribute("data-type");
            }
        } else {
            screenID = e.target.getAttribute("data-target");
            type = e.target.getAttribute("data-type");
        }
        if (type === "screen" && screenID === this.props.screen._id) {
            this.setState({ isMenuVisible: !this.state.isMenuVisible });
        } else this.setState({ isMenuVisible: false });
    };
    componentWillUnmount() {
        clearTimeout(this.activeTimeOutID);
        clearInterval(this.activeIntervalID);
        clearInterval(this.screenOutOfDateIntervalID);
        document.removeEventListener("click", this.checkClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        const prevScreen = prevProps.screen;
        const actualScreen = this.props.screen;
        if (prevScreen._rev < actualScreen._rev) {
            if (
                (!prevScreen.electron_id || prevScreen.electron_id === "") &&
                actualScreen.electron_id !== ""
            )
                this.setActive(actualScreen);
            else this.setState({ active: false });
        }

        if (this.props.selected !== prevProps.selected) {
            let isSelected = this.props.screen._id === this.props.selected;
            this.setState({ isSelected: isSelected });
            this.setState({ isElementVisible: isSelected });
        }
        if (prevProps.content !== this.props.content) {
            this.getStatisticsPerScreen();
        }
    }

    /**
     * If the screen has an electron_id and the last_active time is less than 5 minutes ago, set the
     * active state to true.
     *
     * @param screen {
     */
    setActive = (screen) => {
        if (!screen.electron_id || screen.electron_id === "")
            this.setState({ active: false });
        else {
            const timestamp = Date.now();
            const fifteenMinutesInMS = 15 * 1000 * 60;
            this.setState({
                active:
                    timestamp - screen.last_active <
                    fifteenMinutesInMS + 10 * 1000,
            });
        }
    };

    /**
     * If the last_active property of the screen object is less than 5 minutes ago, wait until it's
     * been 5 minutes, then check the screen object every 5 minutes to see if it's active.
     *
     * @param screen the screen object that is being checked
     */
    checkActive = async (screen) => {
        let msToWait = Date.now() - screen.last_active + 10 * 1000;
        const fiveMinutesInMS = 5 * 1000 * 60;

        if (msToWait > fiveMinutesInMS) msToWait = 0;

        const timeOutID = setTimeout(async () => {
            let response = await getScreen(screen._id);

            if (response.status === 200) {
                let updatedScreen = response.data;
                this.setActive(updatedScreen);
                const msInterval = fiveMinutesInMS + 10 * 1000;

                const intervalID = setInterval(async () => {
                    const response = await getScreen(screen._id);
                    const updatedScreen = response.data;
                    this.setActive(updatedScreen);
                }, msInterval);

                this.activeIntervalID = intervalID;
            }
        }, msToWait);

        this.activeTimeOutID = timeOutID;
    };

    /**
     * If the difference between the current time and the last active time is less than a second,
     * return 'second ago', if it's less than a minute, return the difference in seconds, if it's less
     * than an hour, return the difference in minutes, if it's less than a day, return the difference
     * in hours, if it's less than a week, return the difference in days, otherwise return the
     * difference in weeks.
     *
     * @return A string.
     */
    dateFormmaterText = () => {
        let lastActive = this.props.screen.last_active;

        if (!lastActive) return;

        let now = Date.now();

        if ((now - lastActive) / 1000 === 1) {
            return this.props.t("sections.screens.screenItem.secondAgo");
        }
        if ((now - lastActive) / 1000 < 60) {
            return (
                ((now - lastActive) / 1000).toFixed(0) +
                this.props.t("sections.screens.screenItem.fewSecondsAgo")
            );
        }
        if ((now - lastActive) / 1000 / 60 === 1) {
            return this.props.t("sections.screens.screenItem.minuteAgo");
        }
        if ((now - lastActive) / 1000 / 60 < 60) {
            return (
                ((now - lastActive) / 1000 / 60).toFixed(0) +
                this.props.t("sections.screens.screenItem.fewMinutesAgo")
            );
        }
        if ((now - lastActive) / 1000 / 60 / 60 === 1) {
            return this.props.t("sections.screens.screenItem.hourAgo");
        }
        if ((now - lastActive) / 1000 / 60 / 60 < 24) {
            return (
                ((now - lastActive) / 1000 / 60 / 60).toFixed(0) +
                this.props.t("sections.screens.screenItem.fewHoursAgo")
            );
        }
        if ((now - lastActive) / 1000 / 60 / 60 / 24 === 1) {
            return this.props.t("sections.screens.screenItem.dayAgo");
        }
        return (
            ((now - lastActive) / 1000 / 60 / 60 / 24).toFixed(0) +
            this.props.t("sections.screens.screenItem.fewDaysAgo")
        );
    };
    /**
     * When the user clicks on the button, the text in the entry box will be copied to the clipboard.
     *
     * @param entryText The text you want to copy to the clipboard.
     */
    copyText = (entryText) => {
        navigator.clipboard.writeText(entryText);
    };

    /**
     * When the user clicks on the button, the function will change the screen to the screen that the
     * user is currently on and open the modal.
     */
    pairScreen = () => {
        this.props.changeScreenPair(this.props.screen);
        this.props.openModal("pairModal");
    };
    /**
     * When the user clicks the unpair button, the unpair modal will open and the screen will be
     * unpaired.
     */
    unpairScreen = () => {
        this.props.changeScreenPair(this.props.screen);
        this.props.openModal("unpairModal");
    };
    /**
     * When the user clicks the delete button, the screen is removed from the database and the screen
     * is removed from the screen list.
     */
    removeScreen = () => {
        let screen_id = this.props.screen._id;
        removeScreen(screen_id)
            .then((r) => {
                if (r.status === 200) this.setState({ isMenuVisible: false });
                removeScreenImages(this.props.screen.images);
                this.props.removeScreen(screen_id);
            })
            .catch((err) => console.log(err));
    };

    /**
     * Get the SSID from the screen and set it with the new value passed by parameter
     *
     * @param value the value of the dropdown
     */
    asignSSPCode = async (value) => {
        let screen = cloneDeep(this.props.screen);
        screen.SSPID = value;
        try {
            let response = await setSSPID(screen._id, screen.SSPID);
            if (response.status === 200) {
                screen._rev = response.data.rev;
                this.props.updateScreen(screen);
                this.props.showNotification({
                    type: "success",
                    text: this.props.t(
                        "common.notification.screens.SSPAssigned",
                    ),
                });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.screens.SSPAssignedFailed",
                ),
            });
        }
    };
    /**
     * This function deletes the SSPID from the screen object and then updates the screen object in the
     * database.
     */
    deleteSSPCode = async () => {
        let screen = cloneDeep(this.props.screen);
        delete screen.SSPID;
        try {
            let response = await setSSPID(screen._id, "");
            if (response.status === 200) {
                screen._rev = response.data.rev;
                this.props.updateScreen(screen);
                this.props.showNotification({
                    type: "success",
                    text: this.props.t(
                        "common.notification.screens.SSPRemoved",
                    ),
                });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.screens.SSPRemovedFailed",
                ),
            });
        }
    };

    /**
     * Open a modal that will show the available streaming of cameras
     */
    viewCamerasOfScreen = () => {
        let { screen } = this.props;
        this.props.viewCamerasOfScreen(screen._id, this.state.cameras);
    };

    render() {
        return (
            <>
                <div className="screen-wrapper relative rounded p-1 border-b-2 hover:bg-gray-100">
                    <div className="screen-header flex flex-col md:flex-row justify-between md:items-center cursor-pointer">
                        <div className="flex items-center w-full">
                            <input
                                type="checkbox"
                                className="mr-3 mt-1 rounded"
                                checked={this.props.screensToDelete.includes(
                                    this.props.screen._id,
                                )}
                                onClick={() => {
                                    this.props.handleAddIdOfScreen(
                                        this.props.screen._id,
                                    );
                                }}
                            />
                            <div
                                onClick={this.toggleElement}
                                alt={this.props.screen.name}
                                className={`md:w-4/5 text-sm font-semibold mr-3 truncate flex items-center content-center ${this.state.isSelected ? "text-gray-800" : "text-gray-600"}`}
                            >
                                <div
                                    className={`${this.state.active ? "bg-green-400" : "bg-red-400"} rounded-full flex-none w-3 h-3 mt-1 mr-2`}
                                ></div>
                                <div
                                    onClick={this.viewCamerasOfScreen}
                                    className={`rounded-full flex-none w-5 h-5 mr-2 ${this.state.cameras.length > 0 ? "" : "hidden"}`}
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "cctv"]}
                                        fixedWidth
                                        className="text-gray-400"
                                    />
                                </div>
                                <ReactTooltip
                                    className="p-2"
                                    id={"screenSlots" + this.props.screen._id}
                                    aria-haspopup="true"
                                >
                                    <p className="text-center">
                                        {this.getTextForScreenSlotTooltip()}
                                    </p>
                                </ReactTooltip>
                                <div
                                    data-class="shadow-lg"
                                    data-background-color="white"
                                    data-text-color="000000"
                                    data-for={
                                        "screenSlots" + this.props.screen._id
                                    }
                                    data-tip
                                    className="rounded-full flex-none w-5 h-5 mt-1 mr-2"
                                >
                                    {/* Falta levantar de manera individual el dato occupied/free */}
                                    <Doughnut
                                        width={"90%"}
                                        height={"90%"}
                                        data={this.state.dataPieChart}
                                        legend={false}
                                        options={{
                                            tooltips: {
                                                enabled: false,
                                            },
                                        }}
                                    />
                                </div>
                                <div
                                    className="w-full truncate"
                                    title={this.props.screen.name}
                                >
                                    {this.props.screen.name}
                                    {this.state.isOutOfDate && (
                                        <>
                                            <ReactTooltip
                                                effect="solid"
                                                className="p-2"
                                                id="outOfDateTooltip"
                                                aria-haspopup="true"
                                            >
                                                <p className="text-center">
                                                    {this.props.t(
                                                        "sections.screens.screenItem.outOfDate",
                                                    )}
                                                </p>
                                            </ReactTooltip>
                                            <FontAwesomeIcon
                                                data-tip
                                                data-for="outOfDateTooltip"
                                                icon={[
                                                    "fas",
                                                    "exclamation-triangle",
                                                ]}
                                                className="text-yellow-400 ml-2 text-sm"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            {!this.state.active && (
                                <span
                                    className="text-gray-500 text-xs items-center "
                                    title={this.dateFormmaterText()}
                                >
                                    <p className="items-center w-16 truncate pt-2">
                                        {this.dateFormmaterText()}
                                    </p>
                                </span>
                            )}
                            <div className="w-full md:w-2/5 flex justify-between md:justify-end content-center">
                                {this.props.screen.SSPID ? (
                                    <button
                                        className="flex items-center justify-center buttonSecondary-sm mr-6 truncate"
                                        onClick={() =>
                                            this.props.openModal(
                                                `removeSSPIDModal-${this.props.screen._id}`,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={["fad", "unlink"]}
                                            className="mr-1"
                                        />{" "}
                                        {this.props.screen.SSPID}
                                    </button>
                                ) : (
                                    <button
                                        className="flex items-center justify-center buttonPrimary-sm mr-6 truncate"
                                        onClick={() =>
                                            this.props.openModal(
                                                `SSPIDModal-${this.props.screen._id}`,
                                            )
                                        }
                                    >
                                        SSP ID
                                    </button>
                                )}
                                {this.props.screen.electron_id ? (
                                    <button
                                        className="flex items-center justify-center buttonSecondary-sm mr-6"
                                        onClick={this.unpairScreen}
                                    >
                                        <FontAwesomeIcon
                                            icon={["fad", "unlink"]}
                                            className="mr-1"
                                        />{" "}
                                        {this.props.t("common.buttons.unpair")}
                                    </button>
                                ) : (
                                    <button
                                        className="flex items-center justify-center buttonPrimary-sm mr-6"
                                        onClick={this.pairScreen}
                                    >
                                        {this.props.t("common.buttons.pair")}
                                    </button>
                                )}
                                <ReactTooltip
                                    id={`screenSizeTooltip-${this.props.screen._id}`}
                                    aria-haspopup="true"
                                >
                                    {this.props.screen.pixel_size_width}x
                                    {this.props.screen.pixel_size_height}
                                </ReactTooltip>
                                <span
                                    data-tip
                                    data-class="shadow-lg"
                                    data-background-color="white"
                                    data-text-color="000000"
                                    data-for={`screenSizeTooltip-${this.props.screen._id}`}
                                    className="w-3/12 truncate screen-size font-extralight text-gray-600 mr-3 text-xs transform scale-75 self-center text-right"
                                    title={
                                        this.props.screen.pixel_size_width +
                                        " x " +
                                        this.props.screen.pixel_size_height
                                    }
                                >
                                    {this.props.screen.pixel_size_width}x
                                    {this.props.screen.pixel_size_height}
                                </span>
                                <div className="w-1/12  flex screen-options pl-0.5 ml-2 text-right relative self-center justify-end">
                                    {/* Screen Menu */}
                                    <div
                                        data-toggle="toggleMenu"
                                        className={`absolute z-50 w-48 right-0  mr-6 p-2 px-6 text-xs bg-white rounded-md shadow divide-y divide-blue-200 ${this.state.isMenuVisible ? "" : "hidden"} ${this.props.isLastOne ? "mt-0  bottom-4" : "mt-6"}`}
                                    >
                                        <Link
                                            to={{
                                                pathname: "/wizard",
                                                state: {
                                                    data: {
                                                        screen: this.props
                                                            .screen._id,
                                                        action: "edit",
                                                    },
                                                },
                                            }}
                                        >
                                            <div className="text-left py-2 text-gray-600 hover:text-black">
                                                <FontAwesomeIcon
                                                    icon={["fal", "sliders-h"]}
                                                    className="mr-3"
                                                />{" "}
                                                {this.props.t(
                                                    "sections.screens.menu.screenItemSettings",
                                                )}
                                            </div>
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: "/wizard",
                                                state: {
                                                    data: {
                                                        screen: this.props
                                                            .screen._id,
                                                        action: "clone",
                                                    },
                                                },
                                            }}
                                        >
                                            <div className="text-left py-2 text-gray-600 hover:text-black">
                                                <FontAwesomeIcon
                                                    icon={["fal", "clone"]}
                                                    className="mr-3"
                                                />{" "}
                                                {this.props.t(
                                                    "sections.screens.menu.screenItemClone",
                                                )}
                                            </div>
                                        </Link>
                                        {/* <div className="text-left py-2 text-gray-600 hover:text-black"><FontAwesomeIcon icon={["fal", "pause"]} className="mr-3" /> {this.props.t('sections.screens.menu.screenItemPause')}</div> */}
                                        <div
                                            onClick={() =>
                                                this.props.openModal(
                                                    `deleteScreenModal-${this.props.screen._id}`,
                                                )
                                            }
                                            className="select-none text-left py-2 text-red-600 hover:text-black"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fal", "trash"]}
                                                className="mr-3"
                                            />
                                            {this.props.t(
                                                "sections.screens.menu.screenItemRemove",
                                            )}
                                        </div>
                                    </div>
                                    <FontAwesomeIcon
                                        data-type="screen"
                                        data-toggle="toggleMenuIcon"
                                        data-target={this.props.screen._id}
                                        icon={["fal", "cog"]}
                                        size="1x"
                                        className="text-blue-600 mr-2 cursor-pointer hover:animate-spin"
                                    />
                                    <FontAwesomeIcon
                                        onClick={this.toggleElement}
                                        icon={["fal", "chevron-left"]}
                                        className={`text-blue-400 text-sm transition-transform duration-300 ease-in-out transform ${this.state.isElementVisible ? "-rotate-90" : "rotate-0"}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`screen-description ${this.state.isElementVisible ? "" : "hidden"}`}
                    >
                        <span className="screen-address text-xs font-light text-gray-600">
                            {this.props.screen.location.address}
                        </span>
                    </div>
                    <div
                        className={`screen-description ${this.state.isElementVisible ? "" : "hidden"}`}
                    >
                        <FontAwesomeIcon
                            icon={["fas", "map-marker"]}
                            fixedWidth
                            className="text-xs text-gray-400"
                        />
                        <span className="screen-address text-xs font-mono transform scale-90 font-light text-gray-600">
                            {this.props.screen.location.coordinates.lat.toFixed(
                                6,
                            )}
                            ,
                            {this.props.screen.location.coordinates.lng.toFixed(
                                6,
                            )}
                        </span>
                        <FontAwesomeIcon
                            onClick={() =>
                                this.copyText(
                                    `${this.props.screen.location.coordinates.lat.toFixed(6)},${this.props.screen.location.coordinates.lng.toFixed(6)}`,
                                )
                            }
                            icon={["fad", "copy"]}
                            fixedWidth
                            className="text-xs cursor-pointer text-blue-400"
                        />
                    </div>
                    {this.state.isElementVisible && (
                        <ScreenPlaylist
                            isLastOne={this.props.isLastOne}
                            scrollToEnd={this.props.scrollToEnd}
                            getContentByScreenId={
                                this.props.getContentByScreenId
                            }
                            isResized={this.props.isResized}
                            screens={this.props.screens}
                            elementVisible={this.state.isElementVisible}
                            content={this.props.content}
                            removeContent={this.props.removeContent}
                            getAllContent={this.props.getAllContent}
                            openModal={this.props.openModal}
                            updateOrder={this.props.updateOrder}
                            updateContent={this.props.updateContent}
                            screen={this.props.screen}
                            screenEvents={this.props.events}
                            displayPlaylist={this.state.isElementVisible}
                            showNotification={this.props.showNotification}
                            updateContentCloned={this.props.updateContentCloned}
                        />
                    )}

                    <OneInputModal
                        modalID={`SSPIDModal-${this.props.screen._id}`}
                        title={this.props.t("sections.modal.sspid.title")}
                        placeHolderText={this.props.t(
                            "sections.modal.sspid.placeholder",
                        )}
                        buttonText={this.props.t("common.buttons.submit")}
                        callbackFunction={this.asignSSPCode}
                    ></OneInputModal>
                    <DecisionModal
                        modalID={`removeSSPIDModal-${this.props.screen._id}`}
                        text={this.props.t(
                            "sections.modal.removeSSPIDModal.text",
                        )}
                        callbackFunction={this.deleteSSPCode}
                    />
                    <DecisionModal
                        modalID={`deleteScreenModal-${this.props.screen._id}`}
                        buttonText={this.props.t("sections.content.confirm")}
                        text={this.props.t(
                            "sections.modal.deleteModal.warning",
                        )}
                        callbackFunction={this.removeScreen}
                    ></DecisionModal>
                </div>
            </>
        );
    }
}
export default withTranslation()(ScreenItem);
