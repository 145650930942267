import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ModalLayoutWOButtons = ({
    children,
    title,
    modalID,
    onclose = undefined,
}) => {
    function closeModal() {
        document.getElementById(modalID).classList.add("hidden");
        if (onclose) {
            onclose();
        }
    }
    return (
        <div
            id={modalID}
            className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
        >
            <div className=" modalContent modalCamera bg-white md:w-1/3 rounded-md  flex flex-col md:h-3/5 pb-2 ">
                <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                    <div className="title text-gray-600 text-2xl">{title}</div>
                    <div
                        className="button cursor-pointer"
                        onClick={() => closeModal(modalID)}
                    >
                        <FontAwesomeIcon icon={["fal", "times"]} />
                    </div>
                </div>
                <div className="modal-content relative h-full flex flex-col justify-center bg-bg-yellow-300 overflow-y-hidden">
                    <div class="absolute top-0 left-0 w-full h-3 bg-gradient-to-b from-white to-transparent pointer-events-none z-10"></div>
                    {children}
                    <div class="absolute bottom-0 left-0 w-full h-7 bg-gradient-to-t from-white to-transparent pointer-events-none z-10"></div>
                </div>
            </div>
        </div>
    );
};

export default ModalLayoutWOButtons;
