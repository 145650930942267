import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const instance_axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

export const getUser = () => {
    return instance_axios
        .get("/generic/user", {
            withCredentials: true,
            headers: { common: headers },
        })
        .then((user) => user)
        .catch((err) => err.request);
};
export const postUser = (user) => {
    return instance_axios
        .post("/generic", user, {
            withCredentials: true,
            headers: { common: headers },
        })
        .then((res) => res)
        .catch((err) => err.request);
};

export const changePassword = (newPassword, oldPassword, userID) => {
    return instance_axios
        .post(
            "/user/changepassword",
            { newPassword, oldPassword, userID },
            {
                withCredentials: true,
                headers: { common: headers },
            },
        )
        .then((res) => res)
        .catch((err) => err.request);
};
