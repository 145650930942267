import React, { Component } from "react";
import { forgot_password } from "./UserFunctions";
import { Link } from "react-router-dom";
// import { ReactComponent as LogoWhite } from '../../images/logo-white.svg';
// import { ReactComponent as LogoColor } from '../../images/logo-color.svg';
import { withTranslation } from "react-i18next";

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    onSubmit(e) {
        e.preventDefault();
        const email = this.state.email;
        forgot_password(email).then((res) => {
            if (res.status === 200) {
                this.props.showNotification({
                    type: "success",
                    text: this.props.t(
                        "common.notification.forgotPassword.success",
                    ),
                    duration: 3000,
                });
            } else if (res.status === 404) {
                this.props.showNotification({
                    type: "warning",
                    text: this.props.t(
                        "common.notification.forgotPassword.warning",
                    ),
                    duration: 3000,
                });
            } else {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t(
                        "common.notification.forgotPassword.error",
                    ),
                    duration: 3000,
                });
            }
        });
    }

    render() {
        return (
            <div className="w-full h-screen flex justify-between">
                <div className="relative hidden md:block w-4/6 h-full text-white p-4 bg-cover bg-center bg-gradient-to-r from-green-400 to-blue-500">
                    <div className="logo overflow-hidden">
                        {/* <LogoWhite className="h-10 object-left-top" /> */}
                        <img
                            className="h-10 object-left-top"
                            src={require(
                                `../../images/${process.env.REACT_APP_WL_NAME}/logo-white.svg`,
                            )}
                            alt="Taggify Simple Signage"
                        />
                    </div>
                    <div className="logo-product"></div>
                    <div className="absolute z-10 bottom-0 right-0 text-right p-4 font-light">
                        Simple
                        <span className="text-lg font-bold subpixel-antialiased">
                            Signage
                        </span>
                        <br />
                        {this.props.t("sections.login.slogan")}
                    </div>
                </div>
                <div className="relative w-full md:w-2/6 h-full text-black p-4 flex flex-col">
                    <div className="logo block md:hidden">
                        {/* <LogoColor /> */}
                        <img
                            src={require(
                                `../../images/${process.env.REACT_APP_WL_NAME}/logo-color.svg`,
                            )}
                            alt="Taggify Simple Signage"
                        />
                    </div>
                    <div className="flex justify-end md:hidden content-center mt-2">
                        <div className="inline font-bold mt-3 ml-2 text-gray-700">
                            <span className="font-extralight">Simple</span>
                            Signage
                        </div>
                        <div className="align-bottom font-extralight text-gray-400 text-xs mt-4 ml-4">
                            by Taggify
                        </div>
                    </div>
                    <div className="mt-5 p-0 md:p-8 h-full font-light">
                        <div className="text-gray-500 md:text-3xl font-extralight">
                            {this.props.t("sections.forgotPassword.mainTitle")}
                        </div>
                        <p
                            id="p_error"
                            className="hidden absolute right-0 top-0 mr-2 mt-2 text-red-500 text-sm bg-gray-200 p-3 rounded"
                        >
                            {" "}
                            {this.state.err_message}{" "}
                        </p>
                        <form
                            noValidate
                            onSubmit={this.onSubmit}
                            className="pt-6 pb-8 mb-4"
                        >
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="email"
                                >
                                    {this.props.t(
                                        "sections.forgotPassword.email-label",
                                    )}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                    id="email"
                                    type="text"
                                    placeholder={this.props.t(
                                        "sections.forgotPassword.email-placeholder",
                                    )}
                                    value={this.state.email}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button type="submit" className="buttonPrimary">
                                    {this.props.t(
                                        "sections.forgotPassword.sendEmail",
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="absolute bottom-0 text-center text-xs pb-4 text-gray-600">
                        {this.props.t("sections.login.wantToUse")}{" "}
                        <Link
                            className="font-medium text-blue-600 hover:text-indigo-500"
                            to="/register"
                        >
                            {" "}
                            {this.props.t("common.buttons.signup")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ForgotPassword);
