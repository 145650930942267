import React from "react";

const CardDOOH = (p) => {
    const imageExtensions = ["png", "jpg", "jpeg", "gif"];
    const videoExtensions = ["mp4", "ogg", "webm"];
    const zipExtensions = ["zip", "rar", "7z"];
    const { props } = p;
    let { title, onClick, issueID } = props;
    let { path, schedule } = props.content;

    return (
        <>
            <div className="" onClick={onClick}>
                <div className="flex flex-col">
                    <div className="w-full flex justify-between uppercase">
                        <span className="text-xs">
                            <span>DOOH </span> -{issueID}
                        </span>
                        <span className="text-xs">
                            <span>{schedule.startDate}</span>
                        </span>
                    </div>
                    <div className="w-auto">
                        <p className=" w-auto font-bold tracking-tight normal-case  overflow-hidden text-ellipsis">
                            {title}
                        </p>
                    </div>
                </div>
                <div>
                    {path && (
                        <div>
                            {imageExtensions.includes(path.split(".")[1]) && (
                                <>
                                    {" "}
                                    <img
                                        data-tip
                                        className="rounded"
                                        src={`${process.env.REACT_APP_FILESERVER_URL}/${path}`}
                                        alt={path}
                                    />
                                </>
                            )}
                            {videoExtensions.includes(path.split(".")[1]) && (
                                <>
                                    <video
                                        controls
                                        src={`${process.env.REACT_APP_FILESERVER_URL}/${path}`}
                                        alt={path}
                                        className="object-contain rounded-lg m-auto custom-max-h-90"
                                    ></video>
                                </>
                            )}
                            {zipExtensions.includes(props.content.ext) && (
                                <>
                                    <img
                                        src={require(
                                            `../../../images/common/html.png`,
                                        )}
                                        alt={path}
                                        className="object-contain rounded-lg w-full max-h-80"
                                    />
                                    <br />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default CardDOOH;
