import moment from "moment-timezone";
/**
 * @returns {Boolean} true if the platform is in maintenance
 */
export async function isInMaintenance() {
    let from = process.env.REACT_APP_MAINTENANCE_FROM;
    let to = process.env.REACT_APP_MAINTENANCE_TO;
    if (
        (from === null || from === undefined || from === "") &&
        (to === null || to === undefined || to === "")
    )
        return false;
    from = moment.tz(from, "Etc/UTC");
    to = moment.tz(to, "Etc/UTC");
    let currentTime = await fetch(
        "https://worldtimeapi.org/api/timezone/Etc/UTC",
    )
        .then((response) => response.json())
        .then((data) => data.datetime);
    currentTime = moment.tz(currentTime, "Etc/UTC");
    return currentTime.isBetween(from, to);
}
