import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/modals.css";
import currency from "../../util_files/currency.json";

class EditComScreenModal extends React.Component {
    returnValue = (e) => {
        let ad_value, currency;
        let inputs = document.querySelector(".inputModal");
        let selects = document.getElementById("currency");
        if (inputs.id === "ad_value" && inputs.value !== "") {
            ad_value = inputs.value;
        } else {
            ad_value = this.props.values.ad_value;
        }
        if (selects.value !== "-1") {
            currency = selects.value;
        } else {
            currency = this.props.values.currency;
        }
        let obj = {
            _id: this.props.values.id,
            _rev: this.props.values.rev,
            name: this.props.values.name,
            ad_value: ad_value,
            currency: currency,
        };
        inputs.value = "";
        selects.value = "-1";
        this.props.callbackFunction(obj);
        this.closeModal();
    };

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };

    render() {
        let defaultCurrency = this.props.values.currency;
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent modal-sm bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-xl">
                            <span className="font-semibold">
                                {this.props.t(
                                    "sections.management.section.commercial.pricing.editTitle",
                                )}
                                :
                            </span>{" "}
                            {this.props.values.name}
                        </div>
                        <div
                            className="button cursor-pointer"
                            onClick={this.closeModal}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <div className="h-auto py-4 px-2 text-gray-500">
                        <div className="block items-center">
                            <div className="w-full mb-4">
                                {/* switcher value per impression | second */}
                            </div>
                            <div className="flex">
                                <div className="w-2/12">
                                    <select
                                        tabIndex="2"
                                        id="currency"
                                        className="w-full focus:ring-0 focus:outline-none py-2 pl-2 pr-10 border-none bg-transparent text-gray-500 sm:text-sm border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer"
                                    >
                                        <option value="-1">
                                            {this.props.t(
                                                "sections.management.section.commercial.pricing.editCurrency",
                                            )}
                                        </option>
                                        {currency.map((curr, i) =>
                                            defaultCurrency === curr.ISO ? (
                                                <option
                                                    value={curr.ISO}
                                                    key={i}
                                                    selected
                                                >
                                                    {curr.symbol} {curr.ISO} -{" "}
                                                    {curr.name}
                                                </option>
                                            ) : (
                                                <option
                                                    value={curr.ISO}
                                                    key={i}
                                                >
                                                    {curr.symbol} {curr.ISO} -{" "}
                                                    {curr.name}
                                                </option>
                                            ),
                                        )}
                                    </select>
                                </div>
                                <div className="w-10/12">
                                    <input
                                        id="ad_value"
                                        className="w-full inputModal block shadow appearance-none border rounded-sm py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                        placeholder={this.props.values.ad_value}
                                    />
                                </div>
                            </div>
                            {/* 
                                <label className="labelModal text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.management.section.commercial.pricing.priceTable.adValue')}</label>
                                <label className="labelModal text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.management.section.commercial.pricing.priceTable.currency')}</label> 
                            */}
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="buttonPrimary"
                            onClick={this.returnValue}
                        >
                            {this.props.t("common.buttons.submit")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EditComScreenModal);
