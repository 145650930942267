import React from "react";

const AddCardLinkComponent = ({ openModal, laneId }) => {
    return (
        // The component is empty because if it is deleted, the library component is positioned by default and it is not being used
        <></>
    );
};

export default AddCardLinkComponent;
