import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ErrorMessage = ({ message }) => {
    return (
        <div className="flex flex-col h-full justify-center items-center">
            <FontAwesomeIcon
                icon={["far", "wifi-slash"]}
                className="text-red-400 text-4xl"
            />
            <br />
            {message}
        </div>
    );
};

export default ErrorMessage;
