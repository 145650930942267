import React from "react";
import { NavLink } from "react-router-dom";
//import iso from '../../images/iso-blue.svg';
const Logo = (props) => {
    return (
        <div
            className={` ${props.isHoverMenu ? "opacity-100 block" : "opacity-0 hidden"} transition-opacity duration-300 ease-in-out mt-2 cursor-pointer`}
        >
            <NavLink to="/" className="flex content-center">
                <img
                    src={require(
                        `../../images/${process.env.REACT_APP_WL_NAME}/iso-blue.svg`,
                    )}
                    className="w-8 pl-1 ml-3"
                    alt={process.env.REACT_APP_WL_NAME}
                />
                <div className="inline font-bold mt-3 ml-2 text-gray-700">
                    <span className="font-extralight">Simple</span>Signage
                </div>
            </NavLink>
        </div>
    );
};
export { Logo };
