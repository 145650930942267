import React from "react";
//Components
import Menu from "./Menu";
import { Logo } from "./Logo";
const ContainerMenu = (props) => {
    const [isHoverMenu, setHoverMenu] = React.useState(false);
    const [isMenuOpen, setMenuOpen] = React.useState(false);

    const setHoverMenuIfMenuIsClosed = (value) => {
        if (!isMenuOpen) {
            setHoverMenu(value);
        }
    };
    return (
        <div
            onMouseLeave={() => {
                setHoverMenuIfMenuIsClosed(false);
            }}
            onMouseEnter={() => {
                setHoverMenuIfMenuIsClosed(true);
            }}
            className={`${isHoverMenu ? "shadow-xl w-64" : ""} fixed bg-white z-30 top-0 left-0 h-screen w-16 transition-all duration-75 ease-in-out group`}
        >
            {!isMenuOpen && (
                <img
                    className="w-8 pl-1 ml-3 mt-3.5 opacity-25 group-hover:hidden cursor-pointer"
                    src={require(
                        `../../images/${process.env.REACT_APP_WL_NAME}/iso-blue.svg`,
                    )}
                    alt="Taggify"
                />
            )}
            <Logo isHoverMenu={isHoverMenu} />
            <Menu
                isProfileOpen={props.isProfileOpen}
                handleModalsOpen={props.handleModalsOpen}
                toggleHelp={props.toggleHelp}
                logOut={props.logOut}
                isHoverMenu={isHoverMenu}
                setHoverMenu={setHoverMenu}
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
            />
        </div>
    );
};
export { ContainerMenu };
