import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class ManagementHeader extends Component {
    render() {
        return (
            <>
                <div className="relative border-b-2 border-gray-200 py-2 flex flex-row justify-between align-center">
                    {/* titulo */}
                    <div>
                        <span className="font-extralight text-center align-middle">
                            {this.props.t("sections.management.mainTitle")}
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ManagementHeader);
