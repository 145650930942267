import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Step1 from "../wizard/Step1";
import Step2 from "../wizard/Step2";
import Step3 from "../wizard/Step3";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import DecisionModal from "../../utils/DecisionModal";
import ExtraButtonsMenu from "../../utils/ExtraButtonsMenu";

class EventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            event: {},
            edit: false,
            dateWarning: false,
        };
    }
    componentDidMount() {
        const { eventPicked } = this.props;
        if (eventPicked) {
            this.setState({
                event: cloneDeep(eventPicked),
                dateWarning: false,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.eventPicked, this.props.eventPicked)) {
            this.setState({
                event: cloneDeep(this.props.eventPicked),
            });
        }
    }
    closeModal = () => {
        this.setState(
            {
                dateWarning: false,
                event: {},
            },
            () => {
                this.props.closeModal(this.props.modalID);
                this.setState({
                    currentStep: 1,
                });
            },
        );
    };
    /**
     * Function that trigger a callback function and close the wizard modal
     * @param {*} event
     */
    callFunctionAndClose = (event) => {
        this.props.closeModal(this.props.modalID);
        this.props.functionToCall(event);
        this.setState({
            currentStep: 1,
        });
    };
    /**
     * Function that open the confirmation modal to delete an event
     */
    handleDelete = () => {
        this.props.openModal(`deleteEventModal`);
    };
    /**
     * Function that swap the exclusivity of an event and saves it in state
     * @param {} event
     */
    handleExclusive = (event) => {
        event.isExclusive = !event.isExclusive;
        if (event.impression) {
            delete event.impression;
        }
        this.setState({
            event: event,
        });
    };
    /**
     * Function that handles the change of an input, creating a property inside the event object
     * The name of that prop will be defined by the id of the input
     * @param {} event refers to the modified input
     */
    handleFieldChange = (field, value, onlyNeedNumbers = false) => {
        let editedEvent = cloneDeep(this.state.event);
        let regEx = /([a-zA-Z])/;
        if (onlyNeedNumbers) {
            if (!regEx.test(value)) {
                editedEvent[field] = value;
                this.setState({
                    event: editedEvent,
                });
            } else {
                this.props.showNotification({
                    type: "warning",
                    text: this.props.t(
                        "common.notification.calendar.inputOnlyAcceptNumbers",
                    ),
                });
            }
        } else {
            editedEvent[field] = value;
            this.setState({
                event: editedEvent,
            });
        }
    };
    /**
     * Function thar set the confirmed event property in true
     */
    functionToConfirm = () => {
        let event = cloneDeep(this.state.event);
        event.confirmed = true;
        this.setState(
            {
                event: event,
            },
            () => {
                this.callFunctionAndClose(event);
            },
        );
    };
    /**
     * Function that returns the current step from the create/edit event wizard
     * @param {number} step
     * @returns React.Component
     */
    renderStep = (step) => {
        switch (step) {
            case 1:
                return (
                    <Step1
                        isEventClicked={this.props.isEventClicked}
                        event={cloneDeep(this.state.event)}
                        datePicked={this.props.datePicked}
                        showNotification={this.props.showNotification}
                        functionToDelete={this.handleDelete}
                        callFunctionAndClose={this.callFunctionAndClose}
                        closeModal={this.closeModal}
                        nextStep={this.nextStep}
                        handleInputChange={this.handleFieldChange}
                        handleRepetitiveDayChange={
                            this.handleRepetitiveDayChange
                        }
                    />
                );

            case 2:
                return (
                    <Step2
                        isEventClicked={this.props.isEventClicked}
                        event={cloneDeep(this.state.event)}
                        screens={this.props.screens}
                        screenGroups={this.props.screenGroups}
                        brands={this.props.brands}
                        customers={this.props.customers}
                        contents={this.props.content}
                        showNotification={this.props.showNotification}
                        handleInputChange={this.handleFieldChange}
                        functionToDelete={this.handleDelete}
                        callFunctionAndClose={this.callFunctionAndClose}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                    />
                );
            case 3:
                return (
                    <Step3
                        isEventClicked={this.props.isEventClicked}
                        event={cloneDeep(this.state.event)}
                        showNotification={this.props.showNotification}
                        handleInputChange={this.handleFieldChange}
                        prevStep={this.prevStep}
                        functionToDelete={this.handleDelete}
                        handleExclusive={this.handleExclusive}
                        callFunctionAndClose={this.callFunctionAndClose}
                    />
                );
            default:
                return null;
        }
    };
    /**
     * Function that returns a String that represents the title of the create/edit event wizard
     * @param {number} step
     * @returns String
     */
    renderTitle = (step) => {
        switch (step) {
            case 1:
                return this.props.t("sections.calendar.wizard.step1.title");
            case 2:
                return this.props.t("sections.calendar.wizard.step2.title");
            case 3:
                return this.props.t("sections.calendar.wizard.step3.title");
            default:
                return null;
        }
    };
    /**
     * Function that sets in state the previous step
     */
    prevStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1,
        });
    };
    /**
     * Function that sets the actual event object and the next step in state
     */
    nextStep = (event) => {
        this.setState(
            {
                event: event,
            },
            () => {
                this.setState({
                    currentStep: this.state.currentStep + 1,
                });
            },
        );
    };
    /* A function that is called when a user clicks on a day of the week. It is used to set the
    repetitive days of an event. */
    handleRepetitiveDayChange = (weekdayNumber, event) => {
        if (event.daysOfWeek) {
            if (!event.daysOfWeek.includes(weekdayNumber)) {
                event.daysOfWeek.push(weekdayNumber);
            } else {
                event.daysOfWeek = event.daysOfWeek.filter(
                    (day) => day !== weekdayNumber,
                );
            }
        } else {
            event.daysOfWeek = [weekdayNumber];
        }
        this.setState({ event });
    };
    handleCloneEvent = () => {
        let event = cloneDeep(this.state.event);
        this.props.handleCloneEvent(event);
    };
    getButtonList = () => {
        let buttonList = [
            {
                title: this.props.t(
                    "sections.calendar.wizard.extraButtons.clone",
                ),
                view:
                    this.props.isEventClicked &&
                    this.props.eventAction === "edit",
                function: this.handleCloneEvent,
                icon: (
                    <FontAwesomeIcon icon={["fal", "clone"]} className="mr-3" />
                ),
                class: " text-gray-500",
            },
            {
                title: this.props.t(
                    "sections.calendar.wizard.extraButtons.delete",
                ),
                view: this.props.isEventClicked,
                function: this.handleDelete,
                icon: (
                    <FontAwesomeIcon icon={["fal", "trash"]} className="mr-3" />
                ),
                class: "text-red-500",
            },
            {
                title: this.props.t(
                    "sections.calendar.wizard.extraButtons.confirm",
                ),
                view: this.props.isEventClicked && !this.state.event.confirmed,
                function: this.functionToConfirm,
                icon: (
                    <FontAwesomeIcon icon={["fal", "check"]} className="mr-3" />
                ),
                class: "text-green-500",
            },
        ];
        return buttonList;
    };
    render() {
        const { modalID, text } = this.props;
        return (
            <div
                id={modalID}
                className="modal fixed overflow-auto bg-white z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent bg-gray-100 rounded-md p-5 flex flex-col h-fit modalWidth modal-sm">
                    <div className="relative">
                        <div className="modal-header relative  font-extralight text-lg flex justify-between px-3 mt-1">
                            <div
                                className={
                                    this.props.isEventClicked ? "" : "hidden"
                                }
                            >
                                <ExtraButtonsMenu
                                    id={modalID}
                                    buttons={this.getButtonList()}
                                ></ExtraButtonsMenu>
                            </div>
                            <div className="title text-gray-600 text-xl">
                                {text} -{" "}
                                {this.renderTitle(this.state.currentStep)}
                            </div>
                            {/* <label className="block uppercase   tracking-wide text-gray-600 text-xs font-bold pb-2  mt-1 border-b-2  w-full" > {this.props.t('sections.calendar.wizard.eventInformation')}</label> */}
                            <div
                                className="button cursor-pointer mr-4"
                                onClick={this.closeModal}
                            >
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </div>
                        </div>
                        <div className="modal-body flex justify-center px-3 mt-2">
                            {this.renderStep(this.state.currentStep)}
                        </div>
                    </div>
                </div>
                <DecisionModal
                    modalID={`deleteEventModal`}
                    buttonText={this.props.t("sections.content.confirm")}
                    text={this.props.t("sections.modal.deleteModal.warning")}
                    callbackFunction={() => {
                        this.closeModal();
                        this.props.functionToDelete();
                    }}
                />
            </div>
        );
    }
}
export default withTranslation()(EventModal);
