import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import { orderByProp } from "./commonFunctions";

import "../utils/modals.css";
class ListedEventModal extends Component {
    constructor() {
        super();
        this.state = {
            isReOrdening: false,
            contentOfEvent: [],
        };
    }
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };
    componentDidMount = () => {
        if (this.props.contents) {
            let filteredContent = this.props.getEventContents(
                this.props.event._id,
                [this.props.event],
                this.props.contents,
            );
            this.setState({
                contentOfEvent: orderByProp(
                    filteredContent,
                    "event_order",
                    this.props.screen._id,
                ),
            });
        }
        if (this.props.isReOrdening) {
            this.setState({ isReOrdening: true });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.isReOrdening !== prevProps.isReOrdening) {
            this.setState({ isReOrdening: this.props.isOrdening });
        }
    };
    /* A function that is called when the user drags and drops an item inside the event playlist.
    It is used to update the order of the items in the list. */
    handleDragEndModal = (result) => {
        if (this.state.isReOrdening) return;
        if (!result.destination) return;
        let items = cloneDeep(this.state.contentOfEvent);
        let [reOrderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reOrderedItem);
        let updatedContent = this.changeOrderContent(items);
        this.setState({ contentOfEvent: updatedContent }, () => {});
        this.props.updateOrder(cloneDeep(updatedContent), true);
        return;
    };
    /* A function that is used to update the order of the items in the playlist of event. */
    changeOrderContent = (items = []) => {
        items.forEach((i, index) => {
            let obj = {
                index,
                screen_id: this.props.screen._id,
                event_id: this.props.event._id,
            };
            if (!i.event_order || i.event_order.length === 0) {
                i.event_order = [];
                i.event_order.push(obj);
            } else {
                i.event_order.forEach((order) => {
                    if (order.screen_id === this.props.screen._id) {
                        order.index = obj.index;
                    }
                });
            }
        });
        return items;
    };

    render() {
        const t = this.props.t;
        const EVENT = this.props.event;
        const imageExtensions = ["png", "jpg", "jpeg", "gif"];
        const videoExtensions = ["mp4", "ogg", "webm"];
        const htmlExtensions = ["html", "zip"];
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent w-2/4 bg-gray-100 rounded-md p-5 flex flex-col h-2/3 ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3">
                        <div className="block uppercase   tracking-wide text-gray-600 text-xs font-bold pb-2  mt-1 border-b-2  w-full">
                            {EVENT.title}
                        </div>
                        <div
                            className="button cursor-pointer"
                            onClick={this.closeModal}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <div className="flex flex-col h-5/6 py-2 px-2 rounded text-gray-500">
                        <div className="flex justify-start">
                            <div className=" flex mr-3 my-1">
                                <FontAwesomeIcon
                                    icon={["fad", "calendar"]}
                                    className="mt-1 mr-1 text-blue-300"
                                />
                                <span className=" font-bold upercase mr-1">
                                    {t("sections.screens.modals.from")}:{" "}
                                </span>
                                <label className="  ">
                                    {EVENT.start.split("T")[0]}
                                </label>
                            </div>
                            <div className=" flex mr-3 my-1">
                                <span className="font-bold upercase mr-1">
                                    {t("sections.screens.modals.to")}:{" "}
                                </span>
                                <label className=" ">
                                    {EVENT.end.split("T")[0]}
                                </label>
                            </div>
                        </div>
                        <div className="w-1/9 flex  my-1">
                            <FontAwesomeIcon
                                icon={["fad", "stopwatch"]}
                                className="mt-1 mr-1 text-blue-300"
                            />
                            <span className="font-bold upercase mr-1 ">
                                {t("sections.screens.modals.time")}:{" "}
                            </span>
                            <p className=" ">
                                {EVENT.hourstart}hs - {EVENT.hourend}hs
                            </p>
                        </div>
                        <div className=" flex justify-between my-1 border-b-2">
                            <div className="flex">
                                <FontAwesomeIcon
                                    icon={["fad", "clipboard-list"]}
                                    className="mt-1  mr-1 text-blue-300"
                                />
                                <p className="font-bold upercase mr-1  mb-1 ">
                                    {t("sections.screens.modals.content")}:
                                </p>
                            </div>
                            <Link
                                to={{
                                    pathname: "/scheduler",
                                    state: {
                                        data: {
                                            event: EVENT._id,
                                        },
                                    },
                                }}
                                className="flex flex-row-reverse"
                            >
                                <div className="text-left  text-gray-600 ml-auto hover:text-black font-bold">
                                    <FontAwesomeIcon
                                        icon={["fal", "sliders-h"]}
                                        className="mr-3 "
                                    />
                                    {this.props.t("common.buttons.edit")}
                                </div>
                            </Link>
                        </div>
                        <DragDropContext onDragEnd={this.handleDragEndModal}>
                            <Droppable droppableId="screenPlaylist">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="overflow-y-auto overflow-x-hidden "
                                    >
                                        {this.state.contentOfEvent.map(
                                            (content, index) => (
                                                <Draggable
                                                    key={content._id}
                                                    draggableId={content._id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            className="flex align-middle   hover:bg-gray-200  rounded-lg "
                                                        >
                                                            {imageExtensions.includes(
                                                                content.ext,
                                                            ) && (
                                                                <div className=" rounded ListaEventosModal my-auto">
                                                                    <img
                                                                        src={`${process.env.REACT_APP_FILESERVER_URL}/${content.path}`}
                                                                        alt={
                                                                            content.name
                                                                        }
                                                                        className=""
                                                                    />
                                                                </div>
                                                            )}
                                                            {videoExtensions.includes(
                                                                content.ext,
                                                            ) && (
                                                                <div className=" rounded ListaEventosModal my-auto">
                                                                    <video
                                                                        src={`${process.env.REACT_APP_FILESERVER_URL}/${content.path}`}
                                                                        alt={
                                                                            content.name
                                                                        }
                                                                        className=" "
                                                                    ></video>
                                                                </div>
                                                            )}
                                                            {htmlExtensions.includes(
                                                                content.ext,
                                                            ) && (
                                                                <div className=" rounded ListaEventosModal my-auto">
                                                                    <img
                                                                        src={require("../../images/common/html.png")}
                                                                        alt={
                                                                            content.name
                                                                        }
                                                                        className=""
                                                                    />
                                                                </div>
                                                            )}
                                                            <div className="playlist-item-description mr-2 md:w-5/6 flex flex-col items-start content-center">
                                                                <div className="ml-2 containerContentSpan font-bold">
                                                                    <ReactTooltip
                                                                        effect="solid"
                                                                        className="w-72 p-2"
                                                                        id={`eventContentFullName${content._id}`}
                                                                        aria-haspopup="true"
                                                                    >
                                                                        <p className="text-center">
                                                                            {
                                                                                content.name
                                                                            }
                                                                        </p>
                                                                    </ReactTooltip>
                                                                    {!htmlExtensions.includes(
                                                                        content.ext,
                                                                    ) ? (
                                                                        <>
                                                                            <p
                                                                                className=" max-w-xs truncate"
                                                                                data-tip
                                                                                data-for={`eventContentFullName${content._id}`}
                                                                            >
                                                                                {
                                                                                    content.name
                                                                                }
                                                                            </p>
                                                                            <span className="playlist-item-size font-extralight text-gray-600 ml-1">
                                                                                {" "}
                                                                                {
                                                                                    content.width
                                                                                }
                                                                                x
                                                                                {
                                                                                    content.height
                                                                                }
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <p
                                                                                className=" max-w-xs truncate"
                                                                                data-tip
                                                                                data-for={`eventContentFullName${content._id}`}
                                                                            >
                                                                                {
                                                                                    content.name
                                                                                }
                                                                            </p>
                                                                            <span className="playlist-item-size font-extralight text-gray-600 ml-1">
                                                                                {t(
                                                                                    "sections.screens.screenPlaylist.htmlFile",
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                    <div className="flex">
                                                                        {videoExtensions.includes(
                                                                            content.ext,
                                                                        ) ? (
                                                                            <span className="playlist-item-duration font-extralight text-gray-600 mr-3">
                                                                                <FontAwesomeIcon
                                                                                    icon={[
                                                                                        "fal",
                                                                                        "stopwatch",
                                                                                    ]}
                                                                                    fixedWidth
                                                                                    className="text-blue-600 mr-2 text-sm"
                                                                                />
                                                                                {content.duration?.toFixed(
                                                                                    2,
                                                                                )}
                                                                                "
                                                                            </span>
                                                                        ) : (
                                                                            <span className="playlist-item-duration font-extralight text-gray-600 mr-3">
                                                                                <FontAwesomeIcon
                                                                                    icon={[
                                                                                        "fal",
                                                                                        "stopwatch",
                                                                                    ]}
                                                                                    fixedWidth
                                                                                    className="text-blue-600 mr-2 text-sm"
                                                                                />
                                                                                {
                                                                                    this
                                                                                        .props
                                                                                        .screen
                                                                                        .ad_duration
                                                                                }
                                                                                "
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <span className="font-light text-xs">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                content.downloaded.includes(
                                                                                    this
                                                                                        .props
                                                                                        .screen
                                                                                        ._id,
                                                                                )
                                                                                    ? [
                                                                                          "fad",
                                                                                          "check-double",
                                                                                      ]
                                                                                    : [
                                                                                          "fad",
                                                                                          "hourglass-half",
                                                                                      ]
                                                                            }
                                                                            fixedWidth
                                                                            className="text-blue-300 mr-2"
                                                                        />
                                                                        {content.downloaded.includes(
                                                                            this
                                                                                .props
                                                                                .screen
                                                                                ._id,
                                                                        )
                                                                            ? t(
                                                                                  "sections.screens.screenPlaylist.downloaded",
                                                                              )
                                                                            : t(
                                                                                  "sections.screens.screenPlaylist.toSync",
                                                                              )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ),
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ListedEventModal);
