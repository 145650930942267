import React, { Component } from "react";
class CustomContentOption extends Component {
    render() {
        const { content, props } = this.props;
        const imageExtensions = ["png", "jpg", "jpeg", "gif"];
        const videoExtensions = ["mp4", "ogg", "webm"];
        return (
            <div className="flex flex-row">
                {imageExtensions.includes(content[0].ext) && (
                    <img
                        src={`${process.env.REACT_APP_FILESERVER_URL}/${content[0].path}`}
                        alt={content[0].name}
                        className="object-cover rounded-lg w-1/6"
                    />
                )}
                {videoExtensions.includes(content[0].ext) && (
                    <video
                        src={`${process.env.REACT_APP_FILESERVER_URL}/${content[0].path}`}
                        alt={content[0].name}
                        className="object-cover rounded-lg w-1/6"
                    ></video>
                )}
                {content[0].ext === "html" && (
                    <iframe
                        title="html-iframe"
                        className="object-cover rounded-lg w-1/6"
                        src={`${process.env.REACT_APP_FILESERVER_URL}/${content[0].path}`}
                    ></iframe>
                )}
                <p className="ml-2 my-auto">{props.children}</p>
            </div>
        );
    }
}
export default CustomContentOption;
