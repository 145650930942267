// Basics
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./locale/i18n";
import "./tailwind.output.css";
import * as serviceWorker from "./serviceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { isInMaintenance } from "./components/utils/common/isInMaintenance";
import Maintenance from "./components/maintenance";
import App from "./App";

library.add(fab, fal, fad, fas, far);
async function render() {
    ReactDOM.render(
        (await isInMaintenance()) ? (
            <Maintenance />
        ) : (
            <React.StrictMode>
                <Suspense fallback="loading">
                    <App />
                </Suspense>
            </React.StrictMode>
        ),
        document.getElementById("root"),
    );
}
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
