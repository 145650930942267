import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    removeScreenIDFromAllContent,
    getNotAssignedContent,
    disableContent,
    enableAllContent,
    repeatContent,
    updateOrder,
    updateContentOrder,
    unLinkAllExceptThis,
    deleteRepeatedContent,
    deLinkContent,
    updateManyContent,
    toggleContentCloned,
} from "./Screen.functions";
import ButtonSwitcher from "../buttons/ButtonSwitcher";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTooltip from "react-tooltip";
import DecisionModal from "../utils/DecisionModal";
import OneInputModal from "../utils/OneInputModal";
import CarrouselCreativeModal from "../utils/CarrouselCreativeModal";
import DetailContentModal from "../utils/DetailContentModal";
import SelectContentModal from "../utils/SelectContentModal";
import EventForPlaylist from "../utils/EventForPlaylist";
import * as dotenv from "dotenv";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import ListItem from "./ListItem";
import Sortable from "./Sortable";
import Loading from "../loading/Loading";
dotenv.config();

class ScreenPlaylist extends Component {
    constructor() {
        super();
        this.state = {
            isElementVisible: false,
            isMenuVisible: false,
            contents: [],
            isReOrdening: false,
            contentsExceeded: false,
            selectedContentId: "",
            contentMenuIsVisible: false,
            contentBeingReaded: null,
            contentMenuDetailThumbnail: false,
            viewPlaylist: "list",
            isLoading: [],
        };
        this.timer = null;
    }

    componentDidMount = () => {
        this.sortAndSetContent();
        document.addEventListener("click", this.checkClickOutside);
        this.refreshPlaylist();
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (!isEqual(prevProps.content, this.props.content)) {
            this.refreshPlaylist();
        }
        if (!isEqual(prevState.contents, this.state.contents)) {
            if (this.state.viewPlaylist === "grid") {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.updateOrder(this.state.contents);
                }, 6000);
            }
        }
    };
    componentWillUnmount = () => {
        document.removeEventListener("click", this.checkClickOutside, false);
    };
    componentWillUpdate = (prevProps, prevState) => {
        if (this.props.elementVisible !== prevProps.elementVisible) {
            if (this.props.elementVisible === false) {
                this.setState({ isElementVisible: false });
            }
        }
    };
    /**
     * A function that toggles the visibility of the playlist element.
     * */
    toggleElement = () => {
        this.setState({
            isElementVisible: !this.state.isElementVisible,
        });
    };
    /**
     * A function that toggles the state of the menu.
     * */
    toggleMenu = () => {
        this.setState({ isMenuVisible: !this.state.isMenuVisible });
    };

    /**
     * A function that verify if given arrays are equals
     * @param {Array} a
     * @param {Array} b
     * @returns boolean that represents if given arrays are equals
     */
    equalsArray = (a, b) => {
        let boolean = false;
        if (a.length === b.length) {
            boolean = true;
            a.forEach((itemA) => {
                if (!b.includes(itemA)) {
                    boolean = false;
                }
            });
        }
        return boolean;
    };
    /**
     * Return a boolean that say if the previus content is equal to the current content
     * @param {*} prevContent
     * @returns
     */
    screenIDChanged = (prevContent = []) => {
        let content = cloneDeep(this.props.content);
        let returnBool = false;
        if (content.length === prevContent.length) {
            content.forEach((content, i) => {
                if (
                    !this.equalsArray(
                        content.screen_id,
                        prevContent[i].screen_id,
                    )
                ) {
                    returnBool = true;
                }
            });
        } else {
            returnBool = true;
        }
        return returnBool;
    };

    /**
     * Checking if the user clicked on the screen or not. If the user clicked on the screen, it will show the menu.
     * @param {*} e
     */
    checkClickOutside = (e) => {
        let targetID = 0;
        let screenID = "";
        let type;

        if (
            e.target?.toString().includes("HTMLDocument") ||
            e.target?.getAttribute("data-target") === null
        ) {
            if (
                !e.target.parentNode ||
                e.target.parentNode?.toString().includes("HTMLDocument") ||
                e.target.parentNode?.getAttribute("data-target") === null
            ) {
                targetID = null;
                type = null;
            } else {
                screenID = e.target.parentNode.getAttribute("data-screen");
                targetID = e.target.parentNode.getAttribute("data-target");
                type = e.target.parentNode.getAttribute("data-type");
            }
        } else {
            targetID = e.target.getAttribute("data-target");
            screenID = e.target.getAttribute("data-screen");
            type = e.target.getAttribute("data-type");
        }
        if (type === "playlist" && targetID === this.props.screen._id) {
            this.setState({ isMenuVisible: !this.state.isMenuVisible });
            this.setState({ selectedContentId: "" });
        } else if (type === "content" && screenID === this.props.screen._id) {
            if (!this.state.contentMenuIsVisible) {
                this.setState({ selectedContentId: targetID });
                this.setState({ contentMenuIsVisible: true });
            } else if (this.state.selectedContentId === targetID)
                this.setState({ contentMenuIsVisible: false });
            else {
                this.setState({ selectedContentId: targetID });
                this.setState({ contentMenuIsVisible: true });
            }
            this.setState({ isMenuVisible: false });
        } else {
            this.setState({ isMenuVisible: false });
            this.setState({ contentMenuIsVisible: false });
            this.setState({ contentMenuDetailThumbnail: false });
        }
    };
    /**
     * Returns the number of copies that a content has
     * @param {*} id
     * @returns
     */
    countClonedContentWithID = (id) => {
        let contentsCloned = this.props.content;
        let cont = contentsCloned.filter(
            (content) => content.original_content === id,
        );
        return cont.length;
    };

    /**
     * Function that returns a boolean if the content has the atribute hideCloned in true or not
     * @param {*} id
     * @returns boolean
     */
    contentHasHideCloned = (id) => {
        let content = this.props.content.find((content) => content._id === id);
        if (content?.hideCloned) {
            return true;
        }
        return false;
    };

    /**
     * It sorts the contents of a screen by the order of the contents in the screen
     * @param contents - an array of content objects
     * @returns The contents are being sorted by the order of the screen.
     */

    sortAndSetContent = () => {
        const screen_id = this.props.screen._id;
        let contentSorted = cloneDeep(this.props.content);
        contentSorted = contentSorted.filter(
            (content) =>
                (!content.disableOnPlaylist ||
                    !content.disableOnPlaylist.includes(screen_id)) &&
                (!content.original_content ||
                    !this.contentHasHideCloned(content.original_content)),
        );
        this.props.screenEvents.forEach((event) => {
            let eventPlaying = this.isEventPlaying(event);
            this.setState({
                eventPlaying,
            });
        });
        contentSorted = this.sortContentByOrder(contentSorted);
        let updatedContent = this.changeOrderContent(contentSorted);
        this.setState({ contents: [] }, () => {
            if (!isEqual(updatedContent, this.state.contents)) {
                this.setState({ contents: updatedContent }, () => {
                    this.checkContentOutOfDate();
                });
            }
        });
    };

    /**
     * It sorts the contents of a screen by the order of the contents in the screen
     * @param contents - an array of content objects
     * @returns The contents are being sorted by the order of the screen.
     */
    sortContentByOrder = (contents) => {
        contents = contents.sort((a, b) => {
            let indexA;
            if (a.order) {
                a.order.forEach((ord, index) => {
                    if (ord.screen_id === this.props.screen._id) {
                        indexA = index;
                    }
                });
            }
            let indexB;
            if (b.order) {
                b.order.forEach((ord, index) => {
                    if (ord.screen_id === this.props.screen._id) {
                        indexB = index;
                    }
                });
            }
            if (indexA !== undefined && indexB !== undefined) {
                return a.order[indexA].index - b.order[indexB].index;
            } else if (indexA === undefined && indexB !== undefined) {
                return 1;
            } else if (indexA !== undefined && indexB === undefined) {
                return -1;
            } else {
                return 0;
            }
        });
        return contents;
    };

    /**Check if any content is out of date. */
    checkContentOutOfDate = () => {
        let contents = cloneDeep(this.state.contents);
        contents.forEach((content) => {
            let dateFinishContent = "";
            if (content.schedule.endTime) {
                dateFinishContent = new Date(
                    content.schedule.endDate +
                        "T" +
                        content.schedule.endTime +
                        ":00",
                ).getTime();
            } else {
                dateFinishContent = new Date(content.schedule.endDate);
            }
            if (dateFinishContent - new Date() <= 0) {
                this.setState({ selectedContentId: content._id }, () => {
                    this.deLink(2, content._id);
                });
            }
        });
    };

    /**
     * It returns the sum of the duration of all the contents in the state.contents array, except for
     * the ones that have a duration of 0.
     * @returns The total duration of the contents.
     */
    calcContentsSeconds = () => {
        const screen_duration = this.props.screen.ad_duration;
        return this.state.contents.reduce((acc, content) => {
            if (!this.contentOnlyForEvent(content)) {
                if (content.duration) return (acc += Number(content.duration));
                else return (acc += Number(screen_duration));
            } else {
                return acc;
            }
        }, 0);
    };
    /**
     * It removes all the contents from the screen.
     */
    handleClickRemoveItems = () => {
        let screenID = this.props.screen._id;
        let contentIDs = [];
        let contents = [...this.state.contents];
        contents.forEach((content) => {
            contentIDs.push(content._id);
        });
        removeScreenIDFromAllContent(contentIDs, screenID)
            .then((r) => {
                if (r.status === 200) {
                    this.setState({ contents: [] }, () => {
                        this.setState({ isMenuVisible: false });
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    /**
     * It adds a class to a specific div, then checks if an array contains a value and toggles depending if it have it or not.
     * Disabled array in content determines if that content will be shown in screen or not, even though it is linked.
     * @param index - the index of the content in the array of contents
     * @param content - content that needs to toggle visibility in screen
     */
    handleScreenVisibility = (index, content) => {
        const contentId = content.name + content.screen_id[0]
        this.setState({ isLoading: [ ...this.state.isLoading, contentId  ] })

        if (this.state.viewPlaylist !== "grid") {
            document
                .querySelector("#content" + index)
                .classList.add("pointer-events-none");
        }
        if (!Array.isArray(content.disabled)) {
            content.disabled = [];
        }
        if (content.disabled.includes(this.props.screen._id)) {
            this.removeElement(content.disabled, this.props.screen._id);
        } else {
            content.disabled.push(this.props.screen._id);
        }

        disableContent(content._id, content.disabled)
            .then((res) => {
                if (res.status === 200) {
                    this.updateContent(
                        res.data.id,
                        content.disabled,
                        res.data.rev,
                        index,
                    );
                }
                this.setState({ isLoading: this.state.isLoading.filter(item => item !== contentId) });
            })
            .catch((err) => console.log(err));
    };

    /**
     * enable all switchest of the playlist and refresh the playlist
     */
    enableAllContent = () => {
        let screen_id = this.props.screen._id;
        let content_id = this.state.selectedContentId;
        enableAllContent({ screen_id, content_id })
            .then((r) => {
                if (r.status === 200) {
                    this.refreshPlaylist();
                }
            })
            .catch((err) => {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
            });
    };
    /**
     * Removing an element from an array.
     * @param {*} array
     * @param {*} elem
     */
    removeElement = (array, elem) => {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    };
    /**
     * Calling the updateContent function from the parent component.
     * @param {*} id
     * @param {*} arrDisabled
     * @param {*} rev
     * @param {*} index
     */
    updateContent = (id, arrDisabled, rev, index) => {
        this.props.updateContent(id, arrDisabled, rev, index).then((r) => {
            if (r === true) {
                if (this.state.viewPlaylist !== "grid") {
                    document
                        .querySelector("#content" + index)
                        .classList.remove("pointer-events-none");
                }
            }
        });
    };
    /**
     * Returns a boolean that say if the content belongs to the items of iterative Content
     * @returns boolean
     */
    getContentIsNotInEvent = () => {
        let content = cloneDeep(this.state.contents);
        return content.filter((content) => !this.contentOnlyForEvent(content));
    };

    /**
     * Reordering the items in the list.
     * @param {*} result
     * @returns
     */
    handleDragEnd = (result) => {
        if (this.state.isReOrdening) return;
        if (!result.destination) return;
        let items = Array.from(this.getContentIsNotInEvent());
        let [reOrderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reOrderedItem);
        let updatedContent = this.changeOrderContent(items);
        this.setState({ contents: updatedContent });
        this.setState({ isReOrdening: true });
        this.updateOrder(updatedContent);
    };

    /**
     * Return the index correspondent of the screen
     * @param {*} item
     * @returns
     */
    getIndexOfplaylist = (item) => {
        let order = item.order;
        let index = order.find((e) => e.screen_id === this.props.screen._id);
        return index?.index;
    };
    /**
     * Updating order of contents that has in the playlist
     * @param {*} updatedContent
     * @param {*} event
     */
    updateOrder = (updatedContent = [], event = false) => {
        updateOrder(updatedContent)
            .then((r) => {
                if (r.status === 200) {
                    updatedContent.forEach((cnt) => {
                        r.data.forEach((data) => {
                            if (data.id === cnt._id) {
                                cnt._rev = data.rev;
                            }
                        });
                    });
                    let contentToSendToDb = [];
                    if (event) {
                        let contents = cloneDeep(this.state.contents);
                        contents.forEach((content) => {
                            updatedContent.forEach((updated, i) => {
                                if (content._id === updated._id) {
                                    content = updated;
                                    updatedContent.splice(i, 1);
                                }
                            });
                        });
                        updatedContent.forEach((content) => {
                            contents.push(content);
                        });
                        this.setState({ contents: contents });
                        contentToSendToDb = cloneDeep(contents);
                    } else {
                        this.setState({ contents: updatedContent });
                        contentToSendToDb = cloneDeep(updatedContent);
                    }
                    this.props.updateOrder(contentToSendToDb).then(() => {
                        this.setState({ isReOrdening: false });
                    });
                }
            })
            .catch((err) => console.log(err));
    };
    /**
     * return a boolean value that say if the array in item.order have a screen associate
     * @param {*} item
     * @param {*} screen_id
     * @returns boolean
     */
    hasScreenIDInOrder = (item, screen_id) => {
        let hasScreenId = false;
        if (item.order) {
            item.order.forEach((order) => {
                if (order.screen_id === screen_id) {
                    hasScreenId = true;
                }
            });
        }
        return hasScreenId;
    };
    /**
     * take an array of items, create a object for every item and if item has ScreenID In Order, push the object into item.order and finally return the array
     * @param {*} items
     * @returns items
     */
    changeOrderContent = (items = []) => {
        items.forEach((i, index) => {
            let obj = {
                index,
                screen_id: this.props.screen._id,
            };
            if (!i.order || !this.hasScreenIDInOrder(i)) {
                i.order = [];
                i.order.push(obj);
            } else {
                i.order.forEach((order) => {
                    if (order.screen_id === this.props.screen._id) {
                        order.index = obj.index;
                    }
                });
            }
        });
        return items;
    };
    /**
     * delete the copy of a selected content a certain number of times
     * @param {*} times
     * @returns
     */
    subtractRepeatedContent = async (times) => {
        if (times === "") {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.completeFields"),
            });

            return;
        }

        if (!this.hasOnlyNumbers(times)) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.onlyNumbers"),
            });

            return;
        }
        const repeatedContentToDelete = cloneDeep(this.state.contents)
            .reverse()
            .filter(
                (content) =>
                    content.original_content === this.state.selectedContentId,
            )
            .slice(0, times);
        try {
            const repeatedContentToDeleteIds = repeatedContentToDelete.map(
                (content) => content._id,
            );
            const screen_id = this.props.screen._id;
            const response = await deleteRepeatedContent(
                repeatedContentToDeleteIds,
                screen_id,
            );

            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });

                return;
            }
            this.refreshPlaylist();
            this.props.showNotification({
                type: "success",
                text: this.props.t(
                    "sections.screens.screenPlaylist.repeatedContentHasBeenDeleted",
                    { times: repeatedContentToDelete.length },
                ),
            });
        } catch (error) {
            console.log("error en sustraer repetidos", error);

            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });

            return;
        }
    };
    /**
     * create a copy of the content that has reference to selectedContent
     * @param {*} times
     * @returns
     */
    repeatContent = async (times) => {
        if ((times === "") === 0) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.completeFields"),
            });

            return;
        }

        if (!this.hasOnlyNumbers(times)) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.onlyNumbers"),
            });

            return;
        }

        if (this.exceedSpots(times)) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t(
                    "sections.screens.screenPlaylist.repeatSpotsExceeded",
                    { limit: this.props.screen.total_spots },
                ),
            });

            return;
        }

        //falta poner limite de repeticiones
        const { selectedContentId } = this.state;
        let contents = cloneDeep(this.state.contents);
        const selectedContentData = contents.find(
            (content) => content._id === selectedContentId,
        );
        const originalContentId = selectedContentData._id;
        const screen_id = this.props.screen._id;
        let repeatedContent = [];
        const updatedContent = [];
        let nextOrderIndex = 0;
        let i = 0;
        let repeatedTimes = 0;
        // let xTimesRelative = 0; // FALTA TERMINAR
        // let xTimesToPutCloned = Math.floor((contents.length-1) / (Number(times)+1));
        // let indexOfOriginalContent =-1;
        // contents.forEach((content,i) => {
        //     if(content._id === originalContentId){
        //         indexOfOriginalContent = i;
        //     }
        // });
        // repeatedContent.push(contents.splice(indexOfOriginalContent,1)[0]);
        // for (let index = 0; index < times; index++) {
        //     let copyContent = cloneDeep(selectedContentData)
        //     copyContent.screen_id = [screen_id];
        //     copyContent.original_content = originalContentId;
        //     delete copyContent._id;
        //     delete copyContent._rev;
        //     repeatedContent.push(copyContent);
        // }
        // while(repeatedContent.length !== 0 || contents.length !== 0){
        //     let content = {};
        //     if(xTimesRelative === xTimesToPutCloned){
        //         content = repeatedContent.shift();
        //     }else{
        //         content = contents.shift();
        //     }
        //     content.order.forEach(ord => {
        //         if (ord.screen_id === screen_id){
        //             ord.index = i;
        //         }
        //     });
        //     updatedContent.push(content);
        //     i++;
        //     if(xTimesRelative === xTimesToPutCloned){
        //         xTimesRelative = 0;
        //     }else{
        //         xTimesRelative++;
        //     }
        // }
        while (repeatedTimes < times && i < contents.length) {
            const content = cloneDeep(contents[i]);
            const nextContent = contents[i + 1];
            let nextOrderIndexCopy = nextOrderIndex;

            content.order.forEach((ord) => {
                if (ord.screen_id === screen_id)
                    ord.index = nextOrderIndexCopy++;
            });

            updatedContent.push(content);

            if (
                content._id !== selectedContentId &&
                content.original_content !== selectedContentId &&
                nextContent &&
                nextContent._id !== selectedContentId &&
                nextContent.original_content !== selectedContentId
            ) {
                const selectedContentCopy = cloneDeep(selectedContentData);

                selectedContentCopy.order.forEach((ord) => {
                    if (ord.screen_id === screen_id)
                        ord.index = nextOrderIndexCopy++;
                });

                selectedContentCopy.original_content = originalContentId;
                selectedContentCopy.screen_id = [screen_id];
                repeatedContent.push(selectedContentCopy);
                repeatedTimes++;
            }

            i++;
            nextOrderIndex = nextOrderIndexCopy;
        }

        if (i < contents.length) {
            for (let j = i; j < contents.length; j++) {
                let nextOrderIndexCopy = nextOrderIndex;
                const content = cloneDeep(contents[j]);
                content.order.forEach((ord) => {
                    if (ord.screen_id === screen_id)
                        ord.index = nextOrderIndexCopy++;
                });
                updatedContent.push(content);
                nextOrderIndex = nextOrderIndexCopy;
            }
        }

        if (repeatedTimes < times) {
            for (let i = repeatedTimes; i < times; i++) {
                let nextOrderIndexCopy = nextOrderIndex;
                const selectedContentCopy = cloneDeep(selectedContentData);
                selectedContentCopy.order.forEach((ord) => {
                    if (ord.screen_id === screen_id)
                        ord.index = nextOrderIndexCopy++;
                });
                selectedContentCopy.screen_id = [screen_id];
                selectedContentCopy.original_content = originalContentId;
                if (selectedContentCopy.daily_limit) {
                    delete selectedContentCopy.daily_limit;
                    delete selectedContentCopy.impressions_in_day;
                }
                repeatedContent.push(selectedContentCopy);
                nextOrderIndex = nextOrderIndexCopy;
            }
        }
        try {
            const response = await repeatContent(repeatedContent, screen_id);
            if (response.status !== 201) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });

                return;
            }
        } catch (error) {
            console.log(error);

            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });

            return;
        }

        try {
            const response = await updateContentOrder(updatedContent);
            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });

                return;
            }
        } catch (error) {
            console.log(error);

            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });

            return;
        }

        this.refreshPlaylist();
        this.props.showNotification({
            type: "success",
            text: this.props.t(
                "sections.screens.screenPlaylist.contentHasBeenRepeated",
                { times: repeatedContent.length },
            ),
        });
    };

    /**
     * "If the total number of spots minus the number of spots already taken minus the number of spots the
     * user is trying to add is less than 0, then return true."
     *
     * The function is called in the following way:
     * @param newSpots - the number of spots the user is trying to add
     * @returns The difference between the total spots and the sum of the current spots and the new spots.
     */
    exceedSpots = (newSpots) => {
        return (
            Number.parseInt(this.props.screen.total_spots) -
                (this.state.contents.length + Number.parseInt(newSpots)) <
            0
        );
    };

    /**
     *  Deleting a content from a screen
     *  */
    deleteRepeatedContent = async () => {
        const contentId = this.state.selectedContentId;
        const screen_id = this.props.screen._id;

        try {
            const response = await deleteRepeatedContent(contentId, screen_id);

            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });

                return;
            }

            this.props.removeContent(contentId);
        } catch (error) {
            console.log("error al borrar repetido", error);

            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };
    /**
     * It returns the number of enabled contents in a list of contents.
     * @returns The number of contents that are not disabled.
     */
    getContentEnabled = () => {
        let contents = [...this.state.contents];
        let realQuantity = contents.length;
        contents.forEach((doc) => {
            if (doc.disabled?.length > 0) {
                realQuantity -= doc.disabled.length;
            }
        });
        return realQuantity;
    };
    /**
     * DeLinkContent is a function that takes in a screen_id, contentId, and option and returns a promise.
     * @param option - is a string that can be either "all" or "one"
     */
    deLink = (option, id = null) => {
        let screen_id = this.props.screen._id;
        let contentId = null;
        if (id) contentId = id;
        else contentId = this.state.selectedContentId;
        deLinkContent({ screen_id, contentId, option })
            .then((r) => {
                if (r.status === 200) {
                    this.refreshPlaylist();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    /**
     * UnLinkAllExceptThis() is a function that takes a screen_id and a content_id and unlinks all
     * content from the screen except the content with the content_id.
     */
    unLinkAllExceptThis = () => {
        let screen_id = this.props.screen._id;
        let content_id = this.state.selectedContentId;
        unLinkAllExceptThis({ screen_id, content_id })
            .then((r) => {
                if (r.status === 200) {
                    this.refreshPlaylist();
                }
            })
            .catch((err) => {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
            });
    };
    /**
     * Get all content that is not assigned to a screen and is not expired.
     * @returns an array of objects.
     */
    getNotAssignedContent = async () => {
        let response = {};
        let error = null;
        try {
            response = await getNotAssignedContent(this.props.screen._id);
        } catch (err) {
            console.log(err);
            error = err;
        } finally {
            if (error === null) {
                return response.data;
            } else {
                return [];
            }
        }
    };
    /**
     * This function takes an array of objects and assigns the screen_id property of each object to the
     * screen_id of the current screen.
     * @param [content]
     */
    assignContent = async (content = []) => {
        let contents = await this.getNotAssignedContent();
        let assignedContent = [];
        contents.forEach((c) => {
            content.forEach((c2) => {
                if (c._id === c2.value) {
                    c.screen_id.push(this.props.screen._id);
                    assignedContent.push(c);
                }
            });
        });
        updateManyContent(assignedContent)
            .then((r) => {
                if (r.status === 200) {
                    this.refreshPlaylist();
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t(
                            "common.notification.screens.contentAssigned",
                        ),
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
            });
    };

    /**
     *  The above code is checking if the string contains only numbers. */
    hasOnlyNumbers = (string) => /^\d+$/.test(string);

    /* A function that takes in an id and returns true if the content has been repeated. */
    contentHasBeenRepeated = (id) =>
        this.state.contents.find((content) => content.original_content === id);

    /**
     * It takes an eventID, an array of all events, and an array of all contents, and returns an array
     * of all contents that are associated with the eventID.
     * @param eventID - the id of the event you want to get the contents for
     * @param allEvents - an array of objects that contain an array of content IDs
     * @param contents - an array of objects that contain the content information
     * @returns An array of objects.
     */
    getEventContents = (eventID, allEvents, contents) => {
        let eventContents = [];
        let event = allEvents.find((event) => event._id === eventID);
        contents.forEach((content) => {
            if (event.content.includes(content._id))
                eventContents.push(content);
        });
        return eventContents;
    };

    /**
     * If the event start date is less than or equal to today's date and today's date is greater than or
     * equal to the event end date, then the event is in the past.
     * @param event - the event object
     */
    isEventInThePast = (event) => {
        let today = new Date().getTime();
        let eventStart = new Date(event.start).getTime();
        let eventEnd = new Date(event.end).getTime();
        return eventStart <= today && today >= eventEnd;
    };

    /**
     * If the event start date is less than or equal to today's date and today's date is less than the
     * event end date, then return the event. Otherwise, return null.
     * @param event - The event object that you want to check if it's playing.
     * @returns The event object if the event is playing, otherwise null.
     */
    isEventPlaying = (event) => {
        let today = new Date().getTime();
        let eventStart = new Date(event.start).getTime();
        let eventEnd = new Date(event.end).getTime();
        if (eventStart <= today && today < eventEnd) {
            return event;
        }
        return null;
    };

    /**
     * This function is called when the user clicks a button, and it calls a function that makes an API
     * call to get all the content, and then it sorts the content and sets it to state.
     */
    refreshPlaylist = () => {
        this.props
            .getAllContent()
            .then((r) => {
                if (r) {
                    this.setState(
                        { contents: this.props.getContentByScreenId() },
                        () => {
                            this.sortAndSetContent();
                        },
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * "If the screen is not in the disabled array, then the button should be on, otherwise it should
     * be off."
     * @param index - the index of the content in the playlist
     * @param content
     * @returns A function that returns a component.
     */
    renderPlaylistContentSwitch = (index, content) => {
        return (
            <ButtonSwitcher
                handleVisibility={() =>
                    this.handleScreenVisibility(index, content)
                }
                status={!content.disabled?.includes(this.props.screen._id)}
                labelOn="On"
                labelOff="Off"
            ></ButtonSwitcher>
        );
    };

    /**
     * "If the event's content includes the content's id and the content's screensBeforeEvent does not
     * include the screen's id, then return true."
     * @param event
     * @return {boolean}boolean
     */
    screenHasEventContent = (event) => {
        let contents = this.state.contents;
        let screenHasEventContent = false;
        contents.forEach((c) => {
            if (
                event.content.includes(c._id) &&
                !c.screensBeforeEvent.includes(this.props.screen._id)
            ) {
                screenHasEventContent = true;
            }
        });
        return screenHasEventContent;
    };
    /**
     * It returns the length of the contents array, but only if the content is not an event.
     * @returns The length of the array of contents that are not only for events.
     */
    getContentLength = () => {
        let contents = cloneDeep(this.state.contents);
        return contents.filter((content) => !this.contentOnlyForEvent(content))
            .length;
    };
    /**
     * Show modal for see details of content carrousel
     * @param {*} content the id of content
     */
    handleModalOfContent = (name, content) => {
        this.setState({ contentBeingReaded: content }, () => {
            if (content.isCreativeCarrousel) {
                this.props.openModal(name + this.props.screen._id);
            } else {
                this.props.openModal(name + this.props.screen._id);
            }
        });
    };
    /**
     * If the content has an event_order and the event_order includes the screen_id, then return true.
     * Otherwise, return false.
     * @param content - the content object
     * @returns boolean
     */
    contentOnlyForEvent = (content) => {
        let screen_id = this.props.screen._id;
        if (
            content.event_order &&
            content.event_order.find((order) => order.screen_id === screen_id)
        ) {
            if (!content.screen_id.includes(screen_id)) {
                return true;
            }
            return false;
        }
        return false;
    };

    /**
     *  Getting the end date of a content and returning a span with the end date and a tooltip with the
     *  days left to expire.
     *  @param {*}content
     *  @return {}jsx
     */
    getContentEndDate = (content) => {
        let endDate = content.schedule.endDate;
        let endDateInFormat = endDate.replaceAll("-", "/");
        let endDateInDate = new Date(endDateInFormat);
        let today = new Date();
        let daysTillEnd = this.getDaysBetween(today, endDateInDate);
        return (
            <span className="playlist-item-end font-extralight text-gray-600 mr-1">
                <FontAwesomeIcon
                    icon={["fal", "calendar-alt"]}
                    fixedWidth
                    className="text-blue-600 mr-2 ml-0.5 text-sm"
                />
                {endDate}
                {daysTillEnd <= 7 && (
                    <>
                        <ReactTooltip
                            effect="solid"
                            className="p-2"
                            id={"outOfDateTooltip" + content._id}
                            aria-haspopup="true"
                        >
                            <p className="text-center">
                                {this.props
                                    .t(
                                        "sections.screens.screenPlaylist.expiredTextTooltip",
                                    )
                                    .replace("_", daysTillEnd)}
                                {daysTillEnd > 1 && "s"}
                            </p>
                        </ReactTooltip>
                        <FontAwesomeIcon
                            data-tip
                            data-for={"outOfDateTooltip" + content._id}
                            icon={["fas", "exclamation-triangle"]}
                            className="text-yellow-400 ml-2 text-sm"
                        />
                    </>
                )}
            </span>
        );
    };

    /**
     *  Calculating the number of days between two dates and returned.
     *  @params Date, Date
     *  @return number
     * */
    getDaysBetween = (startDate, endDate) => {
        let start = startDate.getTime();
        let end = endDate.getTime();
        let daysBetween = (end - start) / (1000 * 60 * 60 * 24);
        return Number(Math.round(daysBetween));
    };
    /**
     * Change de atribute of hideCloned, disable or show as appropriate the contents cloned and next sort that content
     * @param {*} content_id
     */
    handleContentCloned = (content_id) => {
        let content = cloneDeep(this.state.contents).find(
            (content) => content._id === content_id,
        );
        if (!content.hideCloned) {
            content.hideCloned = true;
        } else {
            content.hideCloned = false;
        }
        toggleContentCloned({
            content_id: content._id,
            screen_id: this.props.screen._id,
            toggle: content.hideCloned,
        })
            .then((r) => {
                if (r.status === 200) {
                    let rev = r.data.find(
                        (docData) => docData.id === content_id,
                    ).rev;
                    content._rev = rev;
                    this.props.updateContentCloned(content).then((r) => {
                        if (r) {
                            this.sortAndSetContent();
                        } else {
                            this.props.showNotification({
                                type: "error",
                                text: this.props.t(
                                    "sections.screens.screenPlaylist.errorToggleCloned",
                                ),
                            });
                        }
                    });
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t(
                            "sections.screens.screenPlaylist.errorToggleCloned",
                        ),
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    /**
     * Function that say if the array of id ocult has the id what has been passed by parameter
     * @param {*} content_id
     * @returns boolean
     */
    toogleIconContentCloned = (content_id) => {
        if (this.state.hideCloned.includes(content_id)) {
            return true;
        }
        return false;
    };
    /**
     * Return a boolean that say if the content of the screen selected has contents cloned
     * @param {*} id
     * @param {*} screen_id
     * @returns {*} boolean
     */
    hasContentCloned = (id, screen_id) => {
        let contents = cloneDeep(this.props.content);
        contents = contents.filter(
            (content) =>
                content.original_content === id &&
                content.screen_id.includes(screen_id),
        );
        if (contents.length > 0) {
            return true;
        }
        return false;
    };
    /**
     * Obtain the contents of a iterative content
     * @returns array of contents
     */
    getContentIterative = async () => {
        let array = [];
        let content = {};
        if (this.state.contentBeingReaded?.isCreativeCarrousel) {
            let contentsId = cloneDeep(
                this.state.contentBeingReaded.contents_id,
            );
            let allContent = await this.props.getAllContent();
            contentsId.forEach((c) => {
                content = allContent.find((cnt) => cnt._id === c);
                array.push(content);
            });
        }
        return array;
    };
    /**
     * Open context menu with options of the content selected
     * @param {*} e -the event
     * @param {*} content
     */
    openOptionsOfDetailContent = (e, content) => {
        e.preventDefault();
        let targetID = document.getElementById(`detailThumbnail${content._id}`);
        this.setState({
            contentMenuDetailThumbnail: true,
            selectedContentId: targetID.id,
        });
    };
    /**
     * It checks if there is any content that is not disabled and has no daily limit.
     * @returns A boolean value.
     */
    haveStandardContent = () => {
        let contents = cloneDeep(this.state.contents);
        let haveStandardContent = false;
        let hasDailyLimit = false;
        let atLeastOneContent = false;
        for (let index = 0; index < contents.length; index++) {
            const content = contents[index];
            if (
                !content.disabled.includes(this.props.screen._id) &&
                !content.daily_limit
            ) {
                haveStandardContent = true;
                break;
            }
            if (
                content.daily_limit &&
                !content.disabled.includes(this.props.screen._id)
            ) {
                hasDailyLimit = true;
            }
            if (!content.disabled.includes(this.props.screen._id)) {
                atLeastOneContent = true;
            }
        }
        return (haveStandardContent && hasDailyLimit) || !atLeastOneContent;
    };
    /**
     * Switch the view of the contents between list mode and grid mode
     */
    handleViewPlaylist = (value) => {
        if (value !== this.state.viewPlaylist) {
            this.setState({ viewPlaylist: value });
        }
    };
    /**
     * When sort an item in a playlist view grid change de order of items
     * @param {*} items
     */
    handleOnSortEndGridView = (items) => {
        let updatedContent = this.changeOrderContent(items);
        this.setState({ contents: updatedContent, isReOrdening: false });
    };

    render() {
        const { t, displayPlaylist } = this.props;
        const {
            isElementVisible,
            isMenuVisible,
            selectedContentId,
            contentMenuIsVisible,
            contentMenuDetailThumbnail,
        } = this.state;
        return (
            <>

                <div
                    className={`screen-playlist relative mt-2 transition-all duration-200 ${displayPlaylist ? "" : "hidden"}`}
                >
                    <div className="playlist-header flex justify-between items-center cursor-pointer">
                        <span
                            onClick={this.toggleElement}
                            className="screen-name text-sm text-gray-700 mr-3 hover:text-gray-900"
                        >
                            <span className="font-semibold">
                                {t("sections.screens.screensPlaylist")}
                            </span>
                            {this.props.screen.total_spots <
                                this.getContentEnabled() && (
                                <>
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="exceedTooltip"
                                        aria-haspopup="true"
                                    >
                                        <p className="text-center">
                                            {t(
                                                "sections.screens.screenPlaylist.screenExceed",
                                            )}
                                        </p>
                                    </ReactTooltip>
                                    <FontAwesomeIcon
                                        data-tip
                                        data-for="exceedTooltip"
                                        icon={["fas", "exclamation-triangle"]}
                                        className="text-yellow-500 ml-2 text-sm"
                                    />
                                </>
                            )}
                            {!this.haveStandardContent() && (
                                <>
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="onlyDailyLimit"
                                        aria-haspopup="true"
                                    >
                                        <p className="text-center">
                                            {t(
                                                "sections.screens.screenPlaylist.onlyDailyLimit",
                                            )}
                                        </p>
                                    </ReactTooltip>
                                    <FontAwesomeIcon
                                        data-tip
                                        data-for="onlyDailyLimit"
                                        icon={["fas", "exclamation-triangle"]}
                                        className="text-yellow-500 ml-2 text-sm"
                                    />
                                </>
                            )}
                        </span>
                        <span className="playlist-items truncate font-light rounded-full bg-gray-200 text-gray-600 mx-3 px-2 text-xs">
                            {this.getContentLength()}
                            {this.state.contents.length <= 1
                                ? t("sections.screens.screenPlaylist.item")
                                : t(
                                      "sections.screens.screenPlaylist.items",
                                  )}{" "}
                            / {this.calcContentsSeconds().toFixed(2)}
                            {this.calcContentsSeconds() <= 1
                                ? t("sections.screens.screenPlaylist.second")
                                : t("sections.screens.screenPlaylist.seconds")}
                        </span>
                        <div className="playlist-options flex-none w-10 relative">
                            {/* Playlist Menu */}
                            <div
                                className={`absolute z-10 w-56 right-0 mt-6 mr-6 p-2 px-6 text-xs bg-white rounded-md shadow divide-y divide-blue-200 ${isMenuVisible ? "" : "hidden"} ${this.props.isLastOne ? "mt-0  bottom-4" : "mt-6"}`}
                            >
                                <div
                                    className="text-left py-2 text-gray-600 hover:text-black"
                                    onClick={() =>
                                        this.props.openModal(
                                            `assignTo-${this.props.screen._id}`,
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "plus"]}
                                        className="mr-3"
                                    />
                                    {this.props.t(
                                        "sections.screens.menu.screenAddContent",
                                    )}
                                </div>
                                <div
                                    onClick={() => this.refreshPlaylist()}
                                    className="select-none text-left py-2 text-blue-600 hover:text-black"
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "repeat-alt"]}
                                        className="mr-3"
                                    />
                                    Refresh Playlist
                                </div>
                                <div
                                    onClick={() => this.enableAllContent()}
                                    className="select-none text-left py-2 text-blue-600 hover:text-black"
                                >
                                    <FontAwesomeIcon
                                        icon={["fa", "copy"]}
                                        className="mr-3"
                                    />
                                    {t("sections.screens.menu.enableAll")}
                                </div>
                                <div
                                    onClick={() =>
                                        this.props.openModal(
                                            `deleteScreenIDOfContent` +
                                                this.props.screen._id,
                                        )
                                    }
                                    className="select-none text-left py-2 text-red-600 hover:text-black"
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "trash"]}
                                        className="mr-3"
                                    />
                                    {t(
                                        "sections.screens.menu.screenPlaylistRemoveItems",
                                    )}
                                </div>
                            </div>
                            <FontAwesomeIcon
                                data-type="playlist"
                                data-toggle="toggleMenuIcon"
                                data-target={this.props.screen._id}
                                icon={["fal", "cog"]}
                                className="text-blue-600 mr-2 text-sm"
                            />
                            <FontAwesomeIcon
                                onClick={this.toggleElement}
                                icon={["fal", "chevron-left"]}
                                className={`text-blue-400 mr-2 text-sm transition-transform duration-300 ease-in-out transform ${isElementVisible ? "-rotate-90" : "rotate-0"}`}
                            />
                        </div>
                    </div>
                    <div
                        className={`${isElementVisible ? "w-full mb-y flex justify-end" : "invisible"} hidden`}
                    >
                        <div
                            className={`flex justify-self-center mr-1 p-1 ${this.state.viewPlaylist === "list" && "bg-blue-400 p-1 rounded  "}`}
                        >
                            <FontAwesomeIcon
                                onClick={() => {
                                    this.handleViewPlaylist("list");
                                }}
                                icon={["fal", "line-columns"]}
                                className={`cursor-pointer   text-sm transition-transform duration-300 ease-in-out transform ${this.state.viewPlaylist === "list" ? " text-white" : "text-blue-400 "}`}
                            />
                        </div>
                        <div
                            className={`flex justify-self-center mr-1 p-1 ${this.state.viewPlaylist === "grid" && "bg-blue-400 p-1 rounded  "}`}
                        >
                            <FontAwesomeIcon
                                onClick={() => {
                                    this.handleViewPlaylist("grid");
                                }}
                                icon={["fal", "th"]}
                                className={`cursor-pointer   text-sm transition-transform duration-300 ease-in-out transform ${this.state.viewPlaylist === "grid" ? " text-white" : "text-blue-400 "}`}
                            />
                        </div>
                    </div>
                    <div className={`${isElementVisible ? "block" : "hidden"}`}>
                        <div
                            className={`mt-3 ${this.state.viewPlaylist === "grid" && "flex justify-center"}`}
                        >
                            <div id="playlistView" ref={this.ref}>
                                <div id="playlist">
                                    {this.state.viewPlaylist === "grid" ? (
                                        <Sortable
                                            handleOnSortStartGridView={
                                                this.handleOnSortStartGridView
                                            }
                                            isEventInThePast={
                                                this.isEventInThePast
                                            }
                                            getEventContents={
                                                this.getEventContents
                                            }
                                            screenEvents={
                                                this.props.screenEvents
                                            }
                                            onSortStart={this.onSortStart}
                                            handleOnSortEndGridView={
                                                this.handleOnSortEndGridView
                                            }
                                            items={this.state.contents}
                                            contents={this.props.content}
                                            getIndexOfplaylist={
                                                this.getIndexOfplaylist
                                            }
                                            countClonedContentWithID={
                                                this.countClonedContentWithID
                                            }
                                            hasContentCloned={
                                                this.hasContentCloned
                                            }
                                            contentHasBeenRepeated={
                                                this.contentHasBeenRepeated
                                            }
                                            handleContentCloned={
                                                this.handleContentCloned
                                            }
                                            openOptionsOfDetailContent={
                                                this.openOptionsOfDetailContent
                                            }
                                            formatNumber={this.formatNumber}
                                            screen={this.props.screen}
                                            handleModalOfContent={
                                                this.handleModalOfContent
                                            }
                                            t={this.props.t}
                                            renderPlaylistContentSwitch={
                                                this.renderPlaylistContentSwitch
                                            }
                                            openModal={this.props.openModal}
                                            contentMenuDetailThumbnail={
                                                contentMenuDetailThumbnail
                                            }
                                            contentMenuIsVisible={
                                                contentMenuIsVisible
                                            }
                                            selectedContentId={
                                                this.state.selectedContentId
                                            }
                                        />
                                    ) : (
                                        <DragDropContext
                                            onDragEnd={this.handleDragEnd}
                                        >
                                            <Droppable droppableId="screenPlaylist">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className={` playlist-items  relative mt-2 divide-y `}
                                                    >
                                                        {/* Item */}
                                                        {this.state.contents.map(
                                                            (
                                                                content,
                                                                index,
                                                            ) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {!this.contentOnlyForEvent(
                                                                        content,
                                                                    ) && (
                                                                        <Draggable
                                                                            key={
                                                                                content._id
                                                                            }
                                                                            draggableId={
                                                                                content._id
                                                                            }
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {(
                                                                                provided,
                                                                            ) => (
                                                                                <div
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    className={` playlist-item flex justify-between items-start py-2 rounded-md text-sm ${content.original_content ? "item-cloned" : "item-original"} hover:bg-gray-100 active:bg-white active:shadow`}
                                                                                >
                                                                                    <>
                                                                                        <ListItem
                                                                                            contentHasBeenRepeated={
                                                                                                this
                                                                                                    .contentHasBeenRepeated
                                                                                            }
                                                                                            content={
                                                                                                content
                                                                                            }
                                                                                            screen={
                                                                                                this
                                                                                                    .props
                                                                                                    .screen
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                            hasContentCloned={
                                                                                                this
                                                                                                    .hasContentCloned
                                                                                            }
                                                                                            renderPlaylistContentSwitch={
                                                                                                this
                                                                                                    .renderPlaylistContentSwitch
                                                                                            }
                                                                                            countClonedContentWithID={
                                                                                                this
                                                                                                    .countClonedContentWithID
                                                                                            }
                                                                                            openOptionsOfDetailContent={
                                                                                                this
                                                                                                    .openOptionsOfDetailContent
                                                                                            }
                                                                                            handleContentCloned={
                                                                                                this
                                                                                                    .handleContentCloned
                                                                                            }
                                                                                            isElementVisible={
                                                                                                isElementVisible
                                                                                            }
                                                                                            isMenuVisible={
                                                                                                isMenuVisible
                                                                                            }
                                                                                            selectedContentId={
                                                                                                selectedContentId
                                                                                            }
                                                                                            contentMenuIsVisible={
                                                                                                contentMenuIsVisible
                                                                                            }
                                                                                            contentMenuDetailThumbnail={
                                                                                                contentMenuDetailThumbnail
                                                                                            }
                                                                                            handleModalOfContent={
                                                                                                this
                                                                                                    .handleModalOfContent
                                                                                            }
                                                                                            openModal={
                                                                                                this
                                                                                                    .props
                                                                                                    .openModal
                                                                                            }
                                                                                            isLoading={this.state.isLoading.includes(content.name + content.screen_id[0])}
                                                                                        ></ListItem>
                                                                                    </>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    )}
                                                                </div>
                                                            ),
                                                        )}
                                                        {provided.placeholder}
                                                        {this.props.screenEvents.map(
                                                            (event, index) => (
                                                                <>
                                                                    {!this.isEventInThePast(
                                                                        event,
                                                                    ) && (
                                                                        <EventForPlaylist
                                                                            event={
                                                                                event
                                                                            }
                                                                            updateOrder={
                                                                                this
                                                                                    .updateOrder
                                                                            }
                                                                            isReOrdening={
                                                                                this
                                                                                    .state
                                                                                    .isReOrdening
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                            index={
                                                                                index
                                                                            }
                                                                            screen={
                                                                                this
                                                                                    .props
                                                                                    .screen
                                                                            }
                                                                            openModal={
                                                                                this
                                                                                    .props
                                                                                    .openModal
                                                                            }
                                                                            contents={
                                                                                this
                                                                                    .props
                                                                                    .content
                                                                            }
                                                                            getEventContents={
                                                                                this
                                                                                    .getEventContents
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ),
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OneInputModal
                    modalID={`repeatContent${this.props.screen._id}`}
                    title={t("sections.modal.repeatContent.title")}
                    placeHolderText={t(
                        "sections.modal.repeatContent.placeholder",
                    )}
                    buttonText={t("sections.modal.repeatContent.buttonText")}
                    callbackFunction={this.repeatContent}
                />
                <OneInputModal
                    modalID={`subtractRepeatedContent${this.props.screen._id}`}
                    title={t("sections.modal.subtractRepeatedContent.title")}
                    placeHolderText={t(
                        "sections.modal.subtractRepeatedContent.placeholder",
                    )}
                    buttonText={t(
                        "sections.modal.subtractRepeatedContent.buttonText",
                    )}
                    callbackFunction={this.subtractRepeatedContent}
                />
                <DecisionModal
                    text={t("sections.modal.deleteModal.warning")}
                    modalID={`deleteRepeatedContent${this.props.screen._id}`}
                    callbackFunction={this.deleteRepeatedContent}
                />
                <DecisionModal
                    text={t("sections.modal.deleteModal.warning")}
                    modalID={`deleteScreenIDOfContent` + this.props.screen._id}
                    callbackFunction={this.handleClickRemoveItems}
                />
                <DecisionModal
                    text={t("sections.modal.deLinkContent.text")}
                    modalID={`deLinkContent${this.props.screen._id}`}
                    callbackOption1={this.deLink}
                    callbackOption2={this.deLink}
                    option2_text={t("sections.modal.deLinkContent.yes")}
                    option1_text={t("sections.modal.deLinkContent.no")}
                />
                <DecisionModal
                    text={t("sections.modal.unLinkAllContent.warning")}
                    modalID={`unLinkAllContent${this.props.screen._id}`}
                    callbackFunction={this.unLinkAllExceptThis}
                />
                <SelectContentModal
                    modalID={`assignTo-${this.props.screen._id}`}
                    title={this.props.t(
                        "sections.screens.menu.screenAddContent",
                    )}
                    getNotAssignedContent={this.getNotAssignedContent}
                    callbackFunction={this.assignContent}
                ></SelectContentModal>
                <CarrouselCreativeModal
                    refreshPlaylist={this.refreshPlaylist}
                    step={3}
                    modalID={`CarrouselCreativeModal${this.props.screen._id}`}
                    isOpenFromPlaylist={true}
                    screens={this.props.screens}
                    getContentIterative={this.getContentIterative}
                    showNotification={this.props.showNotification}
                    contentBeingReaded={this.state.contentBeingReaded}
                    screen={this.props.screen}
                />
                <DetailContentModal
                    content={this.state.contentBeingReaded}
                    modalID={`DetailContentModal` + this.props.screen._id}
                    screens={this.props.screens}
                />
            </>
        );
    }
}

export default withTranslation()(ScreenPlaylist);
