import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { confirmAccount } from "./UserFunctions";

class ConfirmAccount extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        const url = this.props.location.pathname;
        const code = url.substring(url.lastIndexOf("/") + 1);
        confirmAccount(code)
            .then((r) => {
                if (r.status === 200) {
                    this.props.history.push({
                        pathname: "/login",
                        state: { Confirmed: true },
                    });
                } else {
                    this.props.history.push({
                        pathname: "/login",
                        state: { Confirmed: false },
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    render() {
        return <></>;
    }
}
export default withTranslation()(ConfirmAccount);
