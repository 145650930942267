import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/modals.css";
class OneInputModal extends Component {
    returnValue = (e) => {
        const value = e.target.previousSibling.value;
        this.props.callbackFunction(value);

        if (value === "") return;

        this.closeModal();
        e.target.previousSibling.value = "";
    };

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };

    render() {
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-2xl">
                            {this.props.title}
                        </div>
                        <div
                            className="button cursor-pointer"
                            onClick={this.closeModal}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <div className="flex h-auto py-10 px-2 justify-center items-center bg-gray-200 rounded text-center text-gray-500">
                        {this.props.value ? (
                            <input
                                id={"inputModal" + this.props.modalID}
                                autoFocus
                                value={this.props.value}
                                onChange={this.props.callbackChangeValue}
                                className="shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                type="text"
                                placeholder={this.props.placeHolderText}
                            ></input>
                        ) : (
                            <input
                                id={"inputModal" + this.props.modalID}
                                autoFocus
                                className="shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                type="text"
                                placeholder={this.props.placeHolderText}
                            ></input>
                        )}
                        <button
                            className="buttonPrimary"
                            onClick={this.returnValue}
                        >
                            {this.props.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OneInputModal);
