import React, { Component } from "react";
class CustomScreenOption extends Component {
    render() {
        const { props } = this.props;
        return (
            <div className="flex flex-row">
                <p className="ml-2 my-auto">{props.children}</p>
            </div>
        );
    }
}

export default CustomScreenOption;
