import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const baseURL = process.env.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

export const getAllContent = async () => {
    try {
        const r = await instance_axios.get("/content/all", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return console.log(err);
    }
};
export const getByParent = async (path) => {
    try {
        const r = await instance_axios.post(
            "/content/getbyparent",
            { path },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return console.log(err);
    }
};
export const deleteContent = async (IDs) => {
    try {
        const r = await instance_axios.post(
            "/content/deletecontent",
            { IDs },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};
export const renameContent = async (newName, id) => {
    try {
        const r = await instance_axios.post(
            "/content/rename/" + id,
            { newName },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return console.log(err);
    }
};
export const changeScreenID = async (screens_ids, id) => {
    try {
        const r = await instance_axios.post(
            "/content/changescreenid/" + id,
            { screens_ids },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return console.log(err);
    }
};
export const updateContent = async (newDoc) => {
    try {
        const r = await instance_axios.put(
            "/content/updatemany",
            { docs: [newDoc] },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};

export const updateManyContent = async (docs) => {
    try {
        const r = await instance_axios.put(
            "/content/updatemany",
            { docs: docs },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};

export const checkLimitExceeded = async (screens_ids) => {
    try {
        const r = await instance_axios.post(
            "/content/checklimit",
            { screens_ids },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err;
    }
};
export const changeIconClasses = async (content) => {
    try {
        const r = await instance_axios.put("/", content, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err;
    }
};
export const getContent = async () => {
    try {
        const r = await instance_axios.get("/content/all", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err;
    }
};

export const getScreenGroups = async () => {
    try {
        const screenGroups = await instance_axios.get("/generic/screenGroup", {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return screenGroups;
    } catch (err) {
        return err;
    }
};

export const postIterativeContent = async (iterativeContent) => {
    try {
        const r = await instance_axios.post("/generic/", iterativeContent, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
            },
        });
        return r;
    } catch (err) {
        return err.request;
    }
};

export const replaceMediaContentFromItem = async (
    id,
    path,
    duration,
    width,
    height,
    name,
) => {
    try {
        const r = await instance_axios.put(
            "/content/replace/" + id,
            { path, duration, width, height, name },
            {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user"), //the token is a variable which holds the token
                },
            },
        );
        return r;
    } catch (err) {
        return err.request;
    }
};
