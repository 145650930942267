import React from "react";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-center items-center h-screen flex-col">
            <img
                src={require(
                    `../../images/${process.env.REACT_APP_WL_NAME}/logo-color.svg`,
                )}
                className="h-12 group-hover:block"
                alt={process.env.REACT_APP_WL_COMPANY_NAME}
            />
            <div className="flex mt-2">
                <span>{t("sections.maintenance.message")}</span>
                {/*                 <div className="ml-1">
                    <a href={`mailto:${process.env.REACT_APP_WL_COMPANY_EMAIL}?subject=Maintenance`} className="link">{process.env.REACT_APP_WL_COMPANY_EMAIL}</a>
                </div> */}
            </div>
        </div>
    );
};

export default Maintenance;
