import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

function NavigationWizard(params) {
    const { t } = useTranslation();
    const {
        currentStep,
        setCurrentStep,
        stepOneIsCompleted,
        stepTwoIsCompleted,
        showNotification,
    } = params;
    const currentItem = "bg-blue-400 text-white font-bold shadow";

    const changeStep = (step) => {
        if (currentStep === 1) {
            if (
                !stepOneIsCompleted() ||
                (stepOneIsCompleted() && !stepTwoIsCompleted() && step > 2)
            ) {
                showNotification({
                    type: "warning",
                    text: t("common.notification.completeFields"),
                });
                return;
            }

            // if (stepOneIsCompleted() && !startTimeLessEndTime() && step > 2) {
            //     showNotification({
            //         type: "warning",
            //         text: t('common.notification.stepTwo.startTimeHigherEndTime')
            //     });
            //     return;
            // }
        }

        if (currentStep === 2) {
            if (!stepTwoIsCompleted() && step > currentStep) {
                showNotification({
                    type: "warning",
                    text: t("common.notification.completeFields"),
                });
                return;
            }

            // if (!startTimeLessEndTime() && step > 2) {
            //     showNotification({
            //         type: "warning",
            //         text: t('common.notification.stepTwo.startTimeHigherEndTime')
            //     });
            //     return;
            // }
        }

        setCurrentStep(step);
    };

    return (
        <>
            <nav className="rounded-full mx-auto my-2 px-4 py-4 flex flex-nowrap space-x-4 items-center content-center justify-center overflow-hidden overflow-x-auto md:overflow-x-hidden">
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="stepOne"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t("sections.wizard.stepOne.stepTitle")}
                        </span>{" "}
                        <br /> {t("sections.wizard.stepOne.stepTitleExtra")}
                    </div>
                </ReactTooltip>
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="stepTwo"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t("sections.wizard.stepTwo.stepTitle")}
                        </span>{" "}
                        <br /> {t("sections.wizard.stepTwo.stepTitleExtra")}{" "}
                        <br />
                    </div>
                </ReactTooltip>
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="stepThree"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t("sections.wizard.stepThree.stepTitle")}
                        </span>{" "}
                        <br /> {t("sections.wizard.stepThree.stepTitleExtra")}{" "}
                        <br />
                    </div>
                </ReactTooltip>
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="stepFour"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t("sections.wizard.stepFour.stepTitle")}
                        </span>{" "}
                        <br /> {t("sections.wizard.stepFour.stepTitleExtra")}{" "}
                        <br />
                    </div>
                </ReactTooltip>
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="stepFive"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t("sections.wizard.stepFive.stepTitle")}
                        </span>{" "}
                        <br /> {t("sections.wizard.stepFive.stepTitleExtra")}{" "}
                        <br />
                    </div>
                </ReactTooltip>
                <ReactTooltip
                    effect="solid"
                    className="w-48 px-2"
                    id="confirmationStep"
                    aria-haspopup="true"
                >
                    <div className="m-1">
                        <span className="font-bold">
                            {t(
                                "sections.wizard.confirmationStep.notificationTitle",
                            )}
                        </span>{" "}
                        <br />
                    </div>
                </ReactTooltip>
                <div
                    data-tip
                    data-for="stepOne"
                    onClick={() => changeStep(1)}
                    className={`ml-24 rounded-full p-2 px-4 cursor-pointer ${currentStep === 1 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    1
                </div>
                <div
                    data-tip
                    data-for="stepTwo"
                    onClick={() => changeStep(2)}
                    className={`rounded-full p-2 px-4 cursor-pointer ${currentStep === 2 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    2
                </div>
                <div
                    data-tip
                    data-for="stepThree"
                    onClick={() => changeStep(3)}
                    className={`rounded-full p-2 px-4 cursor-pointer ${currentStep === 3 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    3
                </div>
                <div
                    data-tip
                    data-for="stepFour"
                    onClick={() => changeStep(4)}
                    className={`rounded-full p-2 px-4 cursor-pointer ${currentStep === 4 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    4
                </div>
                <div
                    data-tip
                    data-for="stepFive"
                    onClick={() => changeStep(5)}
                    className={`rounded-full p-2 px-4 cursor-pointer ${currentStep === 5 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    5
                </div>
                <div
                    data-tip
                    data-for="confirmationStep"
                    onClick={() => changeStep(6)}
                    className={`rounded-full p-2 px-4 cursor-pointer ${currentStep === 6 ? currentItem : "bg-white hover:bg-blue-100"}`}
                >
                    6
                </div>
            </nav>
        </>
    );
}
export default withTranslation()(NavigationWizard);
