import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarHeader from "./CalendarHeader";
import FullCalendar from "@fullcalendar/react";
import allLocales from "@fullcalendar/core/locales-all.js";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventModal from "./modals/EventModal.js";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import ButtonSwitcher from "../buttons/ButtonSwitcher";
import Searchbar from "../utils/Searchbar";
import ReactTooltip from "react-tooltip";
import "./calendar.css";

import {
    getScreens,
    getContent,
    addEvent,
    getEvents,
    getEvent,
    updateEvent,
    deleteEvent,
    getScreenGroups,
    updateEventContent,
    endEvent,
} from "./CalendarFunctions";
import { checkLimitExceeded } from "../content/contentAJAXFunctions";
import { getBrands, getCustomers } from "../utils/CommonRequests";
import { updateManyContent } from "../screens/Screen.functions";

class Calendar extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            filteredEvents: [],
            screens: [],
            datePicked: "",
            eventPicked: {},
            componentCalendar: true,
        };
    }
    componentDidMount() {
        getScreens()
            .then((res) => {
                if (res.status === 200) {
                    let screensToShow = res.data.map((screen) => screen._id);
                    let screens = res.data;
                    let filteredScreens = res.data;
                    checkLimitExceeded(screensToShow)
                        .then((res) => {
                            this.setState({
                                screens: screens,
                                filteredScreens: filteredScreens,
                                screensToShow: screensToShow,
                                screenExceeded: res.data,
                            });
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));
        getScreenGroups()
            .then((res) => {
                this.setState({
                    screenGroups: res.data,
                });
            })
            .catch((err) => console.log(err));
        getEvents()
            .then((res) => {
                if (res.status === 200) {
                    res.data.forEach((ev) => {
                        this.isEventInPast(ev);
                    });
                    let eventsToShow = this.divideEventsByDays(res.data);
                    this.setState(
                        {
                            events: res.data,
                            filteredEvents: eventsToShow,
                        },
                        () => {
                            let data = "";
                            if (this.props.location.state) {
                                data = this.props.location.state.data;
                                let event = this.state.events.find(
                                    (ev) => ev._id === data.event,
                                );
                                if (event)
                                    this.setState(
                                        {
                                            eventPicked: event,
                                            isEventClicked: true,
                                            eventAction: "edit",
                                        },
                                        () => {
                                            this.props.openModal("eventModal");
                                        },
                                    );
                            }
                        },
                    );
                }
            })
            .catch((err) => console.log(err));
        getBrands()
            .then((res) => {
                if (res.status === 200) {
                    this.setState({ brands: res.data });
                }
            })
            .catch((err) => console.log(err));
        getCustomers()
            .then((res) => {
                if (res.status === 200) {
                    this.setState({ customers: res.data });
                }
            })
            .catch((err) => console.log(err));
        getContent()
            .then((res) => {
                if (res.status === 200) {
                    let content = res.data.filter(
                        (content) =>
                            !content.original_content &&
                            content.type !== "folder",
                    );
                    content = content.filter(
                        (cont) => !this.contentIsOutOfDate(cont),
                    );
                    this.setState({
                        content: content,
                    });
                }
            })
            .catch((err) => console.log(err));
    }
    /**
     * Function that gets the aspect ratio based in window inner width
     * @returns String representing aspect ratio
     */
    getAspectRatioOfCalendarByInnerWidth = () => {
        if (window.innerWidth > 1024) {
            return "2";
        }
        if (window.innerWidth < 1024 && window.innerWidth > 768) {
            return "1";
        }
        if (window.innerWidth < 768 && window.innerWidth > 425) {
            return "0.5";
        }
    };
    /**
     * Checks if a content is out of date
     * @param {Object} content
     * @returns {Boolean}
     */
    contentIsOutOfDate = (content) =>
        new Date().getTime() > new Date(content.schedule.endDate).getTime();
    /**
     * Function that parse all event in days, based in the days the event will work
     * @param {Array} events
     * @returns array with parsed events by days inside
     */
    divideEventsByDays = (events) => {
        events.forEach((event) => {
            if (event.repetitive_day) {
                event.daysOfWeek = event.repetitive_day;
                event.startRecur = event.start;
                event.endRecur = event.end;
            }
        });
        return events;
    };
    /**
     * Function that close modal with given ID
     * @param {String} modalID
     */
    closeModal = (modalID) => {
        document.getElementById(modalID).classList.add("hidden");
        this.setState({
            eventPicked: {},
            isEventClicked: false,
        });
    };
    /**
     * Function that handles the click in specific date and saves it in state
     * @param {*} arg provided by FullCalendar component
     */
    handleDateClick = (arg) => {
        this.setState(
            {
                datePicked: arg.dateStr,
                isEventClicked: false,
                eventAction: "create",
            },
            () => {
                this.props.openModal("eventModal");
            },
        );
    };
    /**
     * Function that customize the view of an event in FullCalendar component
     * @param {*} eventInfo
     * @returns customized event
     */
    renderEventContent = (eventInfo) => {
        let screensName = this.getScreensName(
            eventInfo.event.extendedProps.screen,
        );
        let textColor = eventInfo.event.extendedProps.confirmed
            ? "rgb(0, 0, 0)"
            : "rgb(105,105,105)";
        let isExceeded = this.eventHasScreenExceeded(
            eventInfo.event.extendedProps,
        )
            ? "#ffb300"
            : "#0004d9";

        return (
            <div className="mt-1">
                <ReactTooltip
                    effect="solid"
                    place="left"
                    className="tooltipIndex"
                    id={"event" + eventInfo.event.extendedProps._id}
                >
                    <p className="text-center   ">
                        {`(${screensName.toString()}) ${eventInfo.event.title}`}
                    </p>
                </ReactTooltip>

                <div
                    className="p-1 flex truncate  bg-blue-200 text-md "
                    style={{ color: textColor }}
                    data-tip
                    data-for={"event" + eventInfo.event.extendedProps._id}
                >
                    <FontAwesomeIcon
                        icon={["fas", "circle"]}
                        className="my-auto pr-1"
                        style={{ color: `${isExceeded}` }}
                    />
                    <p
                        className={
                            (this.isEventInPast(eventInfo.event)
                                ? "line-through"
                                : eventInfo.event.allDay
                                  ? "underline"
                                  : "") + "  decoration-2 decoration-black  "
                        }
                    >
                        {eventInfo.event.title}
                    </p>
                </div>
            </div>
        );
    };
    /**
     * Function that checks if an event is ended
     * @param {*} event
     * @returns boolean
     */
    isEventInPast = (event) => {
        let start = event.start,
            end = event.end;
        if (!end && event.allDay) {
            end = `${event.start.getFullYear()}-${`${event.start.getMonth() + 1}`.length === 1 ? `0${event.start.getMonth() + 1}` : event.start.getMonth() + 1}-${`${event.start.getDate()}`.length === 1 ? `0${event.start.getDate()}` : event.start.getDate()}T${event.extendedProps.hourend}`;
            end = new Date(end);
        }
        let today = new Date().getTime();
        let eventStart = new Date(start).getTime();
        let eventEnd = new Date(end).getTime();
        if (!event.extendedProps && !event.finished) {
            if (
                eventStart > eventEnd &&
                today >= eventEnd &&
                today > eventStart
            ) {
                this.endedEvent(event._id);
            } else if (eventStart < today && today > eventEnd) {
                this.endedEvent(event._id);
            }
        }
        if (eventStart > eventEnd) {
            return today > new Date(start).setHours("23:59:59");
        }
        return today > eventStart && today >= eventEnd;
    };
    /**
     * function that ends an event in case it has not been used
     * @param {String} id
     */
    endedEvent = (id) => {
        endEvent(id)
            .then((response) => response)
            .catch((err) => console.log(err));
    };
    /**
     * Function that checks if given event has a screen with exceeded spots
     * @param {*} event
     * @returns first element of array
     */
    eventHasScreenExceeded = (event) => {
        let screens = cloneDeep(this.state.screenExceeded);
        return screens?.map((screen) => event.screen.includes(screen.id))[0];
    };
    /**
     * Function that return an array with the screen names of event screens
     * @param {Array} eventScreens
     * @returns array of strings
     */
    getScreensName = (eventScreens) => {
        let screens = cloneDeep(this.state.screens);
        let screensName = [];
        eventScreens.forEach((screen) => {
            screensName.push(screens.find((s) => s._id === screen)?.name);
        });
        return screensName;
    };
    /**
     * Function that gets the language of navigator
     * @returns first element of array strings
     */
    getLang = () => {
        let langNavigator =
            window.navigator.language || navigator.browserLanguage;
        let array = langNavigator.split("-");
        return array[0];
    };
    /**
     * Function that post a event to DB, parsing and modifying contents linked to the event
     * @param {*} event
     */
    addEvent = (event) => {
        if (new Date(event.start) > new Date(event.end)) {
            let endDate = new Date(event.end);
            endDate.setDate(endDate.getDate() + 1);
            event.end = `${endDate.getFullYear()}-${`${endDate.getMonth() + 1}`.length === 1 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1}-${`${endDate.getDate()}`.length === 1 ? `0${endDate.getDate()}` : endDate.getDate()}T${event.hourend}`;
        }
        event.type = "calendarEvent";
        event.confirmed = false;
        if (!this.checkForAnotherEventInSameDayAndScreen(event)) {
            addEvent(event)
                .then((res) => {
                    if (res.status === 201) {
                        let eventContents = this.state.content.filter((c) =>
                            event.content.includes(c._id),
                        );
                        eventContents.forEach((content, index) => {
                            if (!content.event_order) content.event_order = [];

                            event.screen.forEach((screen) => {
                                content.event_order.push({
                                    index: index,
                                    screen_id: screen,
                                    event_id: res.data.id,
                                });
                            });
                        });
                        updateEventContent(eventContents)
                            .then((r) => r)
                            .catch((err) => console.log(err));
                        this.setState({ events: [] }, () => {
                            getEvents()
                                .then((res) => {
                                    if (res.status === 200) {
                                        let eventsToShow = res.data;
                                        this.setState({
                                            events: res.data,
                                            filteredEvents: eventsToShow,
                                            eventPicked: {},
                                        });
                                    }
                                })
                                .catch((err) => console.log(err));
                        });
                        this.props.showNotification({
                            type: "success",
                            text: this.props.t(
                                "common.notification.calendar.createdEvent",
                            ), //Evento creado
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t(
                            "common.notification.calendar.errorEvent",
                        ), //Error al crear evento
                    });
                });
        } else
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.calendar.eventDateConflict",
                ),
            });
    };
    /**
     * Function that checks if exists an event in the same day/hour and using the same screen
     * @param {*} event
     * @returns boolean that represents if there is a problem with start and end date of event
     */
    checkForAnotherEventInSameDayAndScreen = (event) => {
        let { events } = this.state;
        let startToCheck = new Date(event.start);
        let endToCheck = new Date(event.end);
        let otherEventInSameDayAndScreen = events.filter(
            (ev) =>
                ev.screen.some((screen) => event.screen.includes(screen)) &&
                ev._id !== event._id,
        );
        otherEventInSameDayAndScreen = otherEventInSameDayAndScreen.filter(
            (ev) =>
                (new Date(ev.start) <= startToCheck &&
                    startToCheck <= new Date(ev.end)) ||
                (new Date(ev.start) <= endToCheck &&
                    endToCheck <= new Date(ev.end)),
        );
        return otherEventInSameDayAndScreen.length > 0;
    };
    /**
     * Function that checks if exists another event with same screen of given one
     * @param {*} event
     * @returns a number representing the amount of events with same screen
     */
    checkIfEventsHaveSameScreen = (event) => {
        let existingEvents = cloneDeep(this.state.events);
        existingEvents = existingEvents.filter((ev) => ev._id !== event._id);
        let sameScreenCount = 0;
        existingEvents.forEach((ev) => {
            if (ev.screen.some((s) => event.screen.includes(s))) {
                sameScreenCount++;
            }
        });
        return sameScreenCount;
    };
    /**
     * Function that checks if given date month has more days
     * @param {Date} date
     * @returns boolean
     */
    checkIfMonthHasMoreDays = (date) => {
        let dateInFormat = date;
        let month = dateInFormat.getMonth() + 1;
        let year = dateInFormat.getFullYear();
        let daysInMonth = new Date(year, month, 0).getDate();
        return daysInMonth > dateInFormat.getDate();
    };
    /**
     * Function that return the amount of days between two dates
     * @param {String} startDate
     * @param {String} endDate
     * @returns number of days between two dates
     */
    getDaysBetween = (startDate, endDate) => {
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
        if (start > end) {
            return 1;
        }
        let daysBetween = (end - start) / (1000 * 60 * 60 * 24);
        if (daysBetween > 0 && daysBetween < 1) {
            return 0;
        }
        return Number(daysBetween.toFixed(0));
    };
    /**
     * Function that gets clicked event and open event modal
     * @param {*} arg
     */
    handleEventClick = (arg) => {
        let eventID =
            arg.event.extendedProps.original_event ||
            arg.event.extendedProps._id;
        getEvent(eventID)
            .then((res) => {
                this.setState(
                    {
                        eventPicked: res.data,
                        isEventClicked: true,
                        eventAction: "edit",
                    },
                    () => {
                        this.props.openModal("eventModal");
                    },
                );
            })
            .catch((err) => console.log(err));
    };
    /**
     * Function that modify an event that needs to be cloned and sets it in state
     * @param event - the event object that was clicked on
     */
    handleCloneEvent = (event) => {
        let eventToClone = cloneDeep(event);
        eventToClone.title = eventToClone.title + " Cloned";
        console.log(eventToClone);
        delete eventToClone.screen;
        delete eventToClone._id;
        delete eventToClone._rev;
        this.setState({
            eventPicked: eventToClone,
            isEventClicked: false,
            eventAction: "clone",
        });
    };
    /**
     * Function that put a event to DB, parsing and modifying contents linked to the event
     * @param {*} event
     */
    editEvent = (event) => {
        if (new Date(event.start) > new Date(event.end)) {
            let endDate = new Date(event.end);
            endDate.setDate(endDate.getDate() + 1);
            event.end = `${endDate.getFullYear()}-${`${endDate.getMonth() + 1}`.length === 1 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1}-${`${endDate.getDate()}`.length === 1 ? `0${endDate.getDate()}` : endDate.getDate()}T${event.hourend}`;
        }
        let screensID = event.screen.map((screen) => {
            return screen.value || screen;
        });
        event.screen = screensID;
        let eventBeforeUpdate = this.state.events.find(
            (e) => e._id === event._id,
        );
        let confirmationOnly = !eventBeforeUpdate.confirmed && event.confirmed;
        event.finished = false;
        if (!this.checkForAnotherEventInSameDayAndScreen(event)) {
            updateEvent(event)
                .then((res) => {
                    if (res.status === 200) {
                        if (typeof eventBeforeUpdate.content !== typeof "") {
                            let deletedContent = this.eventContentChanged(
                                event.content,
                                eventBeforeUpdate.content,
                            );
                            deletedContent = this.state.content.filter((c) =>
                                deletedContent.includes(c._id),
                            );
                            deletedContent.forEach((deleted) => {
                                deleted.event_order =
                                    deleted.event_order.filter(
                                        (order) => order.event_id !== event._id,
                                    );
                            });
                            updateManyContent(deletedContent)
                                .then((r) => r)
                                .catch((err) => console.log(err));
                        }
                        if (!confirmationOnly) {
                            let eventContents = this.state.content.filter((c) =>
                                event.content.includes(c._id),
                            );
                            eventContents.forEach((content, index) => {
                                if (!content.event_order)
                                    content.event_order = [];
                                event.screen.forEach((screen) => {
                                    if (
                                        !content.event_order.find(
                                            (order) =>
                                                order.event_id === event._id &&
                                                order.screen_id === screen,
                                        )
                                    ) {
                                        content.event_order.push({
                                            index: index,
                                            screen_id: screen,
                                            event_id: event._id,
                                        });
                                    }
                                });
                            });
                            updateEventContent(eventContents)
                                .then((r) => r)
                                .catch((err) => console.log(err));
                        }
                        this.setState({ events: [] }, () => {
                            this.closeModal("eventModal");
                            getEvents()
                                .then((res) => {
                                    let eventsToShow = res.data;
                                    this.setState({
                                        events: res.data,
                                        filteredEvents: eventsToShow,
                                        eventPicked: {},
                                    });
                                })
                                .catch((err) => console.log(err));
                        });
                        this.props.showNotification({
                            type: "success",
                            text: this.props.t(
                                "common.notification.calendar.updatedEvent",
                            ), //Evento editado
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t(
                            "common.notification.calendar.errorUpdateEvent",
                        ), //Error al editar evento
                    });
                });
        } else
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.calendar.eventDateConflict",
                ),
            });
    };
    /**
     * Function that checks if the user deleted any content from event
     * @param {Array} contents
     * @param {Array} contentBefore
     * @returns array of contents deleted
     */
    eventContentChanged = (contents, contentBefore) => {
        let deletedCotent = [];
        contentBefore.forEach((contBefore) => {
            if (!contents.includes(contBefore)) {
                deletedCotent.push(contBefore);
            }
        });
        return deletedCotent;
    };
    /**
     * Function that delete an event from DB, parsing and modifying contents linked to the event
     */
    deleteEvent = () => {
        let eventID = this.state.eventPicked._id;
        let event = this.state.events.find((event) => event._id === eventID);
        let eventContents = this.state.content.filter((c) =>
            event.content.includes(c._id),
        );
        deleteEvent(eventID)
            .then((res) => {
                if (res.status === 200) {
                    eventContents.forEach((content) => {
                        content.event_order = content.event_order?.filter(
                            (order) => order.event_id !== event._id,
                        );
                    });
                    updateManyContent(eventContents)
                        .then((r) => r)
                        .catch((err) => console.log(err));
                    this.setState({ events: [] }, () => {
                        getEvents()
                            .then((res) => {
                                let eventsToShow = res.data;
                                this.setState({
                                    events: res.data,
                                    filteredEvents: eventsToShow,
                                    eventPicked: {},
                                });
                            })
                            .catch((err) => console.log(err));
                    });
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t(
                            "common.notification.calendar.deleteEvent",
                        ), //Evento eliminado
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t(
                        "common.notification.calendar.errorDeleteEvent",
                    ), //Error al eliminar evento
                });
            });
    };
    /**
     * Function that filter all events by selected array of screens
     * @param {Array} events
     * @param {Array} selected
     * @returns array of filtered events
     */
    filterEvents = (events, selected) => {
        let filteredEvents = [];
        let dividedEvents = events;
        dividedEvents.forEach((event) => {
            let screen = [];
            event.screen.forEach((e) => {
                screen.push(e);
            });
            if (event.screen.length === 0) {
                filteredEvents.push(event);
            }
            if (screen.length > 0 && screen.some((e) => selected.includes(e))) {
                filteredEvents.push(event);
            }
        });
        return filteredEvents;
    };
    /**
     * Function that handles the changes in selected screens to filter events
     * @param {String} screenID
     */
    handleShowScreen = (screenID) => {
        let selected = this.state.screensToShow;
        if (selected.includes(screenID)) {
            selected = selected.filter((s) => s !== screenID);
        } else {
            selected.push(screenID);
        }
        this.setState({ screensToShow: selected }, () => {
            let events = cloneDeep(this.state.events);
            this.setState({
                filteredEvents: this.filterEvents(events, selected),
            });
        });
    };
    /**
     * Function that filter the list of screens
     * @param {*} e
     * @returns in case of empty search value
     */
    handleSearchScreen = (e) => {
        if (!e || isEqual(e, "")) {
            this.setState({ filteredScreens: this.state.screens });
            return;
        }
        let filteredScreens = cloneDeep(this.state.filteredScreens);
        filteredScreens = filteredScreens.filter((screen) => {
            return screen.name.toLowerCase().includes(e.toLowerCase());
        });
        if (filteredScreens.length > 0) {
            this.setState({ filteredScreens: filteredScreens });
        } else {
            this.setState({ filteredScreens: this.state.screens });
        }
    };
    /**
     * Function that filters all events by title
     * @param {*} e
     * @returns in case of empty search value
     */
    handleSearchEvent = (e) => {
        if (!e || isEqual(e, "")) {
            let eventsToShow = this.state.events;
            this.setState({ filteredEvents: eventsToShow });
            return;
        }
        let filteredEvents = cloneDeep(this.state.filteredEvents);
        filteredEvents = filteredEvents.filter((event) => {
            return event.title.toLowerCase().includes(e.toLowerCase());
        });
        if (filteredEvents.length > 0) {
            this.setState({ filteredEvents: filteredEvents });
        } else {
            let eventsToShow = this.state.events;
            this.setState({ filteredEvents: eventsToShow });
        }
    };

    /**
     * Function that returns an array of references of event states
     * @returns array of references
     */
    getReferences = () => {
        return [
            {
                title: this.props.t(
                    "sections.calendar.references.screenExceeded",
                ),
                data: (
                    <p className="font-semibold ">
                        {this.props.t(
                            "sections.calendar.references.screenExceededData",
                        )}{" "}
                        <FontAwesomeIcon
                            icon={["fas", "circle"]}
                            style={{ color: "#ffb300" }}
                        />
                    </p>
                ),
                class: "",
                icono: (
                    <FontAwesomeIcon
                        icon={["fas", "circle"]}
                        style={{ color: "#ffb300" }}
                    />
                ),
            },
            {
                title: this.props.t("sections.calendar.references.screenOk"),
                data: (
                    <p className="font-semibold">
                        {this.props.t(
                            "sections.calendar.references.screenOkData",
                        )}{" "}
                        <FontAwesomeIcon
                            icon={["fas", "circle"]}
                            style={{ color: "#0004d9" }}
                        />
                    </p>
                ),
                class: "",
                icono: (
                    <FontAwesomeIcon
                        icon={["fas", "circle"]}
                        style={{ color: "#0004d9" }}
                    />
                ),
            },
            {
                title: this.props.t(
                    "sections.calendar.references.notConfirmed",
                ),
                data: (
                    <p className="font-semibold">
                        {this.props.t(
                            "sections.calendar.references.notConfirmedData",
                        )}
                    </p>
                ),
                class: "text-gray-400",
            },
            {
                title: this.props.t(
                    "sections.calendar.references.confirmedEvent",
                ),
                data: (
                    <p className="font-semibold ">
                        {this.props.t(
                            "sections.calendar.references.confirmedEventData",
                        )}
                    </p>
                ),
                class: "text-dark font-bold",
            },
            {
                title: this.props.t("sections.calendar.references.underline"),
                data: (
                    <p className="font-semibold">
                        {this.props.t(
                            "sections.calendar.references.underlineData",
                        )}
                    </p>
                ),
                class: "underline ",
            },
            {
                title: this.props.t("sections.calendar.references.lineThrough"),
                data: (
                    <p className="font-semibold">
                        {this.props.t(
                            "sections.calendar.references.lineThroughData",
                        )}
                    </p>
                ),
                class: "line-through",
            },
        ];
    };
    /* Showing and hiding the div with the id of screenOptions. */
    showScreenOptions = () => {
        let div = document.getElementById("screenOptions");
        let headerCalendar = document.getElementById("calendarHeader");
        if (div.classList.contains("displayNone")) {
            div.classList.remove("displayNone");
        } else {
            div.classList.add("displayNone");
        }
        if (headerCalendar.classList.contains("displayNone")) {
            headerCalendar.classList.remove("displayNone");
        }
    };

    render() {
        return (
            <>
                <CalendarHeader
                    showScreenOptions={this.showScreenOptions}
                    callFunction={this.handleSearchEvent}
                />
                <div className="calendarDad mt-1 mr-1 flex justify-center rounded-lg shadow-lg p-2 bg-white">
                    <div
                        id="screenOptions"
                        className="menuScreen slideInLeft displayNone bg-gray-100 rounded-lg p-4 flex flex-col w-2/6 text-sm mt-2 md:mr-2 sm:opacity-100"
                    >
                        <div className="flex flex-row  justify-between">
                            <div className=" ">
                                <Searchbar
                                    placeholder={this.props.t(
                                        "sections.calendar.sidebar.searchbar",
                                    )}
                                    disabled={false}
                                    callFunction={this.handleSearchScreen}
                                    classNames="shadow appearance-none w-auto border rounded mb-4 py-2 mx-2 px-3 ml-0 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                />
                            </div>
                            <div
                                className="flex-column mt-2 mr-12 closeBtn cursor-pointer"
                                onClick={this.showScreenOptions}
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="times"
                                    className="svg-inline--fa fa-times fa-fw text-blue-400 mb-2"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                    ></path>
                                </svg>
                            </div>
                        </div>

                        <label className="font-bold">
                            {this.props.t("sections.calendar.sidebar.title")}
                        </label>
                        <div className=" h-100 screenZone overflow-auto">
                            {this.state.filteredScreens?.map((screen) => {
                                return (
                                    <div
                                        className="p-1 mx-2 my-2 flex content-center"
                                        key={screen._id}
                                    >
                                        <ButtonSwitcher
                                            className="my-2"
                                            handleVisibility={() =>
                                                this.handleShowScreen(
                                                    screen._id,
                                                )
                                            }
                                            status={this.state.screensToShow.includes(
                                                screen._id,
                                            )}
                                        ></ButtonSwitcher>
                                        <label
                                            className="m-1 truncate"
                                            htmlFor={screen.id}
                                        >
                                            {screen.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        <label className="font-bold">
                            {this.props.t("sections.calendar.references.title")}
                            :
                        </label>
                        {this.getReferences().map((ref, index) => {
                            return (
                                <div
                                    key={index}
                                    className=" pl-2  py-3 border-b-2 border-slate-1000  tracking-wide text-md "
                                    data-tip
                                    data-for={"reference" + index}
                                >
                                    <ReactTooltip
                                        effect="solid"
                                        className="p-2 tex-center w-full"
                                        id={"reference" + index}
                                        aria-haspopup="true"
                                    >
                                        {ref.data}{" "}
                                        <p className={ref.class}>
                                            {" "}
                                            {this.props.t(
                                                "sections.calendar.example",
                                            )}
                                            {ref.icono ? ref.icono : ""}
                                        </p>
                                    </ReactTooltip>

                                    <label className={ref.class + " flex"}>
                                        {ref.icono ? (
                                            <div className="mr-2">
                                                {" "}
                                                {ref.icono}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {ref.title}{" "}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    <div className="calendar capitalize w-full truncate tracking-wide sm:mr-5 overflow-hidden">
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                timeGridPlugin,
                            ]}
                            locale={this.getLang()}
                            locales={allLocales}
                            dateClick={this.handleDateClick}
                            initialView="dayGridMonth"
                            // borderColor ='#00000'
                            // aspectRatio={this.getAspectRatioOfCalendarByInnerWidth()}
                            contentHeight="auto"
                            headerToolbar={{
                                left: "title",
                                center: "",
                                right: "prev,next dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            titleFormat={{
                                month: "short",
                                year: "numeric",
                                day: "numeric",
                            }}
                            weekends={true}
                            events={this.state.filteredEvents}
                            eventClick={this.handleEventClick}
                            eventContent={this.renderEventContent}
                        />
                    </div>
                </div>

                <EventModal
                    modalID="eventModal"
                    isEventClicked={this.state.isEventClicked}
                    text={
                        this.state.eventPicked?.title ||
                        this.props.t("sections.calendar.wizard.newEvent")
                    }
                    screens={this.state.screens}
                    screenGroups={this.state.screenGroups}
                    brands={this.state.brands}
                    customers={this.state.customers}
                    content={this.state.content}
                    datePicked={this.state.datePicked}
                    eventAction={this.state.eventAction}
                    functionToCall={
                        !this.state.isEventClicked
                            ? this.addEvent
                            : this.editEvent
                    } /*  && this.state.eventAction !== "edit" */
                    handleCloneEvent={this.handleCloneEvent}
                    functionToDelete={this.deleteEvent}
                    showNotification={this.props.showNotification}
                    eventPicked={this.state.eventPicked}
                    openModal={this.props.openModal}
                    closeModal={this.closeModal}
                />
            </>
        );
    }
}
export default withTranslation()(Calendar);
