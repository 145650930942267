import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Select, { components } from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import {
    postIterativeContent,
    updateContent,
} from "../content/contentAJAXFunctions";
import isEqual from "lodash.isequal";
import ButtonSwitcher from "../buttons/ButtonSwitcher";
import { Link } from "react-router-dom";
import CustomContentOption from "./GroupedSelect/CustomComponents/CustomContentOption";
import GroupedSelect from "./GroupedSelect/GroupedSelect";
import "../utils/modals.css";
import { getUser } from "./CommonRequests";
class CarrouselCreativeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            screenSelected: [],
            contentBeingEdited: {},
            contentHasNoEndDateInCarousel: false,
            contentBeingReaded: {},
            selectedContent: [],
            step: 3,
            field: {},
            isReOrdening: false,
        };
    }
    componentDidMount = async () => {
        if (this.props.isOpenFromPlaylist) {
            let contents = [];
            try {
                contents = await this.props.getContentIterative();
                this.setState({ contents: contents });
            } catch (error) {
                console.log(error);
            }
        } else {
            if (this.props.contents) {
                this.setState({ contents: this.props.contents });
            }
        }

        if (this.props.step) {
            this.setState({ step: this.props.step });
        }
        if (this.props.contentBeingEdited) {
            this.setState({
                contentBeingEdited: this.props.contentBeingEdited,
            });
        }
        if (this.props.contentBeingReaded) {
            this.setState({
                contentBeingReaded: this.props.contentBeingReaded,
            });
        }
    };
    componentDidUpdate = async (prevProps, prevState) => {
        if (!this.props.isOpenFromPlaylist) {
            if (!isEqual(prevProps.contents, this.props.contents)) {
                this.setState({ contents: this.props.contents });
            }
        }
        if (
            !isEqual(
                prevProps.contentBeingEdited,
                this.props.contentBeingEdited,
            )
        ) {
            this.setState({
                contentBeingEdited: this.props.contentBeingEdited,
            });
        }
        if (
            !isEqual(
                prevProps.contentBeingReaded,
                this.props.contentBeingReaded,
            )
        ) {
            this.setState({
                contentBeingReaded: this.props.contentBeingReaded,
            });
            let allContent = await this.props.getContentIterative();
            let contentBeingReaded = cloneDeep(this.props.contentBeingReaded);
            let contentsOfIterative = allContent.filter(
                (content) =>
                    content &&
                    contentBeingReaded.contents_id.includes(content._id),
            );
            if (contentsOfIterative !== this.state.contents) {
                this.setState({ contents: contentsOfIterative });
            }
        }
        if (!isEqual(prevProps.selectedContent, this.props.selectedContent)) {
            this.setState({ selectedContent: this.props.selectedContent });
        }
    };

    /**
     * hide the modal with the id that the props have, if the params contents exist also clean the checkboxes
     * @param {*} content
     */
    closeModal = (content = null) => {
        document.getElementById(this.props.modalID).classList.add("hidden");
        this.setState({ isReOrdening: false });
        if (content) {
            this.props.clearValue();
            this.setState({
                selectedContent: [],
                field: [],
                step: 1,
                screenSelected: [],
            });
        }
    };
    /* A function that is called when the user drags and drops an item inside the event playlist.
    It is used to update the order of the items in the list. */
    handleDragEndModal = (result) => {
        if (!result.destination) return;
        let items = cloneDeep(this.state.contents);
        let originalOrder = cloneDeep(items);
        let [reOrderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reOrderedItem);
        if (!isEqual(items, originalOrder)) {
            this.setState({ isReOrdening: true });
        }
        this.setState({ contents: items });
        return;
    };

    /**
     * It takes the contentBeingReaded, which is a playlist, and updates the order of the contents_id
     * array.
      contents_id array is the order of the contents in the playlist.
     */
    handleChangeOrderOfContentonPlaylist = () => {
        let content = cloneDeep(this.props.contentBeingReaded);
        let idsNewOrder = cloneDeep(this.state.contents.map((c) => c._id));
        content.contents_id = idsNewOrder;
        updateContent(content)
            .then((r) => {
                if (r.status === 200) {
                    this.props.refreshPlaylist();
                }
            })
            .catch((err) => {
                console.log(err);
                this.props.showNotification({
                    text: this.props.t("common.notification.serverError"),
                    type: "warning",
                    duration: 3000,
                });
            });
    };
    /**
     * return a boolean that say if the start date is minor that end date
     * @param {*} startDate
     * @param {*} endDate
     * @returns {boolean} dateIsRight
     */
    dateIsRight = (startDate, endDate) => {
        let dateIsRight = false;
        if (new Date(startDate) <= new Date(endDate)) {
            dateIsRight = true;
        }
        if (startDate !== "" && this.state.contentHasNoEndDateInCarousel) {
            dateIsRight = true;
        }
        return dateIsRight;
    };

    /**
     * create a carousel type object and post to DB
     */
    createContentIterative = async (e) => {
        e.persist();
        if (e.target.disabled === true) {
            return;
        }
        e.target.disabled = true;
        let user = {};
        let error = null;
        this.setState({ isCreatingCarrusel: true });
        try {
            user = await getUser();
        } catch (err) {
            error = err;
            console.log(error);
            this.props.showNotification({
                text: this.props.t("common.notification.serverError"),
                type: "warning",
                duration: 3000,
            });
        } finally {
            if (error === null) {
                user = user.data._id;
                let inputs = cloneDeep(this.state.field);
                if (this.dateIsRight(inputs.startDate, inputs.endDate)) {
                    if (
                        this.obtainIdsOfContent(this.state.contents).length !==
                        0
                    ) {
                        let array = this.obtainIdsOfContent(
                            this.state.contents,
                        );
                        let content = {
                            type: "content",
                            name: inputs.name,
                            ext: this.obtainAtribute(array, "ext"),
                            contents_id: array,
                            schedule: {
                                startDate: inputs.startDate,
                                endDate: this.state
                                    .contentHasNoEndDateInCarousel
                                    ? ""
                                    : inputs.endDate,
                                time: inputs.time,
                            },
                            path: this.obtainAtribute(array, "path"),
                            screen_id: this.obtainIdsOfScreen(
                                this.state.screenSelected,
                            ),
                            order: [],
                            disabled: [],
                            isInEvent: false,
                            modified_time: Date.now(),
                            isCreativeCarrousel: true,
                            downloaded: [],
                            user: user,
                        };
                        postIterativeContent(content)
                            .then((r) => {
                                if (r.status === 201) {
                                    content._id = r.data.data.id;
                                    content._rev = r.data.data.rev;
                                    this.props.addContent(content);
                                    this.closeModal(true);
                                    this.props.showNotification({
                                        text: this.props.t(
                                            "sections.content.CreativeContentCreatedSuccessfully",
                                        ),
                                        type: "success",
                                        duration: 3000,
                                    });
                                    e.target.disabled = false;
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                e.target.disabled = false;
                                this.props.showNotification({
                                    text: this.props.t(
                                        "common.notification.serverError",
                                    ),
                                    type: "warning",
                                    duration: 3000,
                                });
                            });
                    } else {
                        this.props.showNotification({
                            text: this.props.t(
                                "common.notification.calendar.emptyContent",
                            ),
                            type: "warning",
                            duration: 3000,
                        });
                        e.target.disabled = false;
                    }
                } else {
                    this.props.showNotification({
                        text: this.props.t(
                            "common.notification.fileDropper.scheduleDate",
                        ),
                        type: "warning",
                        duration: 3000,
                    });
                    e.target.disabled = false;
                }
            }
        }
    };
    /**
     * return the atribute of a content
     * @param {*} array  id of contents
     * @param {*} atribute
     * @returns
     */
    obtainAtribute(array, atribute) {
        let content = this.state.contents.filter((e) => e._id === array[0]);
        return content[0][atribute];
    }
    /**
     *  Create an array of objects with the @name and @_id from the screen and return it to the select
     * @param {*} screens [array]
     * @returns options[ {label: screen01, value: 111111}]
     */
    getOptionScreens = (screens = []) => {
        let options = [];
        screens.forEach((e) => {
            let obj = {
                label: e.name,
                value: e._id,
            };
            options.push(obj);
        });
        return options;
    };
    /**
     *return a boolean that says if the array has a specific value
     * @param {*} array
     * @param {*} value
     * @returns boolean
     */
    arrayHasNotSpecialValue = (obj, value) => {
        let boolean = true;
        let keys = Object.keys(obj);
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            if (obj[key] === value) {
                if (key === "endDate") {
                    boolean = cloneDeep(
                        this.state.contentHasNoEndDateInCarousel,
                    );
                } else {
                    boolean = false;
                }
            }
            if (!boolean) {
                break;
            }
        }
        return boolean;
    };
    /**
     * toogle the input type date, if the content has no end date, hide the input
     */
    handleNoEndDateInCarousel = () => {
        const screen_id = this.props.screen?._id || 0;
        this.setState(
            {
                contentHasNoEndDateInCarousel:
                    !this.state.contentHasNoEndDateInCarousel,
            },
            () => {
                let divToChange = document.getElementById(
                    "endDateContentIterative" + screen_id,
                );
                if (this.state.contentHasNoEndDateInCarousel) {
                    divToChange.classList.add("opacity-0");
                    divToChange.classList.add("pointer-events-none");
                } else {
                    divToChange.classList.remove("opacity-0");
                    divToChange.classList.remove("pointer-events-none");
                }
            },
        );
    };
    /**
     * return an array of id of an array contents
     * @param {*} items
     * @returns [ids]
     */
    obtainIdsOfContent = (items) => {
        let ids = [];
        items.forEach((item) => {
            ids.push(item._id);
        });
        return ids;
    };
    /**
     * return an array of id of an array screen
     * @param {*} screen
     * @returns
     */
    obtainIdsOfScreen = (screen) => {
        let ids = [];
        screen.forEach((screen) => {
            ids.push(screen.value);
        });
        return ids;
    };
    /**
     *  a function that is called when a user selects a screen. if the content of select is null the state is reset to []
     * **/
    handleSelect = (e) => {
        if (e) {
            this.setState({ screenSelected: e });
        } else {
            this.setState({ screenSelected: [] });
        }
    };
    /* A function that is used to update the order of the items in the playlist of event. */
    changeOrderContent = (items = []) => {
        items.forEach((i, index) => {
            let obj = {
                index,
                screen_id: this.props.screen._id,
                event_id: this.props.event._id,
            };
            if (!i.event_order || i.event_order.length === 0) {
                i.event_order = [];
                i.event_order.push(obj);
            } else {
                i.event_order.forEach((order) => {
                    if (order.screen_id === this.props.screen._id) {
                        order.index = obj.index;
                    }
                });
            }
        });
        return items;
    };
    /**
     * If any of the content in the array of content objects has a property of isCreativeCarrousel set
     * to true, return true.
     * @returns A boolean value.
     */
    hasIterativeContent = () => {
        let boolean = false;
        let checkboxes = this.props.checkboxes;
        let allContent = this.props.allContent;
        let content = allContent.filter((c) => checkboxes.includes(c.id));
        content.forEach((c) => {
            if (c.isCreativeCarrousel) {
                boolean = true;
            }
        });
        return boolean;
    };
    /**Check if all the inputs have value diferent to ""
     * If the array has not a special value, then set the state.
     */
    checkCompleteFields = () => {
        const screen_id = this.props.screen?._id || 0;
        let name = document.getElementById(
            "nameContentIterative" + screen_id,
        ).value;
        let time = document.getElementById(
            "timeContentIterative" + screen_id,
        ).value;
        let startDate = document.getElementById(
            "startDateContentIterative" + screen_id,
        ).value;
        let endDate = document.getElementById(
            "endDateContentIterative" + screen_id,
        ).value;
        let inputs = {
            name: name,
            time: time,
            startDate: startDate,
            endDate: endDate,
        };
        if (this.arrayHasNotSpecialValue(inputs, "")) {
            if (this.hasIterativeContent()) {
                this.props.showNotification({
                    text: this.props.t(
                        "sections.content.iterativeContentInIterativeContent",
                    ),
                    type: "warning",
                    duration: 3000,
                });
            }
            this.setState({ field: inputs, step: 2 });
        } else {
            this.props.showNotification({
                text: this.props.t("common.notification.completeFields"),
                type: "warning",
                duration: 3000,
            });
        }
    };
    /**
     * When the user clicks the back button, the step state is set to 1.
     */
    handleBackWizard = () => {
        this.setState({ step: 1 });
    };

    /**
     * It takes an array of objects, filters out the objects that have a property of
     * isCreativeCarrousel set to true, then maps the remaining objects to an array of objects with
     * the properties label and value.
     * @returns An array of objects with the properties label and value.
     */
    getContentsOptions = () => {
        let contents = this.props.allContent.filter(
            (content) => !content.isCreativeCarrousel,
        );
        contents = contents.map((content) => {
            return { label: content.name, value: content.id };
        });
        return contents;
    };
    render() {
        const screen_id = this.props.screen?._id || 0;
        const CustomOption = (props) => {
            let filteredOption = this.props.allContent.filter(
                (c) => c.id === props.value,
            );
            return (
                <components.Option {...props}>
                    <CustomContentOption
                        content={filteredOption}
                        props={props}
                    />
                </components.Option>
            );
        };
        const t = this.props.t;
        const imageExtensions = ["png", "jpg", "jpeg", "gif"];
        const videoExtensions = ["mp4", "ogg", "webm"];
        const htmlExtensions = ["html", "zip"];
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div
                    className={`modalContent   ${!this.state.step === 1 ? "sm:w-auto" : ""} w-auto md:w-4/6 lg:w-6/12 xl:w-5/12 min-w-96 bg-white rounded-md p-5 flex flex-col h-5/6 hover:overflow-y-scroll overflow-x-hidden`}
                >
                    {this.props.contentBeingReaded ? (
                        <div className="modal-header relative font-extralight text-lg flex justify-end px-3">
                            <div
                                className="button cursor-pointer absolute"
                                onClick={() => this.closeModal(false)}
                            >
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </div>
                        </div>
                    ) : (
                        <div className="modal-header relative font-extralight text-lg flex justify-end px-3">
                            <div
                                className="button cursor-pointer absolute"
                                onClick={() => this.closeModal(true)}
                            >
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </div>
                        </div>
                    )}
                    {!this.props.isOpenFromPlaylist && (
                        <>
                            <div className="title  text-gray-600 pb-5 text-2xl">
                                {this.props.t(
                                    "sections.content.createCarouselCreative",
                                )}
                                <div className="text-xs mt-1 text-gray-400">
                                    {this.props.t(
                                        "sections.content.infoCarrousel",
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {(this.state.step === 1 || this.state.step === 3) && (
                        <>
                            <div className="flex flex-col  py-2 px-2 rounded text-gray-500  ">
                                <div className="flex  ">
                                    {/* inputs */}
                                    <div className=" w-full">
                                        <FontAwesomeIcon
                                            icon={["fad", "ad"]}
                                            className="mt-1 mr-1 text-blue-300"
                                        />
                                        <label className=" labelModal text-gray-700 text-sm font-bold mb-2 ">
                                            {t("sections.register.name-label")}:{" "}
                                        </label>
                                        {this.state.contentBeingReaded.name ? (
                                            <div className="my-1 ml-24">
                                                <input
                                                    id={
                                                        "nameContentIterative" +
                                                        screen_id
                                                    }
                                                    type="text"
                                                    value={
                                                        this.state
                                                            .contentBeingReaded
                                                            .name
                                                    }
                                                    disabled="disabled"
                                                    className="w-full block shadow appearance-none bg-gray-200 border border-gray-300 rounded py-2 mx-auto px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                ></input>
                                            </div>
                                        ) : (
                                            <div className="my-1 ml-24">
                                                <input
                                                    id={
                                                        "nameContentIterative" +
                                                        screen_id
                                                    }
                                                    type="text"
                                                    value={
                                                        this.state.field.name
                                                    }
                                                    className="w-full block shadow appearance-none border border-gray-300 rounded py-2 mx-auto px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                ></input>
                                            </div>
                                        )}
                                        <FontAwesomeIcon
                                            icon={["fal", "sign"]}
                                            className="mt-2 mr-1 text-blue-300"
                                        />
                                        <label className="labelModal text-gray-700 text-sm font-bold mb-2 ">
                                            {t("common.menu.screens")}:{" "}
                                        </label>
                                        <div className="my-1 ml-24">
                                            {this.props.contentBeingReaded ? (
                                                <Select
                                                    className="w-full block appearance-none shadow rounded mx-auto leading-tight focus:outline-none focus:focus:ring inputModal"
                                                    maxMenuHeight="none"
                                                    menuIsOpen={false}
                                                    isMulti
                                                    value={this.getOptionScreens(
                                                        this.props.screens.filter(
                                                            (screen) =>
                                                                this.props.contentBeingReaded?.screen_id.includes(
                                                                    screen._id,
                                                                ),
                                                        ),
                                                    )}
                                                />
                                            ) : (
                                                <Select
                                                    className="w-full block appearance-none shadow rounded mx-auto leading-tight focus:outline-none focus:focus:ring inputModal"
                                                    maxMenuHeight="1"
                                                    value={
                                                        this.state
                                                            .screenSelected
                                                    }
                                                    isMulti
                                                    onChange={this.handleSelect}
                                                    options={
                                                        this.props.optionScreens
                                                    }
                                                />
                                            )}
                                        </div>
                                        <FontAwesomeIcon
                                            icon={["fal", "calendar-alt"]}
                                            className="mt-1 mr-1 text-blue-300"
                                        />
                                        <label className="labelModal text-gray-700 text-sm font-bold mb-2 ">
                                            {this.props.t(
                                                "sections.content.Date",
                                            )}
                                            :{" "}
                                        </label>
                                        <div className="flex ml-24 item-center">
                                            <div className="mr-4">
                                                <span className=" font-bold upercase mt-2  ">
                                                    {t(
                                                        "sections.screens.modals.from",
                                                    )}
                                                    :{" "}
                                                </span>
                                                {this.props
                                                    .contentBeingReaded ? (
                                                    <div className="justify-start">
                                                        <input
                                                            id={
                                                                "startDateContentIterative" +
                                                                screen_id
                                                            }
                                                            type="date"
                                                            disabled="disabled"
                                                            value={
                                                                this.props
                                                                    .contentBeingReaded
                                                                    .schedule
                                                                    .startDate
                                                            }
                                                            className="  block shadow appearance-none border border-gray-300  rounded py-2 mx-auto  text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                        ></input>
                                                        <label
                                                            className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 flex align-center"
                                                            htmlFor="endDate"
                                                        >
                                                            <span className="  align-middle items-center mt-6 flex font-bold">
                                                                <div
                                                                    className={`flex justify-center ${this.props.contentBeingReaded.schedule.endDate === "" ? "flex-row-reverse" : "flex-row"}`}
                                                                >
                                                                    <div
                                                                        className={`${this.props.contentBeingReaded.schedule.endDate === "" ? "block" : "hidden"} text-xs font-light mt-1 px-2 cursor-pointer`}
                                                                    ></div>
                                                                    <div className="flex w-8 relative my-1  cursor-pointer">
                                                                        <div
                                                                            className={`${this.props.contentBeingReaded.schedule.endDate === "" ? "bg-blue-300" : "bg-gray-300"} h-5 w-8 rounded-full px-1`}
                                                                        >
                                                                            <div
                                                                                className={`${this.props.contentBeingReaded.schedule.endDate === "" ? "ml-3" : "mr-4"} mt-1 w-3 h-3 absolute transition-all transform ease-linear duration-75 flex items-center justify-center rounded-full bg-white shadow-toggle border-gray-300 top-0`}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="px-2">
                                                                    {this.props
                                                                        .contentBeingReaded
                                                                        .schedule
                                                                        .endDate !==
                                                                    ""
                                                                        ? t(
                                                                              "common.modals.fileDropper.withEndDate",
                                                                          )
                                                                        : t(
                                                                              "common.modals.fileDropper.noEndDate",
                                                                          )}
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <input
                                                        id={
                                                            "startDateContentIterative" +
                                                            screen_id
                                                        }
                                                        type="date"
                                                        value={
                                                            this.state.field
                                                                .startDate
                                                        }
                                                        className="  block shadow appearance-none border border-gray-300  rounded py-2 mx-auto  text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                    ></input>
                                                )}
                                            </div>
                                            <div className="flex">
                                                {this.props
                                                    .contentBeingReaded ? (
                                                    <>
                                                        <div>
                                                            {this.props
                                                                .contentBeingReaded
                                                                .schedule
                                                                .endDate !==
                                                                "" && (
                                                                <div>
                                                                    <span className=" font-bold upercase mt-2  ">
                                                                        {t(
                                                                            "sections.screens.modals.to",
                                                                        )}
                                                                        :{" "}
                                                                    </span>
                                                                    <input
                                                                        id={
                                                                            "endDateContentIterative" +
                                                                            screen_id
                                                                        }
                                                                        type="date"
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .contentBeingReaded
                                                                                .schedule
                                                                                .endDate
                                                                        }
                                                                        className={` block shadow appearance-none border border-gray-300 rounded py-2 mx-auto px-3  text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal ${this.props.contentBeingReaded.schedule.endDate === "" ? "opacity-0 " : ""}`}
                                                                    ></input>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            {!this.state
                                                                .contentHasNoEndDateInCarousel && (
                                                                <span className=" font-bold upercase mt-2  ">
                                                                    {t(
                                                                        "sections.screens.modals.to",
                                                                    )}
                                                                    :{" "}
                                                                </span>
                                                            )}
                                                            <input
                                                                id={
                                                                    "endDateContentIterative" +
                                                                    screen_id
                                                                }
                                                                type="date"
                                                                value={
                                                                    this.state
                                                                        .field
                                                                        .endDate
                                                                }
                                                                className={` block shadow appearance-none border border-gray-300 rounded py-2 mx-auto px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal ${this.state.contentHasNoEndDateInCarousel ? "opacity-0 " : ""}`}
                                                            ></input>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {this.state.contentBeingEdited &&
                                            this.state.step === 1 && (
                                                <label
                                                    className="uppercase mt-7 ml-24 tracking-wide text-gray-700 text-xs font-bold mb-2 flex align-center"
                                                    htmlFor="endDate"
                                                >
                                                    <span className="align-middle  font-bold">
                                                        <ButtonSwitcher
                                                            handleVisibility={
                                                                this
                                                                    .handleNoEndDateInCarousel
                                                            }
                                                            status={
                                                                this.state
                                                                    .contentHasNoEndDateInCarousel
                                                            }
                                                            labelOn={this.props.t(
                                                                "common.modals.fileDropper.noEndDate",
                                                            )}
                                                            labelOff={this.props.t(
                                                                "common.modals.fileDropper.withEndDate",
                                                            )}
                                                            revertLabel={true}
                                                        ></ButtonSwitcher>
                                                    </span>
                                                </label>
                                            )}
                                        <FontAwesomeIcon
                                            icon={["fad", "clock"]}
                                            className=" mr-1 text-blue-300"
                                        />
                                        <label className="labelModal text-gray-700 text-sm font-bold mb-2 ">
                                            {t("sections.screens.modals.time")}:{" "}
                                        </label>
                                        <div className=" ml-20 w-full justify-end">
                                            <span className="font-bold upercase ml-4">
                                                {t(
                                                    "sections.wizard.stepFive.brightnessStart",
                                                )}
                                                :{" "}
                                            </span>
                                            <div className="flex">
                                                {this.props
                                                    .contentBeingReaded ? (
                                                    <input
                                                        id={
                                                            "timeContentIterative" +
                                                            screen_id
                                                        }
                                                        disabled="disabled"
                                                        type="time"
                                                        value={
                                                            this.props
                                                                .contentBeingReaded
                                                                .schedule.time
                                                        }
                                                        className=" w-auto  block shadow appearance-none border border-gray-300 rounded py-2 ml-4 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                    ></input>
                                                ) : (
                                                    <input
                                                        id={
                                                            "timeContentIterative" +
                                                            screen_id
                                                        }
                                                        type="time"
                                                        value={
                                                            this.state.field
                                                                .time
                                                        }
                                                        className=" w-auto block  shadow appearance-none border border-gray-300 rounded py-2 ml-4 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal"
                                                    ></input>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {(this.state.step === 2 || this.state.step === 3) && (
                        <div className=" ml-2 ">
                            <div className="flex">
                                <FontAwesomeIcon
                                    icon={["fad", "clipboard-list"]}
                                    className="  mr-1 text-blue-300"
                                />
                                <label className=" labelModal text-gray-700 text-sm font-bold mb-2 ">
                                    {t("sections.screens.modals.content")}:{" "}
                                </label>
                            </div>
                            {!this.props.contentBeingEdited ? (
                                <></>
                            ) : (
                                <div>
                                    <GroupedSelect
                                        getGroupedOptions={
                                            this.getContentsOptions
                                        }
                                        customComponents={{
                                            Option: CustomOption,
                                        }}
                                        selected={this.state.selectedContent}
                                        changeCallback={
                                            this.props.handleSelectContent
                                        }
                                        extraProps={{
                                            isMulti: "true",
                                            closeMenuOnSelect: "false",
                                            maxMenuHeight: 200,
                                            maxMenuwidth: 200,
                                        }}
                                    ></GroupedSelect>
                                    <div
                                        className={`${this.state.contents.length > 1 ? "visible" : "invisible"}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={["fad", "clipboard-list"]}
                                            className="mt-1  mr-1 text-blue-300"
                                        />
                                        <label
                                            className={`labelModal text-gray-700 text-sm font-bold mb-2`}
                                        >
                                            {this.props.t(
                                                "sections.content.order",
                                            )}
                                            :{" "}
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div className="overflow-auto max-h-60">
                                <DragDropContext
                                    onDragEnd={this.handleDragEndModal}
                                >
                                    <Droppable droppableId="screenPlaylist">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className={`playlist-items relative mt-2 divide-y `}
                                            >
                                                {/* Item */}
                                                {this.state.contents.length !==
                                                    0 && (
                                                    <>
                                                        {this.state.contents.map(
                                                            (
                                                                content,
                                                                index,
                                                            ) => (
                                                                <>
                                                                    <Draggable
                                                                        isDragDisabled={
                                                                            this
                                                                                .state
                                                                                .contentBeingReaded
                                                                                ?.original_content
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        key={
                                                                            content._id
                                                                        }
                                                                        draggableId={
                                                                            content._id
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                    >
                                                                        {(
                                                                            provided,
                                                                        ) => (
                                                                            <div
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                className={`playlist-item flex justify-between  items-start py-2 rounded-md text-sm ${content.original_content ? "item-cloned" : "item-original"} hover:bg-gray-200 active:bg-white active:shadow`}
                                                                            >
                                                                                <div className=" w-full flex flex-row truncate ">
                                                                                    <div className=" contenedorEvento flex items-center justify-center mx-1  rounded-lg bg-black   overflow-hidden ">
                                                                                        {imageExtensions.includes(
                                                                                            content.ext,
                                                                                        ) && (
                                                                                            <>
                                                                                                {" "}
                                                                                                <img
                                                                                                    className=""
                                                                                                    src={`${process.env.REACT_APP_FILESERVER_URL}/${content.path}`}
                                                                                                    alt={
                                                                                                        content.name
                                                                                                    }
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                        {videoExtensions.includes(
                                                                                            content.ext,
                                                                                        ) && (
                                                                                            <>
                                                                                                <video
                                                                                                    src={`${process.env.REACT_APP_FILESERVER_URL}/${content.path}`}
                                                                                                    alt={
                                                                                                        content.name
                                                                                                    }
                                                                                                ></video>
                                                                                            </>
                                                                                        )}
                                                                                        {htmlExtensions.includes(
                                                                                            content.ext,
                                                                                        ) && (
                                                                                            <>
                                                                                                <img
                                                                                                    src={require("../../images/common/html.png")}
                                                                                                    alt={
                                                                                                        content.name
                                                                                                    }
                                                                                                ></img>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="playlist-item-description mr-2 md:w-5/6 flex flex-col items-start content-center truncate">
                                                                                        <span className="playlist-item-name font-bold mr-3">
                                                                                            <ReactTooltip
                                                                                                effect="solid"
                                                                                                className="w-72 p-2"
                                                                                                id={`contentFullName${content._id}`}
                                                                                                aria-haspopup="true"
                                                                                            >
                                                                                                <p className="text-center truncate">
                                                                                                    {
                                                                                                        content.name
                                                                                                    }
                                                                                                </p>
                                                                                            </ReactTooltip>
                                                                                            <div className="flex justify-between">
                                                                                                <p
                                                                                                    className="max-w-xs truncate"
                                                                                                    data-tip
                                                                                                    data-for={`contentFullName${content._id}`}
                                                                                                >
                                                                                                    {
                                                                                                        content.name
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            {!htmlExtensions.includes(
                                                                                                content.ext,
                                                                                            ) ? (
                                                                                                <span className="playlist-item-size font-extralight text-gray-600 ml-1">
                                                                                                    {" "}
                                                                                                    {
                                                                                                        content.width
                                                                                                    }
                                                                                                    x
                                                                                                    {
                                                                                                        content.height
                                                                                                    }
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span className="playlist-item-size font-extralight text-gray-600 ml-1">
                                                                                                    {t(
                                                                                                        "sections.screens.screenPlaylist.htmlFile",
                                                                                                    )}
                                                                                                </span>
                                                                                            )}
                                                                                            <span className="italic text-xs text-gray-400">
                                                                                                {content.original_content
                                                                                                    ? t(
                                                                                                          "sections.screens.screenPlaylist.cloned",
                                                                                                      )
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </span>
                                                                                        <div className="flex">
                                                                                            {videoExtensions.includes(
                                                                                                content.ext,
                                                                                            ) && (
                                                                                                <span className="playlist-item-duration font-extralight text-gray-600 mr-3">
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={[
                                                                                                            "fal",
                                                                                                            "stopwatch",
                                                                                                        ]}
                                                                                                        fixedWidth
                                                                                                        className="text-blue-600 mr-1 ml-0.5 text-sm"
                                                                                                    />
                                                                                                    {content.duration?.toFixed(
                                                                                                        2,
                                                                                                    )}
                                                                                                    "
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                </>
                                                            ),
                                                        )}
                                                    </>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    )}
                    {this.props.contentBeingReaded ? (
                        <div className=" flex justify-end mt-5">
                            {this.state.isReOrdening && (
                                <button
                                    className="buttonPrimary mr-4"
                                    onClick={() => {
                                        this.handleChangeOrderOfContentonPlaylist();
                                        this.closeModal();
                                    }}
                                >
                                    {this.props.t(
                                        "sections.content.changeOrder",
                                    )}
                                </button>
                            )}
                            <Link
                                to={{
                                    pathname: "/content",
                                    state: {
                                        data: {
                                            contentBeingEdited:
                                                this.props.contentBeingReaded
                                                    ._id,
                                        },
                                    },
                                }}
                            >
                                {!this.state.contentBeingReaded
                                    .original_content && (
                                    <button className="buttonPrimary mr-4">
                                        {this.props.t("common.buttons.edit")}
                                    </button>
                                )}
                            </Link>
                        </div>
                    ) : (
                        <>
                            {this.state.step === 2 ? (
                                <div>
                                    <div className="w-full flex justify-between mt-5">
                                        <button
                                            className="buttonSecondary mr-4"
                                            onClick={this.handleBackWizard}
                                        >
                                            {this.props.t(
                                                "common.buttons.back",
                                            )}
                                        </button>
                                        <button
                                            className={`buttonPrimary mr-4`}
                                            onClick={
                                                this.createContentIterative
                                            }
                                        >
                                            {this.props.t(
                                                "sections.content.createCarouselCreative",
                                            )}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className=" flex justify-end mt-5">
                                    <div>
                                        <button
                                            className="buttonPrimary mr-4"
                                            onClick={this.checkCompleteFields}
                                        >
                                            {this.props.t(
                                                "common.buttons.next",
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(CarrouselCreativeModal);
