import React from "react";
import KPI from "./KPI";
import { useTranslation } from "react-i18next";

const KPIs = (props) => {
    const { t } = useTranslation();
    const availableSlots =
        props.statistics.availableSlots > 0
            ? props.statistics.availableSlots.toFixed(2)
            : 0;
    return (
        <div className="relative w-full flex flex-col md:flex-row border-b-2 border-gray-200 py-2">
            {/* KPIs */}
            <KPI
                firstData={props.statistics.quantityActives}
                secondData={`/${props.statistics.quantityScreens}`}
                thirdData={t("sections.dashboard.activeScreens")}
            />
            <KPI
                firstData={availableSlots}
                secondData={"%"}
                thirdData={t("sections.dashboard.availableSlots")}
                hasGraphic={true}
            />
        </div>
    );
};
export { KPIs };
