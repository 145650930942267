import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalScreenSettings = ({ displayModal }) => {
    return (
        <>
            <div
                className={`fixed z-20 w-8/12 mx-auto mt-8 p-4 bg-white rounded-lg shadow-lg hidden ${displayModal ? "" : "hidden"}`}
            >
                <div className="modal-header relative font-extralight text-lg flex justify-between">
                    <div className="title">Encabezado</div>
                    <div className="button">
                        <FontAwesomeIcon icon={["fal", "times"]} />
                    </div>
                </div>
                <div className="relative mt-4 overflow-y-auto overscroll-y-auto">
                    Remover desde Screens.js
                </div>
            </div>
        </>
    );
};
export default ModalScreenSettings;
