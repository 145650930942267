import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/modals.css";
import { components } from "react-select";
import CustomContentOption from "./GroupedSelect/CustomComponents/CustomContentOption";
import GroupedSelect from "./GroupedSelect/GroupedSelect";

class SelectContentModal extends Component {
    constructor() {
        super();
        this.state = {
            selected: [],
            content: [],
        };
    }
    returnValue = (e) => {
        let returnValue = this.state.selected;
        this.props.callbackFunction(returnValue);
        this.setState({ selected: [] });
        this.closeModal();
    };
    componentDidMount = async () => {
        let contents = await this.props.getNotAssignedContent();
        this.setState({ content: contents });
    };
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add("hidden");
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    /**
     * Mapping the content array to a new array with the label and value properties.
     * */
    contentOptions = () =>
        this.state.content.map((c) => {
            return {
                label: c.name,
                value: c._id,
            };
        });

    render() {
        /**
         * Function that will find the option given by props and make a custom option based on the option values
         * @returns The CustomOption component.
         */
        const CustomOption = (props) => {
            let filteredOption = this.state.content?.filter(
                (c) => c._id === props.value,
            );
            return (
                <components.Option {...props}>
                    <CustomContentOption
                        content={filteredOption}
                        props={props}
                    />
                </components.Option>
            );
        };
        return (
            <div
                id={this.props.modalID}
                className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden"
            >
                <div className="modalContent bg-white rounded-md p-5 flex flex-col h-auto w-1/2">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 my-1">
                        <div className="title text-gray-600 text-2xl">
                            {this.props.title}
                        </div>
                        <div
                            className="button cursor-pointer"
                            onClick={this.closeModal}
                        >
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </div>
                    </div>
                    <div className="modal-body flex flex-col px-3">
                        <GroupedSelect
                            getGroupedOptions={this.contentOptions}
                            selected={this.state.selected}
                            customComponents={{ Option: CustomOption }}
                            changeCallback={this.handleSelect}
                            extraProps={{
                                isMulti: "true",
                                closeMenuOnSelect: "false",
                            }}
                        />
                    </div>
                    <div className="modal-body flex justify-center px-3 mt-2">
                        <button
                            onClick={this.closeModal}
                            className="buttonSecondary"
                        >
                            {this.props.t("common.buttons.cancel")}
                        </button>
                        <button
                            onClick={this.returnValue}
                            className="buttonPrimary"
                        >
                            {this.props.t("common.buttons.accept")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SelectContentModal);
